.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-space-between{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.overall-main-container {
    position: relative;
    height: 100%;
    display: flex;
}

.flex-row-space-between{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.overall-inner-container {
}

.text-nowrap {
    white-space: nowrap;
}

.text-bold {
    font-weight: 700;
}

.w-58-per{
    width: 58%;
}

.w-100-per{
    width: 100%;
}


.h-100-per{
    height: 100%;
}

.w-m-c {
    width: max-content;
}

.m-g-b-0{
    margin-bottom: 0!important;
}

.m-g-b-5{
    margin-bottom: 5px
}

.m-g-b-8{
    margin-bottom: 8px
}

.m-g-b-10{
    margin-bottom: 10px
}

.m-g-b-14{
    margin-bottom: 14px
}

.m-g-b-20{
    margin-bottom: 20px
}

.m-g-b-24{
    margin-bottom: 24px
}

.m-g-b-28{
    margin-bottom: 28px
}

.m-g-b-30{
    margin-bottom: 30px
}

.m-g-b-31{
    margin-bottom: 31px
}

.m-g-b-32{
    margin-bottom: 32px
}

.m-g-b-50{
    margin-bottom: 50px
}

.m-g-b-51{
    margin-bottom: 51px
}

.m-g-b-55{
    margin-bottom: 55px
}

.m-g-b-56{
    margin-bottom: 56px
}

.m-g-b-58{
    margin-bottom: 58px
}

.m-g-l-3{
    margin-left: 3px!important;
}

.m-g-l-15{
    margin-left: 15px;
}

.m-g-l-44{
    margin-left: 44px!important;
}

.m-g-b-6{
    margin-bottom: 6px;
}

.m-g-b-15{
    margin-bottom: 15px
}

.m-g-b-16{
    margin-bottom: 16px
}

.m-g-b-17{
    margin-bottom: 17px
}

.m-g-b-18{
    margin-bottom: 18px
}

.m-g-b-19{
    margin-bottom: 19px
}

.m-g-b-25{
    margin-bottom: 25px!important;
}

.m-g-b-27{
    margin-bottom: 27px;
}

.m-g-l-0{
    margin-left: 0!important;
}

.m-g-b-34{
    margin-bottom: 34px;
}

.m-g-b-37{
    margin-bottom: 37px;
}

.m-g-b-40{
    margin-bottom: 40px;
}

.m-g-b-43{
    margin-bottom: 43px;
}

.m-g-b-45{
    margin-bottom: 45px;
}

.m-g-b-47{
    margin-bottom: 47px;
}

.m-g-b-54{
    margin-bottom: 54px;
}

.m-g-b-55{
    margin-bottom: 55px;
}

.m-g-t-8 {
    margin-top: 8px;
}

.m-g-t-28{
    margin-top: 28px;
}

.m-g-t-30{
    margin-top: 30px;
}

.m-g-t-33{
    margin-top: 33px;
}

.m-g-t-54{
    margin-top: 54px;
}

.m-g-t-87{
    margin-top: 87px;
}

.m-g-r-a {
    margin-right: auto;
}

.m-g-r-0 {
    margin-right: 0!important;
}

.m-g-t-0 {
    margin-top: 0!important;
}

.m-g-t-16 {
    margin-top: 16px!important;
}

.m-g-t-22 {
    margin-top: 22px;
}

.m-g-t-25 {
    margin-top: 25px!important;
}

.m-g-t-20 {
    margin-top: 20px;
}

.m-g-t-48{
    margin-top: 48px;
}

.m-g-t-106 {
    margin-top: 106px;
}

.m-g-t-109 {
    margin-top: 109px;
}

.m-g-r-0 {
    margin-right: 0!important;
}

.m-g-r-5 {
    margin-right: 5px;
}

.m-g-r-15 {
    margin-right: 15px;
}

.m-g-r-30 {
    margin-right: 30px;
}

.m-g-r-40 {
    margin-right: 40px;
}

.m-g-t-40 {
    margin-top: 40px;
}

.m-g-t-35 {
    margin-top: 35px;
}

.w-166{
    width: max-content;
}

.w-196 {
    width: 196px;
}

.w-260 {
    width: 260px;
}

.w-360 {
    width: 360px;
}

.p-0 {
    padding: 0!important;
}

.p-24 {
    padding: 24px;
}

.p-25{
    padding: 25px;
}

.min-w-118{
    min-width: 118px;
}

.position-absolute {
    position: absolute;
    left: 0;
}

.to-lowercase{
    text-transform: lowercase;
}

.background-yellow{
    background: #FF9800;
}

.background-red{
    background: #FF0000;
}

.background-blue{
    background: #0F90C0;
}

.cursor_pointer:hover {
    cursor: pointer;
}

.cursor_default:hover {
    cursor: default;
}

.justify{
    justify-content: space-between;
}

.datepicker-icon {
    background: #DDE7ED;
    border-radius: 3px;
    padding: 6px 6px 7px;

    div {
        width: 11px;
        height: 12px;
    }
}

.form-label {
    font-family: Lato, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;
    color: #535353;
}

.form-input {
    background: #EFEFF0;
    border-radius: 3px;
    padding: 10px 13px;
    font-family: Lato, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 65%;
    color: #535353;
    border: 1px solid #E4E4E5;

    &:hover {
        border: 1px solid #00BAE5;
    }

    &[name=expirationDate] {
        line-height: 100%;
    }
}

.error {
    color: #FF0000;
    height: 13px;
    font-size: 10px;
    letter-spacing: 0.5px;
    margin-left: 11px;
    padding-top: 6px;
}

.tr_dur_03 {
    transition-duration: 0.3s;
    transition: all .3s;
}

.cursor_pointer {
    cursor: pointer;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}

.d_contents {
    .text-bold {
        display: contents;
    }
}

.t_d_initial {
    transition-duration: initial !important;
}

.p_9_0_5 {
    padding: 9px 0 5px !important;
}

.t_o_el {
    flex-grow: 1;
    width: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.p_t_1_p_b_2 {
    padding-top: 1px;
    padding-bottom: 2px;
}

.f_s_90 {
    font-size: 90%;
}

.m_l_50 {
    margin-left: 50px;
}

.p_0_16 {
    padding: 0 16px;
}

.draggable {
    &:hover {
        cursor: grab;
    }
}

.text_overflow_ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    &.initial_width {
       width: initial;
    }
}

.cursor_not_allowed {
    &:hover {
        cursor: not-allowed;
    }
}

.order_1 {
    order: 1;
}

.grayA8 {
    color: #A8A8A8;
}

.input_fix>div>div {
    width: 100%;
    height: 40px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    transition-duration: 0.3s;

    &:hover, &:focus {
        border-color: #00bae5;
    }

    >div {
        margin-top: 0!important;
        z-index: 1;

        &::before, &::after {
            border-bottom: 0!important;
        }

        &:hover {
            border-bottom: 0!important;

            &::before, &::after {
                border-bottom: 0!important;
            }
        }
    }

    label {
        margin-left: 0;
        top: -11px;
        z-index: 0;

        &.Mui-focused, &.MuiFormLabel-filled {
            top: -8px;
            background-color: white;
            padding: 0 4px;
        }
    }
}
.MuiMenu-list {
    background-color: #f1f2f3;

    .MuiMenuItem-root.MuiListItem-button {
        font-size: 12px;
        font-weight: 400;
        margin-left: 3px;

        &:hover {
            background-color: #dde4eb;
        }
    }
}