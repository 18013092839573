// Colors

$color-gray: #F3F3F4;
$color-blue: #4391BC;
$color-gray-1: #E5E5E5;
$color-black: #0C1441;
$color-white: #ffffff;
$color-blue-1: #C5DAE5;
$color-white-2: #FAFAFA;
$color-white-3: #F3F3F3;
$color-black-2: #191919;
$color-red: #FF4444;
$color-yellow: #FFBB33;
$color-green: #1AB394;
$color-gray-2: #CCCCCC;

// Fonts
$Lato: Lato, serif;
