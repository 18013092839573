@import "_mixins";

.login-form-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.login-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.main-text {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 53.5px;
    line-height: 64px;
    text-transform: capitalize;
    margin-right: 10px;
    color: #191919;

    &.sign-up {
        margin-left: -5px;
     }
}

.second-text {
    font-family: Lato, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    color: #191919;
    width: -moz-available;
}

.ss-btn {
    font-family: Lato, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 10px;
    padding: 0 20px;
    letter-spacing: 0.5px;
    background: #1090C0;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    text-align: center;
    color: #FFFFFF;
    z-index: 100;
    transition-duration: 0.3s;

    &.white {
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        box-sizing: border-box;
        border-radius: 3px;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #0F90C0;

        &:hover {
            border: 1px solid #33B5E5;
        }

        &:disabled {
            color: #CCCCCC;
        }
    }
/*
    &.os_win {
        padding: 14px 20px 11px;
    }
*/

    &.login-form-button {
        white-space: nowrap;
        margin-bottom: 16px;
        margin-left: 11px;
        margin-right: 0;

        &.rejected_button {
            @include mq("tablet-max") {
                margin-top: 23px;
            }

            @include mq("m") {
                margin-top: 0;
            }
        }
    }

    &.d_btn {
        font-weight: 400;
        font-size: 13px;
        color: #FFFFFF;
        border: none;
        transition-duration: 0.3s;

        &.cr_c_b {
            padding: 0 20px;
            padding-bottom: 9.5px;
            font-weight: 400;
            white-space: pre;

            &.second_button {
                margin-right: 15px;
            }

            &.not_active {
                background: #EFEFF0;
                color: #535353;
            }

            &.red {
                background: #FF0000;
                transition-duration: 0.3s;

                &.fs_13 {
                    font-size: 13px;
                }

                &:hover {
                    transition-duration: 0.3s;
                    background-color: #FF6565;
                }
            }
        }

        &:hover {
            color: #FFFFFF;
            background-color: #00BAE5;
            transition-duration: 0.3s;
        }

        &.refresh {
            background: #DDE7ED;
            padding: 10px;
            color: #1090C0;
            margin-right: 15px;

            &:hover {
                color: #FFFFFF;
                background-color: #1090C0;
                transition-duration: 0.3s;
            }

            &.edit_b,
            &.delete_b,
            &.eye,
            &.fa_check,
            &.fa_times,
            &.fa_ban,
            &.unlock_alt {
                margin-right: 0;
                width: 25px;
                height: 25px;
                padding: 5px;
                z-index: 0;

                svg {
                    fill: #0F90C0;
                }

                &:hover {
                    color: #FFFFFF;
                    svg {
                        fill:  #FFFFFF;
                    }
                }

                &:disabled {
                    svg {
                        fill: #CFCFCF;
                    }
                }
            }

            &.delete_b:hover {
                background: #E73043;
            }

            &.edit_b {
                margin-right: 5px;
                padding: 6px;
            }

            &.fa_check {
                padding: 7px 6px 5px;
                background: #D2FFE4;
                color: #00C851;

                &:hover {
                    background: #00C851;
                }
            }

            &.eye {
                padding: 7px 4px 6px;
            }

            &.fa_times {
                padding: 5px 6px 6px;
                background: #FFF4DE;
                color: #FFBB33;

                &:hover {
                    background: #FFBB33;
                }
            }

            &.fa_ban {
                padding: 5px 5px 6px 6px;
                background: #FFE8E8;
                color: #FF4444;

                & .fa-ban {
                    transform: rotate(90deg);
                }

                &:hover {
                    background: #FF4444;
                }
            }
        }

        &:disabled,
        &.forbidden {
            color: #CCCCCC;
            background: #E5E5E5;
            
            i {
                color: #CFCFCF;
            }

            svg {
                fill: #CFCFCF;
            }

            &:hover {
                color: #CCCCCC;
                background: #E5E5E5 !important;
                cursor: default;
            }
        }

        &:disabled {
            pointer-events: none;
        }

        &.active {
            color: #FFFFFF;
            background-color: #1090C0;
            transition-duration: 0.3s;

            &:hover {
                background-color: #00BAE5;
                transition-duration: 0.3s;
            }
        }

        &.disabled {
            pointer-events: none;
            color: #CCCCCC;
            background: #E5E5E5;

            i {
                color: #CFCFCF;
            }

            &:hover {
                color: #CCCCCC;
                background: #E5E5E5;
            }
        }

        &.light_blue_hover {
            &:hover {
                background-color: #00BAE5;
                transition-duration: 0.3s;
            }
        }
    }
}

.status_company_items {
    display: flex;

    .cr_c_b:nth-child(1){
        border-radius: 3px 0 0 3px;
    }
    .cr_c_b:nth-child(2){
        border-radius: 0;
    }
    .cr_c_b:nth-child(3){
        border-radius: 0;
    }
    .cr_c_b:nth-child(4){
        border-radius: 0 3px 3px 0;
    }

    .cr_c_b:not(:last-child){
        border-right: 1px solid #E0E0E0;
    }
}

.ss-btn-clear {
    font-family: Lato, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 10px;
    border: 1px solid #0F90C0;;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 20px;
    letter-spacing: 0.5px;
    background: inherit;
    text-align: center;
    color: #0F90C0;
    margin-right: 20px;
    height: 35px;

    &:hover {
        border: 1px solid #00BAE5;;
        color: #00BAE5;
    }
}

.ss-btn-blue {
    font-family: Lato, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 10px;
    letter-spacing: 0.5px;
    border: 1px solid #0F90C0;;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 20px;
    background: #0F90C0;
    text-align: center;
    color: #FFFFFF;
    margin-right: 20px;

    &:hover {
        border: 1px solid #00bae5;;
        background-color: #00bae5;
    }
}

.status_company_items .cr_c_b {
    font-size: 12px !important;
    letter-spacing: .03em;
    margin: 0;
    padding: 15px 15px 11px !important;
}

@media screen and (max-width: 1050px) {
    .status_company_items .cr_c_b {
        padding: 15px 15px 11px !important;
    }
    .search_box_wrapper {
        width: 200px !important;
    }
}

.login-button {
    border: none;
}

.main-footer {
    position: absolute;
    bottom: 0;
    right: 50px;
    font-family: Lato, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border: none;
    background-color: inherit;
    left: 0;
}

.form-button {
    background-color: #0F90C0;
    border: none;
    margin-left: 8px;
    position: relative;
    color: $color-white;

    &:disabled {
        background-color: #CCCCCC;
        cursor: not-allowed;
    }

    &:hover:enabled {
        background-color: #00bae5;
    }
}

.login-form-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 154px;
}

.registration-form-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 244px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.input-fields-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.forgot-password-link {
    font-family: Lato, serif;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.25px;
    color: #0F90C0;
    margin-left: 20px;
    margin-top: 10px;
    cursor: pointer;
    margin-right: 9px;

    &:hover {
        color: #00bae5;
    }
}

.no-account {
    font-family: Lato, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #0F90C0;
    margin-right: 20px;
}

.login-button-checkbox-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.login-checkbox-wrapper {
    font-family: Lato, serif;
    font-size: 10px;
    margin-top: 1px;
    margin-bottom: 37px;
    letter-spacing: 0.4px;
    line-height: 12px;
    color: #B5B5B5;
    display: flex;
    position: relative;
}

.checkbox{
    height: 14px;
    width: 14px;
    margin-right: 6px;
    ouline: 1px solid #CCCCCC;
}

.checkbox:checked {
    background-color: #4391BC;
    color: #4391BC;
    border: 5px solid green;
}

.terms-of-use{
    font-family: Lato, serif;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.25px;
    color: #0F90C0;
    margin: -2px 4px;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        color: #00bae5
    }
}

.create-company{
    display: flex;
    align-items: center;
}

.plus-icon{
    margin-right: 12px;
}

.registration-from {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    .MuiInputBase-input {
        color: #535353;
    }

    &.modal_form {
        padding: 30px 30px 30px;
    }
}

.upload-image-name-domain-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 26px;
}

.upload-image-label {
    width: 114px;
    height: 114px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Lato, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #0F90C0;
    cursor: pointer;
    position: relative;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 3px;

    &:hover {
        border-radius: 2px;
    }
}

.name-domain-wrapper {
    width: 59%;
    margin-right: 36px;
}

.create-company-form-block {
    margin-right: 70px;
}

.upload-logo-icon {
    margin-bottom: 15px;
    top: 16.67%;
    bottom: 16.67%;
}

.uploaded-image{
    width: min-content;
    height: min-content;
    max-height: 112px;
    max-width: 112px;
    border-radius: 4px;
    margin: auto;
    object-fit: contain;
}

.uploaded-image-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
}

.uploaded-image-wrapper:hover .replace-logo-frame{
    display: block;
    position: absolute;
    width: 101%;
    height: 28px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.38);
    font-family: Lato, serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-top: 10px;
    border-radius: 0 0 2px 2px;
    margin: 0 0 -1px -1px;
}

.replace-logo-frame{
    display: none;
}

.wrapper-fields {
    max-width: 400px;

    input {
        width: 400px;
        height: 40px;
        margin-top: 2px;
        padding: 6px 12px;

        &:disabled {
            background-color: #E0E0E0;
        }

        &[name="expirationDate"] {
            color: gray;
        }
    }
}

.company-logo-label {
    display: block;
    margin-bottom: 3px;
}

.main-blue-color {
    color: #00BAE5;
}

.explanation-text {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 145%;
    color: #535353;
    margin-bottom: 44px;
}

.small-hint-text {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #B5B5B5;
}

.alert-icon {
    position: absolute;
    top: 105.3%;
    left: 0;
}

.form-title {
    font-family: Lato, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 54px;
    /* identical to box height */


    color: #191919;
    margin-bottom: 60px;
}

.form_field {
    width: 360px;
}

.pad-0-49 {
    padding: 0 49px;
}

.form-verification {
    display: flex;
    margin-bottom: 17px;
}

.f-s-14 {
    font-size: 14px;
}

.w-210 {
    width: 210px;
}

.over {
    padding: 0 49px;
}

.m-r-55 {
    margin-right: 55px;
}

input[type=password] {
    font-family: Lato, serif;
}

.nav_tab_wrapper {
    display: flex;

    .nav_tab {
        border-radius: 5px 5px 0 0;
        font-size: 13px;
        line-height: 78%;
        color: #9D9D9D;
        padding: 0 30px;
        height: 36px;
        display: flex;
        align-items: center;
        background-color: #E5E5E5;
        font-weight: 600;

        span {
            margin-left: 7px;
        }

        &:hover:not(.active) {
            cursor: pointer;
        }

        &.active {
            color: #535353;
            background-color: #FFFFFF;
        }
    }
}

.company-settings-company-name {
    font-family: Lato, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 74%;
    /* or 10px */
    color: #535353;
}

.company-settings-company-details-header {
    font-family: Lato, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 74%;
    /* or 10px */
    color: #535353;
    padding: 0 0 17px 24px;
    border-bottom: 1px solid #E5E5E5;
}

.company-settings-form-wrapper{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.label-upload-image{
    padding: 11px 23px;
    background: #FFFFFF;
    border: 1px solid #A9B9C7;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Lato, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 80%;
    width: 120px;
    text-align: center;
    cursor: pointer;
    color: #0F90C0;
}

.uploaded-image-container{
    width: 120px;
    height: 120px;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 10px;
    position: relative;
}

.delete-image-icon-container{
    background: #FF6565;
    border-radius: 2px;
    position: absolute;
    top: 7px;
    right: 7px;
    width: 14px;
    height: 14px;
    cursor: pointer;

    i{
        color: white;
        font-size: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.upload-image-company-logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
        width: 80px;
        height: 80px;
    }
}

.company-details-form{
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid #E0E0E0;

    .MuiInput-underline:after {
        border: none !important;
    }

    .MuiInput-underline:before{
        border-radius: 3px;
        top: -4px;
        bottom: -4px;
        transition: unset!important;
        border: none !important;
    }

    .MuiInput-underline:hover:before{
        border-radius: 3px;
        top: -4px;
        bottom: -4px;
        transition: unset!important;
        border: 1px solid #00BAE5 !important;
    }

    .MuiFormHelperText-root.Mui-error {
        color: #FF0000;
        height: 13px;
        font-size: 10px;
        letter-spacing: 0.5px;
        margin-left: 11px;
        padding-top: 6px;
    }
}

.ss-btn,
.ss-btn-blue,
.ss-btn-clear,
.info-modal-first-button,
.cr_c_b {
    height: 35px;
    line-height: 38px; // Ali
/*
    &.os_win {
        padding: 14px 20px 11px !important;
        line-height: 65% !important;
    }
 */
}

.refresh {
    line-height: 65%;
}

.no_result_wrapper {
    display: flex;
    flex-direction: column;

    .img_no_result_container{
        width: 400px;
    }

    .img_no_result_container,
    .title,
    .description {
        margin: auto;

        img {
            width: 100%;
        }

        @include mq("xxxl") {
            width: 307px;
        }
    }

    .title,
    .description {
        font-family: Lato, serif;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #535353;
        text-align: center;
    }

    .no_report {
        font-family: Lato, serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #535353;
        text-align: center;
        margin: 30px auto;
    }
    .title {
        margin-top: 21px;

        @include mq("xxxl") {
            margin-top: 10px;
        }
    }

    .description {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        margin-top: 10px;
    }
}

.overall_tab_wrapper {
    height: 100%;
}

.not_allowed {
    color: #B5B5B5;
}

.noValue {
    background-color: #FBFCFC;
}

.haveValue {
    background-color: white;
}

.create-edit-form.add_note .note_inner_wrapper .items_container .add-attributes-button-wrapper .custom-dropdown-selection-overall.haveValue {
    background-color: white;
}

.create-edit-form.add_note .note_inner_wrapper .items_container .custom_dropdown.haveValue {
    background-color: white;
}

.MuiPopover-root .MuiMenu-paper {
    max-height: 160px;
}

.MuiFormLabel-root.Mui-error {
    color: #a8a8a8 !important;
}

.ss-btn.d_btn.cr_c_b.pink.fs_13.deleteBtn {
    margin-right: auto;
    background-color: #ffefef;
    color: red;
    padding: 0;
    width: 35px;
    height: 35px;
    font-size: 15px;
    text-align: center;
    transition: all 0.3s;

    &:hover {
        color: #fffefe;
        background-color: red;
    }
}
