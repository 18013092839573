/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit; }

* {
  box-sizing: border-box; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

a {
  text-decoration: none; }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: none; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

img,
svg {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner-container spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner-container spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner-container padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner-container padding and border in Firefox 3+.
 */
button::-moz-focus-inner-container,
input::-moz-focus-inner-container {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.inner-container {
  padding: 0 50px;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #535353; }

form {
  width: 100%; }

.main-footer {
  height: 60px;
  display: flex;
  flex-direction: row; }
  .main-footer.dashboard {
    height: 55px; }
    .main-footer.dashboard .inner-container {
      padding: 0; }

.wrapper-onboarding {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%; }
  .wrapper-onboarding .img-wrapper {
    width: 51%; }
  .wrapper-onboarding .registration-box {
    flex-direction: column;
    align-items: end; }
  .wrapper-onboarding .img-wrapper img {
    width: 130%; }
  .wrapper-onboarding .get-started-form {
    display: flex; }
    .wrapper-onboarding .get-started-form .field {
      width: 50%;
      margin-right: 8px; }

.buttons-wrapper {
  display: flex;
  height: fit-content;
  width: 50%; }

.info-modal-text-wrapper {
  padding: 30px; }

.info-modal-main-text {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  text-align: center;
  color: #535353; }

.info-modal-second-text {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  text-align: center;
  color: #808080; }

.info-modal-first-button {
  position: relative;
  padding: 0 20px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  outline: none;
  cursor: pointer;
  line-height: 16px;
  /* identical to box height */
  background-color: white;
  display: flex;
  border: 1px solid #DDE4EB;
  box-sizing: border-box;
  border-radius: 3px;
  color: #1090C0;
  height: 35px; }

.info-modal-first-button:hover {
  border: 1px solid #33B5E5; }

.info-modal-first-button-blue:disabled,
.info-modal-first-button:disabled {
  background: #E5E5E5;
  border: 1px solid #E5E5E5;
  color: #cccccc; }
  .info-modal-first-button-blue:disabled:hover,
  .info-modal-first-button:disabled:hover {
    background: #E5E5E5;
    border: 1px solid #E5E5E5; }

.info-modal-first-button-blue {
  color: white;
  background-color: #0F90C0;
  border: 1px solid #0F90C0;
  transition-duration: 0.3s; }

.info-modal-first-button-blue:hover {
  border: 1px solid #33B5E5;
  background-color: #33B5E5;
  transition-duration: 0.3s; }

.info-modal-first-button-yellow {
  color: white;
  background-color: #FF9800;
  border: 1px solid #FF9800; }

.info-modal-first-button-yellow:hover {
  border: 1px solid #FFBB33;
  background-color: #FFBB33; }

.info-modal-first-button-red {
  color: white;
  background-color: #FF0000;
  border: 1px solid #FF0000; }

.info-modal-first-button-red:hover {
  color: white;
  background-color: #FF6565;
  border: 1px solid #FF6565; }

.explanation-textarea {
  background: #EFEFF0;
  border-radius: 3px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 65%;
  padding: 15px;
  height: 130px;
  width: 100%;
  resize: none;
  border: none; }

.form-textarea {
  background: #EFEFF0;
  border-radius: 3px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 65%;
  padding: 15px;
  height: 106px;
  width: 100%;
  resize: none;
  border: none; }

.far.fa-eye, .far.fa-eye-slash {
  position: absolute;
  right: 10px;
  top: 21px;
  font-size: 15px; }

.modal {
  position: absolute;
  top: 135px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4391BC;
  color: #ffffff;
  border-radius: 4px;
  font-family: Lato, serif;
  width: 400px;
  height: 150px; }

.login-form-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 44px; }

.warning-modal-header {
  width: 100%;
  height: 40px;
  background: #0F90C0;
  border-radius: 4px 4px 0 0; }
  .warning-modal-header img {
    width: 20px;
    height: 20px; }

.explanation-modal-header {
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 4px 4px 0 0;
  padding: 0 30px; }
  .explanation-modal-header img {
    width: 20px;
    height: 20px; }

.explanation-modal-from-wrapper {
  padding: 25px 30px 30px;
  width: 100%; }

.explanation-modal-from-title {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #535353; }

.reason-modal-title {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.03em;
  margin-top: 4px;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  color: #FFFFFF;
  /*margin-top: 5px;*/
  white-space: pre-wrap; }

.warning-text-wrapper {
  padding: 24px 30px 31px;
  min-height: 195px; }
  .warning-text-wrapper .warning-modal-text {
    margin: auto;
    padding: 0; }
  .warning-text-wrapper .flex-row {
    margin-top: 30px;
    padding: 0; }

.warning-modal-text {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 27px 30px 30px;
  color: #535353; }
  .warning-modal-text .text-bold {
    margin: 0 4px; }
  .warning-modal-text .large_img {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .warning-modal-text .large_img .message {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #535353;
      border: none; }
    .warning-modal-text .large_img .size {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 160%;
      color: #808080;
      margin: 20px 0 10px; }
    .warning-modal-text .large_img .suggestion {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 160%;
      letter-spacing: -0.01em;
      color: #808080; }

.custom-dropdown-selection-overall {
  position: relative;
  font-family: "Lato", sans-serif;
  background: #FFFFFF;
  border: 1px solid #A9B9C7;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  height: 35px;
  transition-duration: 0.3s; }
  .custom-dropdown-selection-overall:hover {
    transition-duration: 0.3s;
    border: 1px solid #00BAE5; }
  .custom-dropdown-selection-overall.disabled {
    border: 1px solid #E5E5E5; }
    .custom-dropdown-selection-overall.disabled .custom-dropdown-button,
    .custom-dropdown-selection-overall.disabled .dropdown-caret {
      cursor: not-allowed;
      color: #CCCCCC; }
    .custom-dropdown-selection-overall.disabled .dropdown-line {
      background-color: #E5E5E5; }
    .custom-dropdown-selection-overall.disabled .dropdown-caret {
      border-color: #E5E5E5 transparent transparent transparent; }
    .custom-dropdown-selection-overall.disabled:hover {
      transition-duration: 0.3s;
      border: 1px solid #E5E5E5;
      cursor: not-allowed; }

.dropdown-line {
  position: absolute;
  height: 23px;
  top: 6px;
  right: 30px;
  width: 1px;
  background: #A9B9C7;
  cursor: pointer; }

.dropdown-caret {
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid;
  border-color: #A9B9C7 transparent transparent transparent;
  right: 9px;
  top: 15px; }
  .dropdown-caret:hover {
    cursor: pointer; }

.custom-dropdown-button {
  padding: 13px 40px 13px 11px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 74%;
  color: #535353;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }
  .custom-dropdown-button:hover {
    cursor: pointer; }

.custom-dropdown-list-selection-with-values {
  background: #FFFFFF;
  border: 1px solid #00BAE5;
  box-sizing: border-box;
  border-radius: 0 0 3px 3px;
  width: 98%;
  max-height: 156px;
  overflow-y: auto;
  margin-left: 2px;
  margin-top: -6px;
  position: absolute;
  z-index: 1000;
  top: 34px; }

.dropdown-search {
  width: 100%;
  height: 30px;
  background: #E5E5E5;
  padding: 11px 11px 10px 11px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 74%;
  color: #535353;
  border: none; }

.custom-dropdown-list-selection-with-values li {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  backdrop-filter: blur(1px);
  font-size: 12px;
  line-height: 74%;
  /* identical to box height, or 9px */
  color: #535353;
  width: 97%;
  padding: 8px 11px;
  margin: 5px 0 5px 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }
  .custom-dropdown-list-selection-with-values li:last-child {
    border-bottom: none; }
  .custom-dropdown-list-selection-with-values li:hover {
    background-color: #0F90C0;
    color: white;
    cursor: pointer; }
  .custom-dropdown-list-selection-with-values li.disabled {
    opacity: 0.5;
    font-style: italic; }
    .custom-dropdown-list-selection-with-values li.disabled:hover {
      cursor: not-allowed; }

.loadingSpinner {
  border: 4px solid #FFF;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  opacity: 0.8;
  border-radius: 50%;
  animation: loadingSpinner 0.7s infinite linear;
  -webkit-animation: loadingSpinner 0.7s infinite linear; }

button .loadingSpinner {
  margin-top: -14px;
  margin-left: -14px;
  position: absolute;
  top: 50%;
  left: 50%; }

.nav li div {
  color: #a7b1c2;
  font-weight: 600;
  padding: 14px 20px 14px 25px;
  display: block;
  cursor: pointer; }

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

.switch_wrapper .switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 18px; }

.switch_wrapper .switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.switch_wrapper .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.switch_wrapper .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

.switch_wrapper input:checked + .slider {
  background-color: #1AB394; }
  .switch_wrapper input:checked + .slider.red {
    background-color: #FB5757; }

.switch_wrapper input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px); }

.switch_wrapper .slider.round {
  border-radius: 34px; }

.switch_wrapper .slider.round:before {
  border-radius: 50%; }

.jobs-dropdown-wrapper {
  width: 278px; }

.export-items-dropdown-wrapper {
  width: 300px;
  margin-right: auto; }

.super-admin-companies-table-container {
  margin-top: 26px;
  margin-bottom: 10px; }
  .super-admin-companies-table-container.head {
    border-bottom: 1px solid #E0E0E0; }
    .super-admin-companies-table-container.head .common_table thead {
      border: 0; }
  .super-admin-companies-table-container.body {
    height: 300px;
    flex-grow: 1;
    overflow-y: scroll;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    scrollbar-width: thin; }
    .super-admin-companies-table-container.body .companies_table tr:hover,
    .super-admin-companies-table-container.body .jobs_table tr:hover {
      cursor: pointer; }
  .super-admin-companies-table-container .common_table {
    width: 100%; }
    .super-admin-companies-table-container .common_table thead {
      border-bottom: 1px solid #E0E0E0; }
      .super-admin-companies-table-container .common_table thead tr th {
        height: 32px;
        font-weight: 600;
        font-size: 13px;
        line-height: 100%;
        color: #535353;
        text-align: start;
        display: flex;
        flex-wrap: nowrap;
        align-items: center; }
        .super-admin-companies-table-container .common_table thead tr th .title_value_wrapper {
          line-height: 32px; }
        .super-admin-companies-table-container .common_table thead tr th .th_inner {
          display: flex;
          align-items: center; }
          .super-admin-companies-table-container .common_table thead tr th .th_inner span {
            width: max-content; }
          .super-admin-companies-table-container .common_table thead tr th .th_inner .icons_wrapper {
            margin-left: 5px;
            width: 8px; }
            .super-admin-companies-table-container .common_table thead tr th .th_inner .icons_wrapper .sort_icon {
              fill: #DDE4ED; }
              .super-admin-companies-table-container .common_table thead tr th .th_inner .icons_wrapper .sort_icon.active_icon_sort {
                fill: #535353; }
              .super-admin-companies-table-container .common_table thead tr th .th_inner .icons_wrapper .sort_icon:hover {
                fill: #00BAE5;
                cursor: pointer; }
            .super-admin-companies-table-container .common_table thead tr th .th_inner .icons_wrapper .sort_icon_active {
              fill: #00BAE5 !important; }
    .super-admin-companies-table-container .common_table tr {
      width: calc(100% - 8px);
      display: flex;
      justify-content: flex-start;
      margin: 0;
      align-items: center; }
      .super-admin-companies-table-container .common_table tr th, .super-admin-companies-table-container .common_table tr td {
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
        color: #535353;
        text-align: start; }
      .super-admin-companies-table-container .common_table tr td {
        font-size: 13px; }
    .super-admin-companies-table-container .common_table tbody tr {
      transition-duration: 0.3s;
      min-height: 40px;
      height: auto;
      padding: 5px 0; }
      .super-admin-companies-table-container .common_table tbody tr.activated td {
        color: #535353; }
      .super-admin-companies-table-container .common_table tbody tr.deactivated td {
        color: #a9b9c7; }
      .super-admin-companies-table-container .common_table tbody tr:first-child {
        margin-top: 0; }
      .super-admin-companies-table-container .common_table tbody tr:nth-child(odd) {
        background: #F5F5F5; }
      .super-admin-companies-table-container .common_table tbody tr:hover {
        cursor: auto;
        background: #E6F8FC; }
        .super-admin-companies-table-container .common_table tbody tr:hover td {
          color: #26AEE1; }
    .super-admin-companies-table-container .common_table tbody td {
      font-weight: normal;
      line-height: 120%; }
      .super-admin-companies-table-container .common_table tbody td:nth-child(4) {
        font-size: 12px; }

.panel_wrapper {
  display: flex; }
  .panel_wrapper.selector .search_box_wrapper {
    margin-right: 15px; }
  .panel_wrapper.selector .custom-dropdown-selection-overall {
    margin-right: auto; }
  @media only screen and (max-width: 1024px) {
    .panel_wrapper.selector .search_box_wrapper {
      width: 240px; }
    .panel_wrapper.selector .custom-dropdown-selection-overall {
      width: 235px;
      margin-right: 0; } }
  @media only screen and (max-width: 991px) {
    .panel_wrapper.selector .search_box_wrapper {
      width: 100px; }
    .panel_wrapper.selector .custom-dropdown-selection-overall {
      width: 130px; } }
  @media only screen and (max-width: 1199px) {
    .panel_wrapper.jobs_tab .flex-row {
      width: 50%;
      justify-content: start; }
      .panel_wrapper.jobs_tab .flex-row.right_side {
        align-items: end;
        justify-content: flex-end; }
      .panel_wrapper.jobs_tab .flex-row .jobs-dropdown-wrapper {
        width: 274px; }
    .panel_wrapper.jobs_tab .search_box_wrapper {
      margin-right: auto;
      margin-bottom: 17px;
      width: 345px; } }
  @media only screen and (max-width: 991px) {
    .panel_wrapper.wrap {
      flex-direction: column; }
      .panel_wrapper.wrap .buttons-wrapper {
        margin-top: 20px; } }

.spinner_container {
  width: 100%;
  height: 100%;
  display: flex; }
  .spinner_container .spinner {
    margin: auto; }

.job-details-dropdown-wrapper {
  width: 120px; }

.add-item-to-package-dropdown-wrapper {
  width: 160px; }

.mark-up-dropdown-wrapper {
  width: 225px; }

.filter-report-dropdown-wrapper {
  width: 120px; }

.filter-report-dropdown-wrapper-operator {
  width: 180px; }

.package-page-dropdown-wrapper {
  width: 160px; }

.sidebar {
  height: 100%;
  width: 200px;
  color: white;
  background-color: #02354B; }
  .sidebar .current_version {
    font-family: Lato, serif;
    font-size: 9px;
    line-height: 11px;
    color: #E5E5E5;
    margin: 0 0 22px 22px;
    display: block; }

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .nav .menu_item .menu_item_container {
    padding: 0; }
    .nav .menu_item .menu_item_container .sub_items_container {
      padding: 0 0 5px; }
      .nav .menu_item .menu_item_container .sub_items_container a {
        padding: 0 0 0 55px; }
        .nav .menu_item .menu_item_container .sub_items_container a .nav-label {
          color: #B4C6D8; }
        .nav .menu_item .menu_item_container .sub_items_container a.active .nav-label {
          color: #00BAE5; }
        .nav .menu_item .menu_item_container .sub_items_container a:hover .nav-label {
          color: #FFFFFF; }
        .nav .menu_item .menu_item_container .sub_items_container a:hover .icon_container .fas {
          color: #FFFFFF; }
        .nav .menu_item .menu_item_container .sub_items_container a:hover .icon_container svg {
          fill: #FFFFFF; }
  .nav .menu_item a,
  .nav .menu_item .assets-management-wrapper {
    display: flex;
    padding: 10px 22px;
    font-family: Lato, serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #B4C6D8;
    align-items: center;
    height: 35px; }
    .nav .menu_item a .inner,
    .nav .menu_item .assets-management-wrapper .inner {
      display: flex;
      padding: 0;
      align-items: center;
      transition-duration: 0.3s; }
    .nav .menu_item a .icon_container,
    .nav .menu_item .assets-management-wrapper .icon_container {
      padding: 0;
      margin-right: 10px;
      transition-duration: 0.3s;
      min-width: 16px;
      text-align: center; }
    .nav .menu_item a .fa-qrcode,
    .nav .menu_item .assets-management-wrapper .fa-qrcode {
      font-size: 18px;
      color: #B4C6D8;
      transition-duration: 0.3s; }
    .nav .menu_item a svg,
    .nav .menu_item .assets-management-wrapper svg {
      fill: #B4C6D8;
      transition-duration: 0.3s; }
    .nav .menu_item a .fa-angle-up,
    .nav .menu_item .assets-management-wrapper .fa-angle-up {
      fill: #B4C6D8;
      transform: rotate(180deg);
      transition-duration: 0.3s; }
      .nav .menu_item a .fa-angle-up.active,
      .nav .menu_item .assets-management-wrapper .fa-angle-up.active {
        transform: rotate(360deg);
        color: #00BAE5; }
  .nav .menu_item a:hover .nav-label {
    color: #FFFFFF;
    transition-duration: 0.3s; }
  .nav .menu_item a:hover .icon_container .fas {
    color: #FFFFFF;
    transition-duration: 0.3s; }
  .nav .menu_item a:hover .icon_container svg {
    fill: #FFFFFF;
    transition-duration: 0.3s; }
  .nav .menu_item .assets-management-wrapper {
    justify-content: space-between; }
    .nav .menu_item .assets-management-wrapper:hover {
      color: #FFFFFF; }
      .nav .menu_item .assets-management-wrapper:hover .nav-label {
        color: #FFFFFF; }
      .nav .menu_item .assets-management-wrapper:hover .icon_container svg {
        fill: #FFFFFF; }
      .nav .menu_item .assets-management-wrapper:hover .fa-angle-up.active {
        color: #FFFFFF; }
  .nav .menu_item .sub_items_container a {
    height: 0;
    transition-duration: 0.3s; }
    .nav .menu_item .sub_items_container a .nav-label {
      font-size: 0;
      transition-duration: 0.3s; }
  .nav .menu_item.active_ca {
    background: #002636; }
    .nav .menu_item.active_ca .nav-label {
      color: #00BAE5; }
    .nav .menu_item.active_ca .fas {
      color: #00BAE5; }
    .nav .menu_item.active_ca svg {
      fill: #00BAE5; }
    .nav .menu_item.active_ca .fa-angle-up {
      color: #00BAE5; }
    .nav .menu_item.active_ca .assets-management-wrapper {
      padding: 0 22px; }
    .nav .menu_item.active_ca a .nav-label {
      font-family: Lato, serif;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #00BAE5;
      transition-duration: 0.3s; }
      .nav .menu_item.active_ca a .nav-label:hover {
        color: #FFFFFF; }
    .nav .menu_item.active_ca a:hover .nav-label {
      color: #FFFFFF; }
    .nav .menu_item.active_ca a:hover .icon_container svg {
      fill: #FFFFFF; }
    .nav .menu_item.active_ca .sub_items_container a {
      height: 35px; }
      .nav .menu_item.active_ca .sub_items_container a .nav-label {
        color: #B4C6D8;
        transition-duration: 0.3s; }
  .nav .menu_item.settings {
    margin: auto 22px 22px;
    background: #215E78;
    border-radius: 3px;
    height: 30px;
    transition-duration: 0.3s; }
    .nav .menu_item.settings a {
      padding: 0 20px;
      height: 30px;
      align-items: center; }
    .nav .menu_item.settings.active_ca {
      background: #002636; }
    .nav .menu_item.settings:hover {
      background: #346A81; }
      .nav .menu_item.settings:hover .nav-label {
        color: #FFFFFF; }

.header {
  padding: 10pt; }

.avatar-image {
  width: 50px;
  height: 50px;
  border-radius: 50% !important; }

.sidebar-label {
  padding-left: 29px;
  width: 100%;
  height: 100%;
  text-align: start; }

.ca_nav_header {
  margin: 50px 22px 14px;
  text-align: center;
  padding: 0;
  border-bottom: 1px solid #346A81; }
  .ca_nav_header .account_settings {
    cursor: auto;
    padding: 5px;
    margin: 0 auto;
    width: 60px;
    height: 60px;
    background-color: #FFFFFF;
    border-radius: 15%;
    display: flex;
    align-items: center; }
    .ca_nav_header .account_settings .avatar_image {
      max-height: 100%;
      max-width: 100%; }
  .ca_nav_header .company_title {
    display: block;
    font-family: Lato, serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin-top: 13px;
    color: #FFFFFF; }
    .ca_nav_header .company_title.subtitle {
      font-size: 10px;
      line-height: 12px;
      color: #B4C6D8;
      margin-top: 10px; }
      .ca_nav_header .company_title.subtitle.parent {
        font-weight: normal;
        margin-top: 3px;
        margin-bottom: 10px; }
      .ca_nav_header .company_title.subtitle.position {
        margin-top: 3px; }
    .ca_nav_header .company_title.main_menu {
      font-size: 12px;
      line-height: 14px;
      text-align: start;
      margin: 35px 0 14px; }

.disabledBlockElement {
  pointer-events: none;
  opacity: 0.4; }

.job_container {
  height: 100%; }
  .job_container .companies_wrapper.job {
    margin-top: 0;
    padding: 25px;
    background-color: #FFFFFF; }
    .job_container .companies_wrapper.job .panel_wrapper {
      margin: 0 0 32px; }
      .job_container .companies_wrapper.job .panel_wrapper .table_tools_block {
        display: flex;
        margin-right: auto; }
        .job_container .companies_wrapper.job .panel_wrapper .table_tools_block .white {
          margin-right: 15px; }
      .job_container .companies_wrapper.job .panel_wrapper .custom-dropdown {
        height: 35px;
        max-height: 35px;
        min-width: 150px; }
    .job_container .companies_wrapper.job .nav_switcher {
      height: 75%; }
      .job_container .companies_wrapper.job .nav_switcher .nav_switcher_ul {
        display: flex;
        border-bottom: 1px solid #CCCCCC;
        margin-bottom: 10px;
        margin-right: 8px; }
      .job_container .companies_wrapper.job .nav_switcher .switcher_link {
        font-family: Lato, serif;
        font-size: 13px;
        line-height: 16px;
        color: #999999;
        padding: 0 15px 7px;
        border-bottom: 1px solid #CCCCCC;
        margin-bottom: -1px;
        cursor: pointer; }
        .job_container .companies_wrapper.job .nav_switcher .switcher_link.active {
          font-weight: bold;
          color: #535353;
          border-bottom: 1px solid #00BAE5; }
    .job_container .companies_wrapper.job .nav_tab_wrapper {
      background-color: #f0f0f1; }
    .job_container .companies_wrapper.job .items_note_container {
      height: 94%;
      margin-top: -1px;
      overflow-y: auto;
      scrollbar-width: thin;
      display: flex;
      flex-direction: column; }
      .job_container .companies_wrapper.job .items_note_container .categories_list {
        border: 1px solid #DDE7ED;
        border-radius: 3px;
        margin-bottom: 10px;
        margin-right: 8px;
        transition-property: color; }
        .job_container .companies_wrapper.job .items_note_container .categories_list .list_item {
          display: flex;
          padding: 2px 15px 0;
          border-radius: 3px;
          cursor: pointer;
          background-color: #F0F0F0;
          transition-duration: 0.3s;
          transition-property: color; }
          .job_container .companies_wrapper.job .items_note_container .categories_list .list_item .note_title {
            font-family: Lato, serif;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #535353;
            margin-top: 3px; }
            .job_container .companies_wrapper.job .items_note_container .categories_list .list_item .note_title.num {
              margin-left: 5px; }
          .job_container .companies_wrapper.job .items_note_container .categories_list .list_item .plus-category {
            padding-top: 2px; }
          .job_container .companies_wrapper.job .items_note_container .categories_list .list_item.expand_notes {
            border-bottom: 1px solid #DDDDDD;
            margin-bottom: 15px;
            border-radius: 3px 3px 0 0; }
            .job_container .companies_wrapper.job .items_note_container .categories_list .list_item.expand_notes .plus-category,
            .job_container .companies_wrapper.job .items_note_container .categories_list .list_item.expand_notes .note_title {
              color: #00BAE5; }
          .job_container .companies_wrapper.job .items_note_container .categories_list .list_item.outside {
            background-color: #FFFFFF; }
        .job_container .companies_wrapper.job .items_note_container .categories_list.categories_list_expanded {
          border: 1px solid #00BAE5;
          border-radius: 3px 3px 0 0; }
          .job_container .companies_wrapper.job .items_note_container .categories_list.categories_list_expanded .categories_list {
            background-color: #F8F8F8;
            transition-property: color; }
            .job_container .companies_wrapper.job .items_note_container .categories_list.categories_list_expanded .categories_list .expand_notes {
              margin-bottom: 0; }
          .job_container .companies_wrapper.job .items_note_container .categories_list.categories_list_expanded:hover {
            border: 1px solid #00BAE5; }
        .job_container .companies_wrapper.job .items_note_container .categories_list.notes_list_item_outer {
          background-color: #F8F8F8; }
          .job_container .companies_wrapper.job .items_note_container .categories_list.notes_list_item_outer .categories_list {
            margin: 0 15px 0 40px;
            border: none; }
            .job_container .companies_wrapper.job .items_note_container .categories_list.notes_list_item_outer .categories_list:not(:last-child) {
              border-bottom: 1px solid #E0E0E0; }
            .job_container .companies_wrapper.job .items_note_container .categories_list.notes_list_item_outer .categories_list .notes_list_item .notes_wrapper .company_item_name {
              padding: 10px 0; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.notes_list_item_outer .categories_list .notes_list_item .notes_wrapper .company_item_name .buttons_note_wrapper {
                margin-left: 15px; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.notes_list_item_outer .categories_list .notes_list_item .notes_wrapper .company_item_name .item_name_property {
                font-size: 13px;
                line-height: 140%; }
        .job_container .companies_wrapper.job .items_note_container .categories_list .subcategory_list {
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 5px; }
          .job_container .companies_wrapper.job .items_note_container .categories_list .subcategory_list.categories_list_expanded {
            border: 1px solid #F8F8F8; }
            .job_container .companies_wrapper.job .items_note_container .categories_list .subcategory_list.categories_list_expanded .categories_list {
              background-color: #F8F8F8;
              margin-left: 40px;
              margin-right: 15px;
              margin-bottom: 0; }
            .job_container .companies_wrapper.job .items_note_container .categories_list .subcategory_list.categories_list_expanded:hover {
              border: 1px solid #F8F8F8; }
            .job_container .companies_wrapper.job .items_note_container .categories_list .subcategory_list.categories_list_expanded .placeholders-list .placeholder_list_item:hover {
              background-color: #F8F8F8; }
          .job_container .companies_wrapper.job .items_note_container .categories_list .subcategory_list .list_item:hover {
            background-color: #F8F8F8; }
          .job_container .companies_wrapper.job .items_note_container .categories_list .subcategory_list:last-child {
            margin-bottom: 10px; }
        .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list {
          border: none; }
          .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item {
            background-color: #F8F8F8; }
            .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_image_wrapper {
              width: 90px;
              height: 90px;
              background: #FFFFFF;
              border: 1px solid #dde4eb;
              box-sizing: border-box;
              border-radius: 3px;
              margin-left: 15px;
              display: flex;
              align-items: center;
              position: relative; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_image_wrapper .accessorie_image {
                max-width: 100%;
                max-height: 100%;
                opacity: 1; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_image_wrapper .zoom_icon {
                border-radius: 3px;
                transition: .3s ease;
                opacity: 0;
                position: absolute;
                height: inherit;
                width: inherit;
                display: flex;
                top: 0;
                left: 0; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_image_wrapper .zoom_icon .fa-search-plus {
                  margin: auto;
                  color: #FFFFFF;
                  font-size: 23px; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_image_wrapper:hover {
                cursor: pointer; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_image_wrapper:hover img {
                  opacity: 0.7;
                  background: rgba(0, 0, 0, 0.3); }
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_image_wrapper:hover .zoom_icon {
                  opacity: 1;
                  background: rgba(0, 0, 0, 0.3); }
            .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container {
              display: flex;
              padding: 10px 0;
              align-items: center; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .accessorie_wrapper .accessorie_title {
                font-family: Lato, serif;
                font-size: 13px;
                line-height: 16px;
                color: #535353;
                margin-top: 5px;
                transition-duration: 0.3s; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .accessorie_wrapper .accessorie_title:hover {
                  color: #00BAE5;
                  transition-duration: 0.3s; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .accessorie_wrapper .company_item_name {
                display: flex;
                margin: 25px 0 15px; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .accessorie_wrapper .company_item_name .item_name_container:not(:last-child) {
                  padding-right: 20px;
                  margin-right: 20px; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .accessorie_wrapper .company_item_name .item_name_container .item_name_title,
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .accessorie_wrapper .company_item_name .item_name_container .item_name_property {
                  font-family: Lato, serif;
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 14px;
                  color: #535353; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .accessorie_wrapper .company_item_name .item_name_container .item_name_title {
                  margin-bottom: 5px; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .accessorie_wrapper .company_item_name .item_name_container .item_name_property {
                  font-weight: normal; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .with_img_container {
                display: flex;
                margin-left: auto;
                align-items: center; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .with_img_container .buttons_note_wrapper {
                  display: flex;
                  margin-left: 15px;
                  min-width: 55px; }
                  .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .with_img_container .buttons_note_wrapper .ss-btn.d_btn.refresh:not(:last-child) {
                    margin-right: 5px; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .accessorie_wrapper_container .with_img_container.with_img {
                  align-items: flex-start; }
            .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .note_with_selection {
              display: flex;
              padding-bottom: 10px; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .note_with_selection .job-details-dropdown-wrapper {
                margin-left: 15px; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .note_with_selection .accessorie_image_wrapper .danger_badge {
                position: relative;
                top: auto;
                right: auto; }
            .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .img_dropdown_container {
              margin-right: 70px;
              display: flex;
              margin-left: auto; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .accessorie_list_item .accessorie_list_item_inner .img_dropdown_container .danger_badge {
                position: relative;
                top: auto;
                right: auto;
                height: 19px;
                margin-left: 15px; }
          .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .placeholder_list_item {
            display: flex;
            align-items: center;
            padding: 5px 0; }
            .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .placeholder_list_item .accessorie_title {
              margin-right: auto;
              transition-duration: 0.3s; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .placeholder_list_item .accessorie_title:hover {
                color: #00BAE5;
                transition-duration: 0.3s; }
            .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .placeholder_list_item .placeholder_note_without_selection {
              margin-right: 45px; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .placeholder_list_item .placeholder_note_without_selection .danger_badge_container {
                display: flex;
                flex-direction: column; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .placeholder_list_item .placeholder_note_without_selection .danger_badge_container .danger_badge {
                  position: relative;
                  margin-top: 10px;
                  margin-left: auto;
                  height: 19px; }
            .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .placeholder_list_item .ss-btn.d_btn.refresh.edit_b {
              margin: 0; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list .placeholder_list_item .ss-btn.d_btn.refresh.edit_b.plus {
                margin-left: 5px; }
          .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list:hover {
            border: none; }
          .job_container .companies_wrapper.job .items_note_container .categories_list.placeholder_list:not(:last-child) {
            border-bottom: 1px solid #E0E0E0; }
        .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item {
          padding: 15px;
          background-color: #F8F8F8; }
          .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .item_name_title,
          .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .item_name_property {
            font-family: Lato, serif;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: #535353; }
          .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .item_name_property {
            font-weight: normal; }
          .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper {
            display: flex; }
            .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .item_name_property {
              margin-right: auto; }
            .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .accessorie_image_wrapper {
              width: 90px;
              height: 90px;
              background: #FFFFFF;
              border: 1px solid #A9B9C7;
              box-sizing: border-box;
              border-radius: 3px;
              margin-left: 15px;
              display: flex;
              align-items: center;
              position: relative; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .accessorie_image_wrapper .accessorie_image {
                max-width: 100%;
                max-height: 100%;
                opacity: 1; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .accessorie_image_wrapper .zoom_icon {
                border-radius: 3px;
                transition: .3s ease;
                opacity: 0;
                position: absolute;
                height: inherit;
                width: inherit;
                display: flex;
                top: 0;
                left: 0; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .accessorie_image_wrapper .zoom_icon .fa-search-plus {
                  margin: auto;
                  color: #FFFFFF;
                  font-size: 23px; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .accessorie_image_wrapper:hover {
                cursor: pointer; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .accessorie_image_wrapper:hover img {
                  opacity: 0.7;
                  background: rgba(0, 0, 0, 0.3); }
                .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .accessorie_image_wrapper:hover .zoom_icon {
                  opacity: 1;
                  background: rgba(0, 0, 0, 0.3); }
            .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .with_img_container {
              display: flex;
              margin-left: auto;
              align-items: center; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .with_img_container .buttons_note_wrapper {
                margin-left: 15px;
                min-width: 55px; }
                .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .with_img_container .buttons_note_wrapper .ss-btn.d_btn.refresh:not(:last-child) {
                  margin-right: 5px; }
              .job_container .companies_wrapper.job .items_note_container .categories_list.subcategory_list .notes_list_item .note_items_wrapper .with_img_container.with_img {
                align-items: flex-start; }
        .job_container .companies_wrapper.job .items_note_container .categories_list:hover {
          border: 1px solid #00BAE5; }
        .job_container .companies_wrapper.job .items_note_container .categories_list.notes_inner {
          border: none; }
          .job_container .companies_wrapper.job .items_note_container .categories_list.notes_inner .note_items_wrapper {
            border-bottom: 1px solid #E0E0E0;
            align-items: center;
            padding: 5px 0; }

.job_properties_container {
  display: flex;
  align-items: flex-start;
  background: #F9F9F9;
  border-radius: 3px;
  padding: 12px 15px 10px;
  margin-bottom: 25px; }
  .job_properties_container .button_back_container {
    display: flex;
    align-items: center;
    padding-right: 30px;
    min-height: 41px; }
    @media only screen and (min-width: 1365px) {
      .job_properties_container .button_back_container {
        padding-right: 20px; } }
    .job_properties_container .button_back_container .ss-btn.d_btn.refresh {
      margin-right: 0;
      width: 25px;
      height: 25px;
      padding: 5px; }
    .job_properties_container .button_back_container .title,
    .job_properties_container .button_back_container .property_title,
    .job_properties_container .button_back_container .property {
      font-family: Lato, serif;
      font-size: 12px;
      line-height: 14px;
      color: #535353;
      transition-duration: 0.3s; }
    .job_properties_container .button_back_container .info_container {
      width: 66px; }
      .job_properties_container .button_back_container .info_container .title {
        margin: 0 0 8px;
        transition-duration: 0.3s;
        width: 66px; }
      .job_properties_container .button_back_container .info_container .number {
        font-family: Lato, serif;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #1090C0;
        max-width: 66px;
        width: min-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
  .job_properties_container .properties_wrapper {
    display: flex;
    max-width: calc(100% - 195px); }
    @media only screen and (max-width: 1366px) {
      .job_properties_container .properties_wrapper {
        flex-grow: initial; } }
    .job_properties_container .properties_wrapper .properties_two_wrapper {
      display: flex; }
      @media only screen and (max-width: 1366px) {
        .job_properties_container .properties_wrapper .properties_two_wrapper .properties_container .property_wrapper {
          width: 65px; }
          .job_properties_container .properties_wrapper .properties_two_wrapper .properties_container .property_wrapper.titles {
            width: 90px; } }
  .job_properties_container .properties_container {
    margin-right: 80px;
    display: flex; }
    .job_properties_container .properties_container .property_wrapper {
      margin-right: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: auto; }
      .job_properties_container .properties_container .property_wrapper.titles {
        width: max-content; }
      .job_properties_container .properties_container .property_wrapper .property_title {
        font-weight: bold;
        margin: 0;
        font-size: 12px;
        cursor: default;
        line-height: 14px; }
        .job_properties_container .properties_container .property_wrapper .property_title:first-child {
          margin-bottom: 10px; }
      .job_properties_container .properties_container .property_wrapper .property {
        margin-top: 0;
        font-size: 12px;
        line-height: 14px;
        padding-left: 3px;
        color: #999999;
        font-weight: 400;
        cursor: default; }
        .job_properties_container .properties_container .property_wrapper .property:first-child {
          margin-bottom: 11px; }
  .job_properties_container .buttons_container {
    min-width: 137px;
    min-height: 40px;
    align-items: center;
    display: flex;
    margin-left: auto; }
    .job_properties_container .buttons_container .new {
      border-radius: 2px;
      font-weight: bold;
      font-size: 9px;
      line-height: 74%;
      text-align: center;
      letter-spacing: 0.03em;
      padding: 0 26px;
      height: 16.35px;
      margin-right: 15px; }
    .job_properties_container .buttons_container .ss-btn.d_btn.refresh.edit_b {
      margin-right: 5px; }

.change_headers_color_container .choose_header_label {
  font-family: Lato, serif;
  font-size: 12px;
  line-height: 14px;
  color: #535353; }
  .change_headers_color_container .choose_header_label.not_allowed {
    color: #B5B5B5; }

.change_headers_color_container .choose_headers_wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px; }

.change_headers_color_container .choose_header_container {
  position: relative;
  margin-left: auto; }
  .change_headers_color_container .choose_header_container .color_box {
    width: 20px;
    height: 20px;
    border: 1px solid #9D9D9D;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer; }
    .change_headers_color_container .choose_header_container .color_box.forbid {
      cursor: not-allowed; }
  .change_headers_color_container .choose_header_container .color_picker {
    background-color: #F8F6F6;
    position: absolute;
    top: 25px;
    right: 0;
    border-radius: 3px;
    z-index: 9; }
    .change_headers_color_container .choose_header_container .color_picker .buttons_picker_wrapper {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      border: 1px solid #CCCCCC;
      border-radius: 4px;
      background-color: #FFFFFF; }
      .change_headers_color_container .choose_header_container .color_picker .buttons_picker_wrapper .button_ok,
      .change_headers_color_container .choose_header_container .color_picker .buttons_picker_wrapper .button_cancel {
        width: auto;
        font-size: 10px;
        line-height: 12px;
        height: 24px;
        padding-left: 10px;
        padding-right: 10px; }
      .change_headers_color_container .choose_header_container .color_picker .buttons_picker_wrapper .button_cancel {
        margin-right: 10px; }

.attribute-filter-wrapper-regular {
  padding-left: 10px;
  overflow-y: auto;
  height: calc(100% - 45px);
  scrollbar-width: thin; }

.attribute-filter-wrapper-regular {
  margin-top: 7px;
  padding: 0; }
  .attribute-filter-wrapper-regular::-webkit-scrollbar {
    width: 1px; }
  .attribute-filter-wrapper-regular .filter_attribute_container {
    width: 100%;
    max-width: 253px; }
  .attribute-filter-wrapper-regular .filter-attribute-wrapper,
  .attribute-filter-wrapper-regular .square-checkbox-wrapper {
    background: #EFEFF0;
    border-radius: 3px;
    height: 30px;
    margin-bottom: 5px;
    margin-right: 25px;
    padding: 0 10px 0 9px;
    font-family: Lato, serif;
    font-size: 12px;
    line-height: 74%;
    color: #535353;
    border: 1px solid #EFEFF0; }
    .attribute-filter-wrapper-regular .filter-attribute-wrapper .fa-check-square.active-filter-icon:before,
    .attribute-filter-wrapper-regular .square-checkbox-wrapper .fa-check-square.active-filter-icon:before {
      color: #00BAE5; }
    .attribute-filter-wrapper-regular .filter-attribute-wrapper .fas.fa-square,
    .attribute-filter-wrapper-regular .square-checkbox-wrapper .fas.fa-square {
      border: 1px solid #00BAE5;
      border-radius: 3px;
      padding: 1px 1px 0 1.5px;
      color: #00BAE5; }
    .attribute-filter-wrapper-regular .filter-attribute-wrapper .filter-attribute-name,
    .attribute-filter-wrapper-regular .filter-attribute-wrapper .filter_checkbox_name,
    .attribute-filter-wrapper-regular .square-checkbox-wrapper .filter-attribute-name,
    .attribute-filter-wrapper-regular .square-checkbox-wrapper .filter_checkbox_name {
      white-space: nowrap; }
    .attribute-filter-wrapper-regular .filter-attribute-wrapper .filter_checkbox_name,
    .attribute-filter-wrapper-regular .square-checkbox-wrapper .filter_checkbox_name {
      line-height: 100%;
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis; }
    .attribute-filter-wrapper-regular .filter-attribute-wrapper .fas.fa-chevron-right, .attribute-filter-wrapper-regular .filter-attribute-wrapper .fas.fa-chevron-down,
    .attribute-filter-wrapper-regular .square-checkbox-wrapper .fas.fa-chevron-right,
    .attribute-filter-wrapper-regular .square-checkbox-wrapper .fas.fa-chevron-down {
      font-size: 9px; }
    .attribute-filter-wrapper-regular .filter-attribute-wrapper:hover,
    .attribute-filter-wrapper-regular .square-checkbox-wrapper:hover {
      border: 1px solid #00BAE5;
      font-weight: normal; }
    .attribute-filter-wrapper-regular .filter-attribute-wrapper.show_all,
    .attribute-filter-wrapper-regular .square-checkbox-wrapper.show_all {
      border: 1px solid #00BAE5;
      color: #00BAE5; }
  .attribute-filter-wrapper-regular .square-checkbox-wrapper {
    height: 25px;
    margin: 0 25px 5px 23px;
    padding: 0 10px;
    font-size: 12px;
    position: relative;
    background-color: #F5F5F5; }
    .attribute-filter-wrapper-regular .square-checkbox-wrapper .check-box-wrapper {
      top: 4px;
      right: 10px; }

.select_add_item_attribute_form.attribute_modal {
  padding-right: 20px; }
  .select_add_item_attribute_form.attribute_modal .filter_above_line {
    width: 340px;
    height: 1px;
    background-color: #E0E0E0;
    display: block;
    margin-bottom: 9px; }
  .select_add_item_attribute_form.attribute_modal .button_wrapper_above {
    display: flex;
    justify-content: space-between;
    height: 32px;
    padding-bottom: 7px; }
    .select_add_item_attribute_form.attribute_modal .button_wrapper_above .refresh {
      height: 25px;
      width: 25px;
      padding: 5px; }
      .select_add_item_attribute_form.attribute_modal .button_wrapper_above .refresh:last-child {
        margin-right: 10px; }
      .select_add_item_attribute_form.attribute_modal .button_wrapper_above .refresh svg {
        fill: #1090C0;
        transition-duration: 0.3s; }
      .select_add_item_attribute_form.attribute_modal .button_wrapper_above .refresh:hover svg {
        fill: #FFFFFF; }
  .select_add_item_attribute_form.attribute_modal .attribute-filter-wrapper-regular {
    height: auto;
    max-height: 400px;
    scrollbar-width: thin; }
    .select_add_item_attribute_form.attribute_modal .attribute-filter-wrapper-regular .filter-attribute-wrapper,
    .select_add_item_attribute_form.attribute_modal .attribute-filter-wrapper-regular .square-checkbox-wrapper {
      margin-right: 10px;
      font-size: 12px; }
      .select_add_item_attribute_form.attribute_modal .attribute-filter-wrapper-regular .filter-attribute-wrapper .filter_checkbox_name,
      .select_add_item_attribute_form.attribute_modal .attribute-filter-wrapper-regular .square-checkbox-wrapper .filter_checkbox_name {
        width: 270px; }

.nav_switcher_ul_container {
  margin-bottom: 5px;
  overflow-x: auto;
  scrollbar-width: thin;
  height: 40px;
  flex: 0 0 auto; }
  .nav_switcher_ul_container .nav_switcher_ul {
    display: flex;
    border-bottom: 2px solid #CCCCCC;
    scrollbar-width: thin; }
    .nav_switcher_ul_container .nav_switcher_ul .switcher_link {
      font-family: Lato, serif;
      font-weight: 400;
      color: #ababab;
      padding: 0 15px 10px;
      border-bottom: 2px solid #CCCCCC;
      margin-bottom: -2px;
      cursor: pointer;
      white-space: nowrap;
      font-size: 13px;
      line-height: 13px; }
      .nav_switcher_ul_container .nav_switcher_ul .switcher_link .fas {
        font-size: 13px;
        line-height: 13px; }
      .nav_switcher_ul_container .nav_switcher_ul .switcher_link.active {
        font-weight: 400;
        color: #535353;
        border-bottom: 2px solid #1090c0; }
        .nav_switcher_ul_container .nav_switcher_ul .switcher_link.active .fas {
          color: #1090c0; }
      .nav_switcher_ul_container .nav_switcher_ul .switcher_link:not(.active) .fas {
        line-height: 17px; }
      .nav_switcher_ul_container .nav_switcher_ul .switcher_link:hover {
        color: #00bae5;
        border-bottom: 2px solid #00BAE5; }

.custom_dropdown {
  position: relative;
  font-family: Lato,sans-serif;
  background: #fff;
  border: 1px solid #a9b9c7;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 270px;
  height: 35px;
  width: auto; }
  .custom_dropdown .custom_dropdown-button-dropdown-selection {
    font-size: 12px;
    width: 240px; }
  .custom_dropdown .custom_dropdown_button {
    padding: 13px 40px 13px 11px;
    font-family: Lato,serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 74%;
    color: #535353; }
    .custom_dropdown .custom_dropdown_button:hover {
      cursor: pointer; }
  .custom_dropdown .dropdown-line {
    position: absolute;
    height: 23px;
    top: 6px;
    right: 30px;
    width: 1px;
    background: #a9b9c7; }
  .custom_dropdown .dropdown-caret {
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: #a9b9c7;
    right: 9px;
    top: 15px; }
    .custom_dropdown .dropdown-caret:hover {
      cursor: pointer; }
  .custom_dropdown .custom_dropdown_list {
    background: #fff;
    border: 1px solid #00bae5;
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
    width: 98%;
    max-height: 156px;
    overflow-y: auto;
    margin-left: 2px;
    margin-top: -1px;
    position: absolute;
    z-index: 1000;
    padding-right: 8px; }
    .custom_dropdown .custom_dropdown_list .custom_dropdown-button-dropdown-selection {
      backdrop-filter: blur(1px);
      width: 97%;
      padding: 8px 11px;
      margin: 5px 0 5px 4px; }
      .custom_dropdown .custom_dropdown_list .custom_dropdown-button-dropdown-selection:hover {
        background-color: #0f90c0;
        color: #fff; }
  .custom_dropdown.open {
    border: 1px solid #00bae5; }
    .custom_dropdown.open .dropdown-caret {
      border-top-color: #00bae5; }
  .custom_dropdown:hover {
    border: 1px solid #00bae5; }

.attribute_value_wrapper {
  background: #FFFFFF;
  border: 1px solid #A9B9C7;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 7px 7px 0;
  display: flex;
  flex-wrap: wrap; }
  .attribute_value_wrapper .ss-btn {
    width: 22px;
    height: 21px;
    padding: 0;
    margin-bottom: 7px;
    display: flex; }
    .attribute_value_wrapper .ss-btn .fa-plus {
      margin: auto; }
  .attribute_value_wrapper .cheeps {
    background: #DDE7ED;
    border-radius: 2px;
    font-family: Lato, serif;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: #535353;
    margin: 0 5px 7px 0;
    height: 21px;
    padding: 4px 26px 5px 10px;
    transition-duration: 0.3s; }
    .attribute_value_wrapper .cheeps .cheeps-delete-icon-wrapper {
      margin: 0 9px 0 0; }
    .attribute_value_wrapper .cheeps svg {
      fill: #535353;
      transition-duration: 0.3s; }
    .attribute_value_wrapper .cheeps .cheeps_title {
      transition-duration: 0.3s;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .attribute_value_wrapper .cheeps:hover {
      transition-duration: 0.3s; }
      .attribute_value_wrapper .cheeps:hover svg {
        fill: #00bae5; }
      .attribute_value_wrapper .cheeps:hover .cheeps_item,
      .attribute_value_wrapper .cheeps:hover .cheeps_title {
        transition-duration: 0.3s;
        color: #00bae5; }
  .attribute_value_wrapper .category-attributes-list {
    background: #FFFFFF;
    border: 1px solid #00BAE5;
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
    width: 100%;
    max-height: 156px;
    overflow-y: auto;
    margin-left: 0;
    margin-top: -1px;
    position: absolute;
    z-index: 1000;
    left: 0;
    scrollbar-width: thin; }
    .attribute_value_wrapper .category-attributes-list div {
      font-family: Lato, serif;
      font-style: normal;
      font-weight: normal;
      -webkit-backdrop-filter: blur(1px);
      backdrop-filter: blur(1px);
      font-size: 12px;
      line-height: 74%;
      color: #535353;
      width: 97%;
      padding: 8px 11px;
      margin: 5px 0 5px 4px; }
      .attribute_value_wrapper .category-attributes-list div:hover {
        color: #FFFFFF; }

.create-edit-form.add_note .upload-image-label {
  width: 100px;
  height: 100px;
  color: #535353; }
  .create-edit-form.add_note .upload-image-label .uploaded-image {
    max-width: 98px;
    max-height: 98px; }

.create-edit-form.add_note .replace-logo-frame {
  width: 101%; }

.create-edit-form.add_note .delete-image-icon-wrapper {
  color: #fff;
  background: #ff6565;
  border-radius: 2px;
  width: 17px;
  height: 17px;
  display: flex; }
  .create-edit-form.add_note .delete-image-icon-wrapper .fa-times {
    margin: auto;
    margin-top: 3px; }

.create-edit-form.add_note .note_inner_wrapper {
  display: flex;
  flex-wrap: wrap; }
  .create-edit-form.add_note .note_inner_wrapper .item_label {
    font-family: Lato, serif;
    font-size: 12px;
    line-height: 12px;
    color: #535353;
    margin-bottom: 5px;
    display: block;
    margin-top: 30px;
    font-weight: 500; }
    .create-edit-form.add_note .note_inner_wrapper .item_label.note {
      margin-top: 0; }
  .create-edit-form.add_note .note_inner_wrapper .items_container {
    margin-left: 15px;
    width: 225px; }
    .create-edit-form.add_note .note_inner_wrapper .items_container .note_text_area {
      resize: none;
      background: #fbfcfc;
      border: 1px solid #DDE4EB;
      box-sizing: border-box;
      border-radius: 2px;
      width: 225px;
      height: 140px;
      font-family: Lato, serif;
      font-size: 12px;
      line-height: 18px;
      color: #535353;
      padding: 6px 7px;
      scrollbar-width: thin; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .note_text_area:hover, .create-edit-form.add_note .note_inner_wrapper .items_container .note_text_area:focus {
        border: 1px solid #00BAE5; }
    .create-edit-form.add_note .note_inner_wrapper .items_container .custom_dropdown {
      min-width: 225px;
      width: 225px;
      background: #FBFCFC;
      border: 1px solid #DDE4EB;
      border-radius: 2px;
      height: 40px;
      margin-top: 15px; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .custom_dropdown .dropdown-caret {
        top: 17px; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .custom_dropdown .custom_dropdown_button {
        font-size: 13px;
        line-height: 13px;
        color: #b9c0c5; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .custom_dropdown .dropdown-line {
        display: none; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .custom_dropdown .custom_dropdown_list {
        top: 35px;
        border-top: none;
        scrollbar-width: thin; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .custom_dropdown.open {
        border-color: #00bae5; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .custom_dropdown.haveValue .custom_dropdown_button {
        color: #535353; }
    .create-edit-form.add_note .note_inner_wrapper .items_container .add-attributes-button-wrapper {
      margin-top: 15px; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .add-attributes-button-wrapper .custom-dropdown-selection-overall {
        height: 40px;
        background: #FBFCFC;
        border: 1px solid #DDE4EB;
        border-radius: 2px; }
        .create-edit-form.add_note .note_inner_wrapper .items_container .add-attributes-button-wrapper .custom-dropdown-selection-overall .dropdown-caret {
          top: 17px; }
        .create-edit-form.add_note .note_inner_wrapper .items_container .add-attributes-button-wrapper .custom-dropdown-selection-overall .dropdown-line {
          display: none; }
        .create-edit-form.add_note .note_inner_wrapper .items_container .add-attributes-button-wrapper .custom-dropdown-selection-overall .custom-dropdown-button {
          font-size: 13px;
          line-height: 13px;
          color: #b9c0c5; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .add-attributes-button-wrapper .btn {
        height: 100%;
        font-size: 10px;
        line-height: 26px;
        flex: 0 0 40px; }
        .create-edit-form.add_note .note_inner_wrapper .items_container .add-attributes-button-wrapper .btn[disabled] {
          background-color: #E5E5E5;
          border-color: #E5E5E5;
          color: #cccccc; }
    .create-edit-form.add_note .note_inner_wrapper .items_container .dynamic_input .add_attributes_button_wrapper {
      justify-content: space-between; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .dynamic_input .add_attributes_button_wrapper .job-info-label {
        font-size: 10px;
        line-height: 12px; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .dynamic_input .add_attributes_button_wrapper .table-button .fas {
        font-size: 12px;
        line-height: 12px; }
    .create-edit-form.add_note .note_inner_wrapper .items_container .dynamic_input .attribute_value_wrapper {
      border: 0;
      padding: 2px 2px;
      min-height: 24px; }
      .create-edit-form.add_note .note_inner_wrapper .items_container .dynamic_input .attribute_value_wrapper .cheeps {
        height: 20px;
        padding: 3px 25px 4px 10px;
        margin: 0 3px 0 0;
        border: 0; }
        .create-edit-form.add_note .note_inner_wrapper .items_container .dynamic_input .attribute_value_wrapper .cheeps .cheeps-delete-icon-wrapper {
          top: 2px; }
  .create-edit-form.add_note .note_inner_wrapper .button_container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 30px; }
    .create-edit-form.add_note .note_inner_wrapper .button_container .pink {
      background-color: #ffefef;
      color: red;
      padding: 0 8.5px;
      font-size: 20px; }
  .create-edit-form.add_note .note_inner_wrapper .dynamicInputContainer {
    margin-right: -8px;
    padding-right: 8px;
    scrollbar-width: thin; }
  .create-edit-form.add_note .note_inner_wrapper .dynamic_input {
    margin-top: 10px; }
    .create-edit-form.add_note .note_inner_wrapper .dynamic_input .add_attributes_button_wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3px; }
      .create-edit-form.add_note .note_inner_wrapper .dynamic_input .add_attributes_button_wrapper .job-info-label {
        font-family: Lato, serif;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #535353; }
      .create-edit-form.add_note .note_inner_wrapper .dynamic_input .add_attributes_button_wrapper .fa-trash {
        color: #1090C0;
        transition-duration: 0.3s; }
        .create-edit-form.add_note .note_inner_wrapper .dynamic_input .add_attributes_button_wrapper .fa-trash.without_button:hover {
          transition-duration: 0.3s;
          color: #33B5E5; }
    .create-edit-form.add_note .note_inner_wrapper .dynamic_input .attribute_value_wrapper {
      background: #F5F5F7;
      border-radius: 3px;
      padding: 3px 0 0 3px;
      min-height: 27px; }
      .create-edit-form.add_note .note_inner_wrapper .dynamic_input .attribute_value_wrapper .cheeps {
        margin: 0 3px 3px 0;
        height: 19px;
        padding: 3px 25px 4px 10px;
        max-width: 100%;
        width: fit-content; }
        .create-edit-form.add_note .note_inner_wrapper .dynamic_input .attribute_value_wrapper .cheeps div {
          max-width: 215px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 600; }
        .create-edit-form.add_note .note_inner_wrapper .dynamic_input .attribute_value_wrapper .cheeps .cheeps-delete-icon-wrapper {
          top: 1px; }
    .create-edit-form.add_note .note_inner_wrapper .dynamic_input .attribute_input {
      background: #F5F5F7;
      border: none;
      font-family: Lato, serif;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: #535353;
      width: 100%;
      flex-grow: 1;
      min-width: 40px;
      min-height: 21px; }
  .create-edit-form.add_note .note_inner_wrapper .asset_name {
    font-family: Lato, serif;
    font-size: 14px;
    line-height: 17px;
    color: #535353;
    width: 100%; }
    .create-edit-form.add_note .note_inner_wrapper .asset_name span {
      font-weight: bold;
      margin-right: 3px; }
  .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container {
    width: 550px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .input_name_wrapper {
      width: 260px; }
      .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .input_name_wrapper.description {
        width: 100%;
        margin-top: 20px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container:focus {
      background-color: #E3F0FF; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dropdown_container {
      width: 260px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .add-attributes-button-wrapper.attributes {
      width: 100%; }
      .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .add-attributes-button-wrapper.attributes .custom-dropdown-selection-overall {
        margin-right: 12px;
        width: 474px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dynamicInputContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dynamicInputContainer .dynamic_input {
        width: 47%;
        margin-top: 15px; }
        .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dynamicInputContainer .dynamic_input .cheeps div {
          max-width: 170px; }
        .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dynamicInputContainer .dynamic_input .cheeps svg {
          fill: #535353; }
        .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dynamicInputContainer .dynamic_input .cheeps .cheeps_title {
          transition-duration: 0.3s; }
        .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dynamicInputContainer .dynamic_input .cheeps:hover svg {
          fill: #00bae5; }
        .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dynamicInputContainer .dynamic_input .cheeps:hover .cheeps_item {
          color: #00bae5; }
        .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dynamicInputContainer .dynamic_input .attribute_input {
          width: fit-content; }
          .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dynamicInputContainer .dynamic_input .attribute_input.color_red {
            color: #ff6565; }
        .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dynamicInputContainer .dynamic_input .attribute_value_container {
          display: flex;
          justify-content: space-between; }
          .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container .items_container .dynamicInputContainer .dynamic_input .attribute_value_container .attribute_value_wrapper {
            width: 220px; }
  .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container {
    width: 451px;
    margin-top: 30px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .item_label {
      margin-top: 0; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .input_name_wrapper {
      width: 260px; }
      .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .input_name_wrapper.description {
        width: 100%;
        margin-top: 20px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dropdown_container {
      width: 260px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .add-attributes-button-wrapper.attributes {
      width: 100%; }
      .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .add-attributes-button-wrapper.attributes .custom-dropdown-selection-overall {
        margin-right: 12px;
        width: 474px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer {
      width: 100%;
      display: block;
      border: 1px solid #D8E7F4;
      border-radius: 3px;
      margin-top: 15px;
      overflow: auto;
      padding: 0 15px 14px 13px;
      transition-duration: 0.3s;
      height: 200px; }
      .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .dynamic_input {
        width: 100%;
        margin-top: 10px;
        transition-duration: 0.3s; }
        .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .dynamic_input .cheeps {
          transition-duration: 0.3s;
          height: 21px; }
          .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .dynamic_input .cheeps div {
            max-width: 370px; }
          .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .dynamic_input .cheeps svg {
            fill: #535353;
            transition-duration: 0.3s; }
          .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .dynamic_input .cheeps .cheeps_item {
            transition-duration: 0.3s;
            font-weight: 500; }
          .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .dynamic_input .cheeps:hover svg {
            fill: #00bae5;
            transition-duration: 0.3s; }
          .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .dynamic_input .cheeps:hover .cheeps_item {
            color: #00bae5; }
        .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .dynamic_input .attribute_input {
          width: fit-content; }
        .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .dynamic_input .attribute_value_container {
          display: flex;
          justify-content: space-between; }
          .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .dynamic_input .attribute_value_container .attribute_value_wrapper {
            border: none;
            transition-duration: 0.3s;
            width: 100%; }
      .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .fa-trash.dynamic {
        transition-duration: 0.3s; }
        .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .items_container .dynamicInputContainer .fa-trash.dynamic:hover {
          color: #00BAE5;
          transition-duration: 0.3s; }
  .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .switch_wrapper_container {
    margin-left: 10px; }
  .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .img_category_container {
    width: 260px;
    margin-top: 30px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .img_category_container .dropdown_container .item_label {
      margin-top: 27px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .img_category_container .upload-image-label {
      width: 109px;
      height: 109px; }
      .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.cp .img_category_container .upload-image-label .uploaded-image {
        max-height: 109px;
        max-width: 100px; }
  .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa {
    padding: 30px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .input_name_wrapper_outside {
      width: 400px;
      flex-grow: 1; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .name {
      margin-top: -3px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .website,
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .sku,
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .vendor {
      margin-top: 25px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .button_container {
      margin-top: 30px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .dynamicInputContainer {
      height: 210px;
      border: 1px solid #BAC4CC;
      box-sizing: border-box;
      border-radius: 3px;
      scrollbar-width: thin; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .item_label {
      margin-top: 15px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .input_name_wrapper.sku, .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .input_name_wrapper.website {
      margin-top: 35px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .add-attributes-button-wrapper {
      margin-bottom: 10px; }
    .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .dynamicInputContainer {
      height: 210px;
      overflow-y: auto;
      padding: 8px 10px 10px 10px; }
      .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .dynamicInputContainer .dynamic_input {
        margin-top: 0;
        margin-bottom: 15px;
        width: 48%; }
      .create-edit-form.add_note .note_inner_wrapper.edit_copy_asset_container.sa .items_container .dynamicInputContainer .attribute_value_wrapper {
        border: none; }
  .create-edit-form.add_note .note_inner_wrapper .upload-image-label {
    border: 1px solid #DDE4EB; }

.main_item_wrapper {
  background: #F9F9F9;
  border-radius: 3px;
  width: 20%;
  height: 284px;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #F9F9F9;
  transition-duration: 0.3s; }
  .main_item_wrapper .item_attributes_category {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid #E5E5E5; }
    .main_item_wrapper .item_attributes_category .fa-times:hover {
      font-size: 10px;
      cursor: pointer;
      color: #00BAE5; }
    .main_item_wrapper .item_attributes_category .title {
      font-family: Lato, serif;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      padding-right: 10px; }
  .main_item_wrapper .imgs_wrapper {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 10px; }
    .main_item_wrapper .imgs_wrapper .img_container {
      background: #FFFFFF;
      border: 1px solid #F1F2F3;
      box-sizing: border-box;
      border-radius: 3px;
      min-width: 78.3px;
      max-width: 78.3px;
      height: 80px;
      align-items: center;
      display: flex;
      position: relative;
      cursor: pointer;
      margin: 0; }
      .main_item_wrapper .imgs_wrapper .img_container img {
        max-height: 100%;
        max-width: 100%;
        opacity: 1;
        margin: auto; }
      .main_item_wrapper .imgs_wrapper .img_container .zoom_icon {
        transition: .3s ease;
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex; }
        .main_item_wrapper .imgs_wrapper .img_container .zoom_icon .fa-search-plus {
          margin: auto;
          color: #FFFFFF;
          font-size: 23px; }
      .main_item_wrapper .imgs_wrapper .img_container.no_img_ext {
        cursor: not-allowed;
        background: #F6F6F6;
        border: 1px solid #B3C5D0; }
      .main_item_wrapper .imgs_wrapper .img_container:hover img {
        opacity: 0.7;
        background: rgba(0, 0, 0, 0.3); }
      .main_item_wrapper .imgs_wrapper .img_container:hover.no_img_ext img {
        background: inherit; }
      .main_item_wrapper .imgs_wrapper .img_container:hover .zoom_icon {
        opacity: 0.3;
        background: rgba(0, 0, 0, 0.3); }
    .main_item_wrapper .imgs_wrapper .item_name_wrapper {
      width: 20px;
      padding-left: 15px;
      flex-grow: 1; }
      .main_item_wrapper .imgs_wrapper .item_name_wrapper .note_level_label {
        width: 80px;
        height: 19px;
        margin-bottom: 3px;
        margin-left: auto;
        padding: 4px 0;
        font-size: 9px;
        line-height: 11px; }
    .main_item_wrapper .imgs_wrapper .item_name {
      font-family: Lato, serif;
      font-size: 10px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #1090C0;
      font-weight: 600; }
    .main_item_wrapper .imgs_wrapper .item_name_vendor {
      font-family: Lato, serif;
      font-size: 9px;
      line-height: 140%;
      color: #999999;
      margin-bottom: 5px;
      margin-top: -2px; }
  .main_item_wrapper .item_info_wrapper {
    height: 117px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-height: thin; }
    .main_item_wrapper .item_info_wrapper::-webkit-scrollbar {
      width: 3px;
      height: 3px; }
  .main_item_wrapper .company_item_name .item_name_container,
  .main_item_wrapper .company_item_name_color_Scheme .item_name_container {
    margin-bottom: 3px; }
    .main_item_wrapper .company_item_name .item_name_container .item_name_title,
    .main_item_wrapper .company_item_name .item_name_container .item_name_property,
    .main_item_wrapper .company_item_name_color_Scheme .item_name_container .item_name_title,
    .main_item_wrapper .company_item_name_color_Scheme .item_name_container .item_name_property {
      font-family: Lato, serif;
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.02em;
      color: #535353;
      display: inline-block;
      margin-right: 2px;
      font-weight: 400; }
    .main_item_wrapper .company_item_name .item_name_container .item_name_title,
    .main_item_wrapper .company_item_name_color_Scheme .item_name_container .item_name_title {
      font-size: 10px;
      line-height: 13px;
      font-weight: 600; }
    .main_item_wrapper .company_item_name .item_name_container .item_name_property,
    .main_item_wrapper .company_item_name_color_Scheme .item_name_container .item_name_property {
      color: #696969;
      font-weight: 300; }
  .main_item_wrapper .check_box_wrapper {
    cursor: pointer; }
  .main_item_wrapper.deactivate {
    pointer-events: none;
    opacity: 0.7;
    color: #CCCCCC; }
    .main_item_wrapper.deactivate.checkbox_events_on {
      pointer-events: auto; }
    .main_item_wrapper.deactivate .item_name,
    .main_item_wrapper.deactivate .item_name_title {
      color: #CCCCCC; }
    .main_item_wrapper.deactivate .item_name_container .item_name_title,
    .main_item_wrapper.deactivate .item_name_container .item_name_property {
      color: #CCCCCC; }
    .main_item_wrapper.deactivate.selected {
      border: 1px solid #E5E5E5; }
      .main_item_wrapper.deactivate.selected .active-filter-icon {
        color: #CCCCCC; }
  .main_item_wrapper.selected {
    border: 1px solid #00BAE5; }
    .main_item_wrapper.selected.not_allowed_item {
      opacity: 0.35; }
      .main_item_wrapper.selected.not_allowed_item .check_box_wrapper:hover {
        cursor: not-allowed; }
      .main_item_wrapper.selected.not_allowed_item:hover {
        opacity: 0.25; }
  .main_item_wrapper:hover {
    border: 1px solid #00BAE5; }
  .main_item_wrapper .main_item_wrapper {
    margin-right: 10px; }

.simple_input,
.search_input {
  width: 100%;
  height: 35px;
  background: #EFEFF0;
  border: 1px solid #EFEFF0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 35px 0 15px;
  transition-duration: 0.3s;
  font-family: "Lato"; }
  .simple_input:focus, .simple_input:hover,
  .search_input:focus,
  .search_input:hover {
    border: 1px solid #00BAE5;
    transition-duration: 0.3s; }
  .simple_input:disabled,
  .search_input:disabled {
    border: 1px solid #EFEFF0;
    background: #EFEFF0;
    color: #7F7F7F; }
  .simple_input::placeholder,
  .search_input::placeholder {
    font-size: 12px;
    color: #C6C6C6; }

.simple_input_container {
  width: 100%; }
  .simple_input_container .simple_input {
    color: #1AB394;
    padding-left: 10px;
    font-family: Lato, serif;
    font-size: 12px;
    line-height: 17px;
    transition-duration: 0.3s; }
    .simple_input_container .simple_input:focus {
      color: #535353; }
    .simple_input_container .simple_input:disabled {
      border: 1px solid #EFEFF0;
      background: #EFEFF0;
      color: #7F7F7F; }
  .simple_input_container.inside_placeholder {
    width: 400px;
    flex-grow: 1;
    position: relative; }
    .simple_input_container.inside_placeholder .simple_input {
      font-size: 13px;
      padding: 5px 35px 0 57px; }
    .simple_input_container.inside_placeholder .inside_placeholder_title {
      position: absolute;
      left: 15px;
      top: 33%;
      font-weight: 500;
      font-size: 13px; }
  .simple_input_container .invalid {
    border: 1px solid #FF0000;
    color: #535353; }

.button_wrapper {
  width: 100%;
  justify-content: flex-end;
  display: flex; }

.form_label {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
  display: inline-block; }

.settings_input {
  width: 100%;
  height: 35px;
  background: #EFEFF0;
  border-radius: 3px;
  border: 1px solid #EFEFF0;
  font-family: Lato, serif;
  font-size: 12px;
  line-height: 14px;
  transition-duration: 0.3s;
  padding-left: 11px; }
  .settings_input:disabled {
    color: #B5B5B5 !important; }
  .settings_input:not(:disabled):hover {
    border: 1px solid #00BAE5; }

.package_info_container {
  display: flex;
  background: #F9F9F9;
  border-radius: 3px;
  padding: 0 10px;
  align-items: center;
  height: 35px; }
  .package_info_container .info_title_wrapper {
    display: flex;
    align-items: baseline; }
    .package_info_container .info_title_wrapper:first-child {
      margin-right: 20px; }
    .package_info_container .info_title_wrapper .property_title {
      font-weight: bold;
      font-size: 12px;
      line-height: 13px;
      margin: 0 3px 0 0;
      cursor: default;
      white-space: nowrap; }
    .package_info_container .info_title_wrapper .property {
      font-size: 12px;
      font-weight: 400;
      cursor: default; }

.licence_container {
  display: flex; }
  .licence_container .basic,
  .licence_container .premium {
    border-radius: 2px;
    padding: 5px 13px 4px;
    display: flex;
    align-items: center;
    font-family: Lato, serif;
    font-weight: 400;
    font-size: 9px;
    line-height: 74%;
    letter-spacing: 0.03em;
    min-height: 18px;
    color: #FFFFFF;
    justify-content: center; }
    .licence_container .basic .days_wrapper,
    .licence_container .premium .days_wrapper {
      display: contents;
      font-size: 10px; }
  .licence_container .basic {
    background-color: #1090C0; }
  .licence_container .premium {
    background-color: #E3B848; }

.min_animation {
  cursor: pointer;
  transition-duration: 0.3s; }
  .min_animation:hover {
    transition-duration: 0.3s;
    color: #05BAE5;
    cursor: pointer; }

.resource_label {
  min-width: 80px;
  height: 19px;
  border-radius: 2px;
  font-family: Lato, serif;
  font-style: normal;
  font-size: 9px;
  line-height: 74%;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 13px 4px;
  font-weight: 600;
  letter-spacing: 0.05em; }
  .resource_label.blue {
    background-color: #0F90C0; }
  .resource_label.pink {
    background-color: #C16CC2; }
  .resource_label.gray {
    background-color: #C4C4C4; }

.with_value .MuiFormLabel-root {
  top: 4px;
  color: #B5B5B5; }

.material-field.custom .MuiInputLabel-root.Mui-focused {
  top: 4px; }

.material-field.custom .with_value .MuiFormLabel-root {
  top: 4px; }

.material-field.custom .MuiFormControl-root.MuiTextField-root {
  width: 100%;
  height: 46px; }
  .material-field.custom .MuiFormControl-root.MuiTextField-root .MuiInput-formControl {
    padding-left: 0;
    margin-top: 0 !important; }

#create_copy_asset_description,
#create_copy_asset_name_sa,
#create_copy_asset_sku_sa,
#create_copy_asset_website_sa,
#create_copy_asset_vendor_sa,
#create_asset_request_name,
#create_asset_request_sku,
#create_asset_request_website,
#create_asset_request_note {
  height: 24px;
  padding: 17px 15px 5px;
  font-size: 14px;
  text-overflow: ellipsis; }
  #create_copy_asset_description:focus,
  #create_copy_asset_name_sa:focus,
  #create_copy_asset_sku_sa:focus,
  #create_copy_asset_website_sa:focus,
  #create_copy_asset_vendor_sa:focus,
  #create_asset_request_name:focus,
  #create_asset_request_sku:focus,
  #create_asset_request_website:focus,
  #create_asset_request_note:focus {
    background-color: #E3F0FF; }

.line {
  width: 100%;
  height: 1px;
  background-color: #E0E0E0;
  display: block; }

@-moz-document url-prefix() {
  .radio_item .first,
  .radio_item .second {
    margin-top: -1px; }
    .radio_item .first label::before,
    .radio_item .second label::before {
      top: 0 !important; }
    .radio_item .first label::after,
    .radio_item .second label::after {
      top: 3px !important; }
  .radio_item .second {
    margin-bottom: -2px; } }

.radio_item [type="radio"]:checked,
.radio_item [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

.radio_item [type="radio"]:checked + label,
.radio_item [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 17px;
  cursor: pointer;
  font-family: Lato, serif;
  font-size: 12px;
  line-height: 16px;
  color: #535353; }

.radio_item [type="radio"]:checked + label:before,
.radio_item [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 10px;
  height: 10px;
  border: 1px solid #1090C0;
  border-radius: 100%;
  background: #fff; }

.radio_item [type="radio"]:checked + label:after,
.radio_item [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 6px;
  height: 6px;
  background: #1090C0;
  position: absolute;
  top: 5px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.radio_item [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.radio_item [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.accessorie_image_wrapper {
  width: 90px;
  height: 83px;
  background: #FFFFFF;
  border: 1px solid #dde4eb;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden; }
  .accessorie_image_wrapper .accessorie_image {
    max-width: 100%;
    max-height: 100%;
    opacity: 1; }
  .accessorie_image_wrapper .zoom_icon {
    border-radius: 3px;
    transition: .3s ease;
    opacity: 0;
    position: absolute;
    height: inherit;
    width: inherit;
    display: flex;
    top: 0;
    left: 0; }
    .accessorie_image_wrapper .zoom_icon .fa-search-plus {
      margin: auto;
      color: #FFFFFF;
      font-size: 23px; }
  .accessorie_image_wrapper:hover {
    cursor: pointer; }
    .accessorie_image_wrapper:hover img {
      opacity: 0.7;
      background: rgba(0, 0, 0, 0.3); }
    .accessorie_image_wrapper:hover .zoom_icon {
      opacity: 1;
      background: rgba(0, 0, 0, 0.3); }

.global_loader {
  position: fixed;
  background-color: white;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000; }

.green_item {
  background: #1AB394;
  border-radius: 2px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #FFFFFF !important;
  padding: 2px 10px;
  display: flex;
  width: fit-content; }

.super-admin-companies-table-container.head {
  padding-right: 5px; }

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ff4444; }

.MuiInputBase-input.MuiInput-input {
  color: #535353; }

/* todo make it work properly, it can help a lot with 'transition-duration: 0.3s'
body #root * {
  i {
    transition-duration: 0s;

    &::before {
      transition-duration: 0.3s;
    }

    &::after {
      transition-duration: 0.3s;
    }
  }

  svg * {
    transition-duration: unset;
  }
}*/
body #root td, body #root .list_item {
  transition-property: all;
  transition-duration: 0.3s; }

.login-form-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.login-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.main-text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 53.5px;
  line-height: 64px;
  text-transform: capitalize;
  margin-right: 10px;
  color: #191919; }
  .main-text.sign-up {
    margin-left: -5px; }

.second-text {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: #191919;
  width: -moz-available; }

.ss-btn {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 10px;
  padding: 0 20px;
  letter-spacing: 0.5px;
  background: #1090C0;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  text-align: center;
  color: #FFFFFF;
  z-index: 100;
  transition-duration: 0.3s;
  /*
    &.os_win {
        padding: 14px 20px 11px;
    }
*/ }
  .ss-btn.white {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #0F90C0; }
    .ss-btn.white:hover {
      border: 1px solid #33B5E5; }
    .ss-btn.white:disabled {
      color: #CCCCCC; }
  .ss-btn.login-form-button {
    white-space: nowrap;
    margin-bottom: 16px;
    margin-left: 11px;
    margin-right: 0; }
    @media only screen and (max-width: 1024px) {
      .ss-btn.login-form-button.rejected_button {
        margin-top: 23px; } }
    @media only screen and (max-width: 766px) {
      .ss-btn.login-form-button.rejected_button {
        margin-top: 0; } }
  .ss-btn.d_btn {
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
    border: none;
    transition-duration: 0.3s; }
    .ss-btn.d_btn.cr_c_b {
      padding: 0 20px;
      padding-bottom: 9.5px;
      font-weight: 400;
      white-space: pre; }
      .ss-btn.d_btn.cr_c_b.second_button {
        margin-right: 15px; }
      .ss-btn.d_btn.cr_c_b.not_active {
        background: #EFEFF0;
        color: #535353; }
      .ss-btn.d_btn.cr_c_b.red {
        background: #FF0000;
        transition-duration: 0.3s; }
        .ss-btn.d_btn.cr_c_b.red.fs_13 {
          font-size: 13px; }
        .ss-btn.d_btn.cr_c_b.red:hover {
          transition-duration: 0.3s;
          background-color: #FF6565; }
    .ss-btn.d_btn:hover {
      color: #FFFFFF;
      background-color: #00BAE5;
      transition-duration: 0.3s; }
    .ss-btn.d_btn.refresh {
      background: #DDE7ED;
      padding: 10px;
      color: #1090C0;
      margin-right: 15px; }
      .ss-btn.d_btn.refresh:hover {
        color: #FFFFFF;
        background-color: #1090C0;
        transition-duration: 0.3s; }
      .ss-btn.d_btn.refresh.edit_b, .ss-btn.d_btn.refresh.delete_b, .ss-btn.d_btn.refresh.eye, .ss-btn.d_btn.refresh.fa_check, .ss-btn.d_btn.refresh.fa_times, .ss-btn.d_btn.refresh.fa_ban, .ss-btn.d_btn.refresh.unlock_alt {
        margin-right: 0;
        width: 25px;
        height: 25px;
        padding: 5px;
        z-index: 0; }
        .ss-btn.d_btn.refresh.edit_b svg, .ss-btn.d_btn.refresh.delete_b svg, .ss-btn.d_btn.refresh.eye svg, .ss-btn.d_btn.refresh.fa_check svg, .ss-btn.d_btn.refresh.fa_times svg, .ss-btn.d_btn.refresh.fa_ban svg, .ss-btn.d_btn.refresh.unlock_alt svg {
          fill: #0F90C0; }
        .ss-btn.d_btn.refresh.edit_b:hover, .ss-btn.d_btn.refresh.delete_b:hover, .ss-btn.d_btn.refresh.eye:hover, .ss-btn.d_btn.refresh.fa_check:hover, .ss-btn.d_btn.refresh.fa_times:hover, .ss-btn.d_btn.refresh.fa_ban:hover, .ss-btn.d_btn.refresh.unlock_alt:hover {
          color: #FFFFFF; }
          .ss-btn.d_btn.refresh.edit_b:hover svg, .ss-btn.d_btn.refresh.delete_b:hover svg, .ss-btn.d_btn.refresh.eye:hover svg, .ss-btn.d_btn.refresh.fa_check:hover svg, .ss-btn.d_btn.refresh.fa_times:hover svg, .ss-btn.d_btn.refresh.fa_ban:hover svg, .ss-btn.d_btn.refresh.unlock_alt:hover svg {
            fill: #FFFFFF; }
        .ss-btn.d_btn.refresh.edit_b:disabled svg, .ss-btn.d_btn.refresh.delete_b:disabled svg, .ss-btn.d_btn.refresh.eye:disabled svg, .ss-btn.d_btn.refresh.fa_check:disabled svg, .ss-btn.d_btn.refresh.fa_times:disabled svg, .ss-btn.d_btn.refresh.fa_ban:disabled svg, .ss-btn.d_btn.refresh.unlock_alt:disabled svg {
          fill: #CFCFCF; }
      .ss-btn.d_btn.refresh.delete_b:hover {
        background: #E73043; }
      .ss-btn.d_btn.refresh.edit_b {
        margin-right: 5px;
        padding: 6px; }
      .ss-btn.d_btn.refresh.fa_check {
        padding: 7px 6px 5px;
        background: #D2FFE4;
        color: #00C851; }
        .ss-btn.d_btn.refresh.fa_check:hover {
          background: #00C851; }
      .ss-btn.d_btn.refresh.eye {
        padding: 7px 4px 6px; }
      .ss-btn.d_btn.refresh.fa_times {
        padding: 5px 6px 6px;
        background: #FFF4DE;
        color: #FFBB33; }
        .ss-btn.d_btn.refresh.fa_times:hover {
          background: #FFBB33; }
      .ss-btn.d_btn.refresh.fa_ban {
        padding: 5px 5px 6px 6px;
        background: #FFE8E8;
        color: #FF4444; }
        .ss-btn.d_btn.refresh.fa_ban .fa-ban {
          transform: rotate(90deg); }
        .ss-btn.d_btn.refresh.fa_ban:hover {
          background: #FF4444; }
    .ss-btn.d_btn:disabled, .ss-btn.d_btn.forbidden {
      color: #CCCCCC;
      background: #E5E5E5; }
      .ss-btn.d_btn:disabled i, .ss-btn.d_btn.forbidden i {
        color: #CFCFCF; }
      .ss-btn.d_btn:disabled svg, .ss-btn.d_btn.forbidden svg {
        fill: #CFCFCF; }
      .ss-btn.d_btn:disabled:hover, .ss-btn.d_btn.forbidden:hover {
        color: #CCCCCC;
        background: #E5E5E5 !important;
        cursor: default; }
    .ss-btn.d_btn:disabled {
      pointer-events: none; }
    .ss-btn.d_btn.active {
      color: #FFFFFF;
      background-color: #1090C0;
      transition-duration: 0.3s; }
      .ss-btn.d_btn.active:hover {
        background-color: #00BAE5;
        transition-duration: 0.3s; }
    .ss-btn.d_btn.disabled {
      pointer-events: none;
      color: #CCCCCC;
      background: #E5E5E5; }
      .ss-btn.d_btn.disabled i {
        color: #CFCFCF; }
      .ss-btn.d_btn.disabled:hover {
        color: #CCCCCC;
        background: #E5E5E5; }
    .ss-btn.d_btn.light_blue_hover:hover {
      background-color: #00BAE5;
      transition-duration: 0.3s; }

.status_company_items {
  display: flex; }
  .status_company_items .cr_c_b:nth-child(1) {
    border-radius: 3px 0 0 3px; }
  .status_company_items .cr_c_b:nth-child(2) {
    border-radius: 0; }
  .status_company_items .cr_c_b:nth-child(3) {
    border-radius: 0; }
  .status_company_items .cr_c_b:nth-child(4) {
    border-radius: 0 3px 3px 0; }
  .status_company_items .cr_c_b:not(:last-child) {
    border-right: 1px solid #E0E0E0; }

.ss-btn-clear {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 10px;
  border: 1px solid #0F90C0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 20px;
  letter-spacing: 0.5px;
  background: inherit;
  text-align: center;
  color: #0F90C0;
  margin-right: 20px;
  height: 35px; }
  .ss-btn-clear:hover {
    border: 1px solid #00BAE5;
    color: #00BAE5; }

.ss-btn-blue {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 10px;
  letter-spacing: 0.5px;
  border: 1px solid #0F90C0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 20px;
  background: #0F90C0;
  text-align: center;
  color: #FFFFFF;
  margin-right: 20px; }
  .ss-btn-blue:hover {
    border: 1px solid #00bae5;
    background-color: #00bae5; }

.status_company_items .cr_c_b {
  font-size: 12px !important;
  letter-spacing: .03em;
  margin: 0;
  padding: 15px 15px 11px !important; }

@media screen and (max-width: 1050px) {
  .status_company_items .cr_c_b {
    padding: 15px 15px 11px !important; }
  .search_box_wrapper {
    width: 200px !important; } }

.login-button {
  border: none; }

.main-footer {
  position: absolute;
  bottom: 0;
  right: 50px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border: none;
  background-color: inherit;
  left: 0; }

.form-button {
  background-color: #0F90C0;
  border: none;
  margin-left: 8px;
  position: relative;
  color: #ffffff; }
  .form-button:disabled {
    background-color: #CCCCCC;
    cursor: not-allowed; }
  .form-button:hover:enabled {
    background-color: #00bae5; }

.login-form-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 154px; }

.registration-form-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 244px; }

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.input-fields-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.forgot-password-link {
  font-family: Lato, serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.25px;
  color: #0F90C0;
  margin-left: 20px;
  margin-top: 10px;
  cursor: pointer;
  margin-right: 9px; }
  .forgot-password-link:hover {
    color: #00bae5; }

.no-account {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #0F90C0;
  margin-right: 20px; }

.login-button-checkbox-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.login-checkbox-wrapper {
  font-family: Lato, serif;
  font-size: 10px;
  margin-top: 1px;
  margin-bottom: 37px;
  letter-spacing: 0.4px;
  line-height: 12px;
  color: #B5B5B5;
  display: flex;
  position: relative; }

.checkbox {
  height: 14px;
  width: 14px;
  margin-right: 6px;
  ouline: 1px solid #CCCCCC; }

.checkbox:checked {
  background-color: #4391BC;
  color: #4391BC;
  border: 5px solid green; }

.terms-of-use {
  font-family: Lato, serif;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.25px;
  color: #0F90C0;
  margin: -2px 4px;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer; }
  .terms-of-use:hover {
    color: #00bae5; }

.create-company {
  display: flex;
  align-items: center; }

.plus-icon {
  margin-right: 12px; }

.registration-from {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%; }
  .registration-from .MuiInputBase-input {
    color: #535353; }
  .registration-from.modal_form {
    padding: 30px 30px 30px; }

.upload-image-name-domain-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 26px; }

.upload-image-label {
  width: 114px;
  height: 114px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #0F90C0;
  cursor: pointer;
  position: relative;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 3px; }
  .upload-image-label:hover {
    border-radius: 2px; }

.name-domain-wrapper {
  width: 59%;
  margin-right: 36px; }

.create-company-form-block {
  margin-right: 70px; }

.upload-logo-icon {
  margin-bottom: 15px;
  top: 16.67%;
  bottom: 16.67%; }

.uploaded-image {
  width: min-content;
  height: min-content;
  max-height: 112px;
  max-width: 112px;
  border-radius: 4px;
  margin: auto;
  object-fit: contain; }

.uploaded-image-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex; }

.uploaded-image-wrapper:hover .replace-logo-frame {
  display: block;
  position: absolute;
  width: 101%;
  height: 28px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.38);
  font-family: Lato, serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-top: 10px;
  border-radius: 0 0 2px 2px;
  margin: 0 0 -1px -1px; }

.replace-logo-frame {
  display: none; }

.wrapper-fields {
  max-width: 400px; }
  .wrapper-fields input {
    width: 400px;
    height: 40px;
    margin-top: 2px;
    padding: 6px 12px; }
    .wrapper-fields input:disabled {
      background-color: #E0E0E0; }
    .wrapper-fields input[name="expirationDate"] {
      color: gray; }

.company-logo-label {
  display: block;
  margin-bottom: 3px; }

.main-blue-color {
  color: #00BAE5; }

.explanation-text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #535353;
  margin-bottom: 44px; }

.small-hint-text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #B5B5B5; }

.alert-icon {
  position: absolute;
  top: 105.3%;
  left: 0; }

.form-title {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 54px;
  /* identical to box height */
  color: #191919;
  margin-bottom: 60px; }

.form_field {
  width: 360px; }

.pad-0-49 {
  padding: 0 49px; }

.form-verification {
  display: flex;
  margin-bottom: 17px; }

.f-s-14 {
  font-size: 14px; }

.w-210 {
  width: 210px; }

.over {
  padding: 0 49px; }

.m-r-55 {
  margin-right: 55px; }

input[type=password] {
  font-family: Lato, serif; }

.nav_tab_wrapper {
  display: flex; }
  .nav_tab_wrapper .nav_tab {
    border-radius: 5px 5px 0 0;
    font-size: 13px;
    line-height: 78%;
    color: #9D9D9D;
    padding: 0 30px;
    height: 36px;
    display: flex;
    align-items: center;
    background-color: #E5E5E5;
    font-weight: 600; }
    .nav_tab_wrapper .nav_tab span {
      margin-left: 7px; }
    .nav_tab_wrapper .nav_tab:hover:not(.active) {
      cursor: pointer; }
    .nav_tab_wrapper .nav_tab.active {
      color: #535353;
      background-color: #FFFFFF; }

.company-settings-company-name {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 74%;
  /* or 10px */
  color: #535353; }

.company-settings-company-details-header {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 74%;
  /* or 10px */
  color: #535353;
  padding: 0 0 17px 24px;
  border-bottom: 1px solid #E5E5E5; }

.company-settings-form-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start; }

.label-upload-image {
  padding: 11px 23px;
  background: #FFFFFF;
  border: 1px solid #A9B9C7;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 80%;
  width: 120px;
  text-align: center;
  cursor: pointer;
  color: #0F90C0; }

.uploaded-image-container {
  width: 120px;
  height: 120px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  position: relative; }

.delete-image-icon-container {
  background: #FF6565;
  border-radius: 2px;
  position: absolute;
  top: 7px;
  right: 7px;
  width: 14px;
  height: 14px;
  cursor: pointer; }
  .delete-image-icon-container i {
    color: white;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.upload-image-company-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .upload-image-company-logo img {
    width: 80px;
    height: 80px; }

.company-details-form {
  margin-left: 30px;
  padding-left: 30px;
  border-left: 1px solid #E0E0E0; }
  .company-details-form .MuiInput-underline:after {
    border: none !important; }
  .company-details-form .MuiInput-underline:before {
    border-radius: 3px;
    top: -4px;
    bottom: -4px;
    transition: unset !important;
    border: none !important; }
  .company-details-form .MuiInput-underline:hover:before {
    border-radius: 3px;
    top: -4px;
    bottom: -4px;
    transition: unset !important;
    border: 1px solid #00BAE5 !important; }
  .company-details-form .MuiFormHelperText-root.Mui-error {
    color: #FF0000;
    height: 13px;
    font-size: 10px;
    letter-spacing: 0.5px;
    margin-left: 11px;
    padding-top: 6px; }

.ss-btn,
.ss-btn-blue,
.ss-btn-clear,
.info-modal-first-button,
.cr_c_b {
  height: 35px;
  line-height: 38px;
  /*
    &.os_win {
        padding: 14px 20px 11px !important;
        line-height: 65% !important;
    }
 */ }

.refresh {
  line-height: 65%; }

.no_result_wrapper {
  display: flex;
  flex-direction: column; }
  .no_result_wrapper .img_no_result_container {
    width: 400px; }
  .no_result_wrapper .img_no_result_container,
  .no_result_wrapper .title,
  .no_result_wrapper .description {
    margin: auto; }
    .no_result_wrapper .img_no_result_container img,
    .no_result_wrapper .title img,
    .no_result_wrapper .description img {
      width: 100%; }
    @media only screen and (max-width: 1600px) {
      .no_result_wrapper .img_no_result_container,
      .no_result_wrapper .title,
      .no_result_wrapper .description {
        width: 307px; } }
  .no_result_wrapper .title,
  .no_result_wrapper .description {
    font-family: Lato, serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #535353;
    text-align: center; }
  .no_result_wrapper .no_report {
    font-family: Lato, serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #535353;
    text-align: center;
    margin: 30px auto; }
  .no_result_wrapper .title {
    margin-top: 21px; }
    @media only screen and (max-width: 1600px) {
      .no_result_wrapper .title {
        margin-top: 10px; } }
  .no_result_wrapper .description {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px; }

.overall_tab_wrapper {
  height: 100%; }

.not_allowed {
  color: #B5B5B5; }

.noValue {
  background-color: #FBFCFC; }

.haveValue {
  background-color: white; }

.create-edit-form.add_note .note_inner_wrapper .items_container .add-attributes-button-wrapper .custom-dropdown-selection-overall.haveValue {
  background-color: white; }

.create-edit-form.add_note .note_inner_wrapper .items_container .custom_dropdown.haveValue {
  background-color: white; }

.MuiPopover-root .MuiMenu-paper {
  max-height: 160px; }

.MuiFormLabel-root.Mui-error {
  color: #a8a8a8 !important; }

.ss-btn.d_btn.cr_c_b.pink.fs_13.deleteBtn {
  margin-right: auto;
  background-color: #ffefef;
  color: red;
  padding: 0;
  width: 35px;
  height: 35px;
  font-size: 15px;
  text-align: center;
  transition: all 0.3s; }
  .ss-btn.d_btn.cr_c_b.pink.fs_13.deleteBtn:hover {
    color: #fffefe;
    background-color: red; }

@font-face {
  font-family: "Lato";
  font-weight: 900;
  src: url("Assets/fonts/Lato/Lato-Black-900.woff2") format("woff2"); }

@font-face {
  font-family: "Lato";
  font-weight: 700;
  src: url("Assets/fonts/Lato/Lato-Bold-700.woff2") format("woff2"); }

@font-face {
  font-family: "Lato";
  font-weight: 100;
  src: url("Assets/fonts/Lato/Lato-Hairline-100.woff2") format("woff2"); }

@font-face {
  font-family: "Lato";
  font-weight: 800;
  src: url("Assets/fonts/Lato/Lato-Heavy-800.woff2") format("woff2"); }

@font-face {
  font-family: "Lato";
  font-weight: 300;
  src: url("Assets/fonts/Lato/Lato-Light-300.woff2") format("woff2"); }

@font-face {
  font-family: "Lato";
  font-weight: 500;
  src: url("Assets/fonts/Lato/Lato-Medium-500.woff2") format("woff2"); }

@font-face {
  font-family: "Lato";
  font-weight: 400;
  src: url("Assets/fonts/Lato/Lato-Regular-400.woff2") format("woff2"); }

@font-face {
  font-family: "Lato";
  font-weight: 600;
  src: url("Assets/fonts/Lato/Lato-Semibold-600.woff2") format("woff2"); }

@font-face {
  font-family: "Lato";
  font-weight: 200;
  src: url("Assets/fonts/Lato/Lato-Thin-200.woff2") format("woff2"); }

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.overall-main-container {
  position: relative;
  height: 100%;
  display: flex; }

.flex-row-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }

.text-nowrap {
  white-space: nowrap; }

.text-bold {
  font-weight: 700; }

.w-58-per {
  width: 58%; }

.w-100-per {
  width: 100%; }

.h-100-per {
  height: 100%; }

.w-m-c {
  width: max-content; }

.m-g-b-0 {
  margin-bottom: 0 !important; }

.m-g-b-5 {
  margin-bottom: 5px; }

.m-g-b-8 {
  margin-bottom: 8px; }

.m-g-b-10 {
  margin-bottom: 10px; }

.m-g-b-14 {
  margin-bottom: 14px; }

.m-g-b-20 {
  margin-bottom: 20px; }

.m-g-b-24 {
  margin-bottom: 24px; }

.m-g-b-28 {
  margin-bottom: 28px; }

.m-g-b-30 {
  margin-bottom: 30px; }

.m-g-b-31 {
  margin-bottom: 31px; }

.m-g-b-32 {
  margin-bottom: 32px; }

.m-g-b-50 {
  margin-bottom: 50px; }

.m-g-b-51 {
  margin-bottom: 51px; }

.m-g-b-55 {
  margin-bottom: 55px; }

.m-g-b-56 {
  margin-bottom: 56px; }

.m-g-b-58 {
  margin-bottom: 58px; }

.m-g-l-3 {
  margin-left: 3px !important; }

.m-g-l-15 {
  margin-left: 15px; }

.m-g-l-44 {
  margin-left: 44px !important; }

.m-g-b-6 {
  margin-bottom: 6px; }

.m-g-b-15 {
  margin-bottom: 15px; }

.m-g-b-16 {
  margin-bottom: 16px; }

.m-g-b-17 {
  margin-bottom: 17px; }

.m-g-b-18 {
  margin-bottom: 18px; }

.m-g-b-19 {
  margin-bottom: 19px; }

.m-g-b-25 {
  margin-bottom: 25px !important; }

.m-g-b-27 {
  margin-bottom: 27px; }

.m-g-l-0 {
  margin-left: 0 !important; }

.m-g-b-34 {
  margin-bottom: 34px; }

.m-g-b-37 {
  margin-bottom: 37px; }

.m-g-b-40 {
  margin-bottom: 40px; }

.m-g-b-43 {
  margin-bottom: 43px; }

.m-g-b-45 {
  margin-bottom: 45px; }

.m-g-b-47 {
  margin-bottom: 47px; }

.m-g-b-54 {
  margin-bottom: 54px; }

.m-g-b-55 {
  margin-bottom: 55px; }

.m-g-t-8 {
  margin-top: 8px; }

.m-g-t-28 {
  margin-top: 28px; }

.m-g-t-30 {
  margin-top: 30px; }

.m-g-t-33 {
  margin-top: 33px; }

.m-g-t-54 {
  margin-top: 54px; }

.m-g-t-87 {
  margin-top: 87px; }

.m-g-r-a {
  margin-right: auto; }

.m-g-r-0 {
  margin-right: 0 !important; }

.m-g-t-0 {
  margin-top: 0 !important; }

.m-g-t-16 {
  margin-top: 16px !important; }

.m-g-t-22 {
  margin-top: 22px; }

.m-g-t-25 {
  margin-top: 25px !important; }

.m-g-t-20 {
  margin-top: 20px; }

.m-g-t-48 {
  margin-top: 48px; }

.m-g-t-106 {
  margin-top: 106px; }

.m-g-t-109 {
  margin-top: 109px; }

.m-g-r-0 {
  margin-right: 0 !important; }

.m-g-r-5 {
  margin-right: 5px; }

.m-g-r-15 {
  margin-right: 15px; }

.m-g-r-30 {
  margin-right: 30px; }

.m-g-r-40 {
  margin-right: 40px; }

.m-g-t-40 {
  margin-top: 40px; }

.m-g-t-35 {
  margin-top: 35px; }

.w-166 {
  width: max-content; }

.w-196 {
  width: 196px; }

.w-260 {
  width: 260px; }

.w-360 {
  width: 360px; }

.p-0 {
  padding: 0 !important; }

.p-24 {
  padding: 24px; }

.p-25 {
  padding: 25px; }

.min-w-118 {
  min-width: 118px; }

.position-absolute {
  position: absolute;
  left: 0; }

.to-lowercase {
  text-transform: lowercase; }

.background-yellow {
  background: #FF9800; }

.background-red {
  background: #FF0000; }

.background-blue {
  background: #0F90C0; }

.cursor_pointer:hover {
  cursor: pointer; }

.cursor_default:hover {
  cursor: default; }

.justify {
  justify-content: space-between; }

.datepicker-icon {
  background: #DDE7ED;
  border-radius: 3px;
  padding: 6px 6px 7px; }
  .datepicker-icon div {
    width: 11px;
    height: 12px; }

.form-label {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  color: #535353; }

.form-input {
  background: #EFEFF0;
  border-radius: 3px;
  padding: 10px 13px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 65%;
  color: #535353;
  border: 1px solid #E4E4E5; }
  .form-input:hover {
    border: 1px solid #00BAE5; }
  .form-input[name=expirationDate] {
    line-height: 100%; }

.error {
  color: #FF0000;
  height: 13px;
  font-size: 10px;
  letter-spacing: 0.5px;
  margin-left: 11px;
  padding-top: 6px; }

.tr_dur_03 {
  transition-duration: 0.3s;
  transition: all .3s; }

.cursor_pointer {
  cursor: pointer; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    visibility: visible;
    opacity: 1; } }

.d_contents .text-bold {
  display: contents; }

.t_d_initial {
  transition-duration: initial !important; }

.p_9_0_5 {
  padding: 9px 0 5px !important; }

.t_o_el {
  flex-grow: 1;
  width: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.p_t_1_p_b_2 {
  padding-top: 1px;
  padding-bottom: 2px; }

.f_s_90 {
  font-size: 90%; }

.m_l_50 {
  margin-left: 50px; }

.p_0_16 {
  padding: 0 16px; }

.draggable:hover {
  cursor: grab; }

.text_overflow_ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; }
  .text_overflow_ellipsis.initial_width {
    width: initial; }

.cursor_not_allowed:hover {
  cursor: not-allowed; }

.order_1 {
  order: 1; }

.grayA8 {
  color: #A8A8A8; }

.input_fix > div > div {
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  transition-duration: 0.3s; }
  .input_fix > div > div:hover, .input_fix > div > div:focus {
    border-color: #00bae5; }
  .input_fix > div > div > div {
    margin-top: 0 !important;
    z-index: 1; }
    .input_fix > div > div > div::before, .input_fix > div > div > div::after {
      border-bottom: 0 !important; }
    .input_fix > div > div > div:hover {
      border-bottom: 0 !important; }
      .input_fix > div > div > div:hover::before, .input_fix > div > div > div:hover::after {
        border-bottom: 0 !important; }
  .input_fix > div > div label {
    margin-left: 0;
    top: -11px;
    z-index: 0; }
    .input_fix > div > div label.Mui-focused, .input_fix > div > div label.MuiFormLabel-filled {
      top: -8px;
      background-color: white;
      padding: 0 4px; }

.MuiMenu-list {
  background-color: #f1f2f3; }
  .MuiMenu-list .MuiMenuItem-root.MuiListItem-button {
    font-size: 12px;
    font-weight: 400;
    margin-left: 3px; }
    .MuiMenu-list .MuiMenuItem-root.MuiListItem-button:hover {
      background-color: #dde4eb; }

.container {
  width: 100%;
  height: 100%; }

input:disabled {
  cursor: default;
  background-color: white; }

input:required {
  box-shadow: none; }

.form-group {
  position: relative;
  margin-bottom: 23px; }
  .form-group .MuiOutlinedInput-input.MuiSelect-select {
    padding-top: 13px;
    padding-bottom: 13px;
    min-height: 14px;
    height: 14px;
    margin-left: -15px; }
    .form-group .MuiOutlinedInput-input.MuiSelect-select span {
      font-size: 13px;
      line-height: 14px; }
  .form-group fieldset span {
    font-size: 13px;
    line-height: 14px; }
  .form-group label {
    font-size: 13px;
    line-height: 14px;
    margin-left: -13px; }
  .form-group.form-group-select label {
    margin-top: -6px; }
    .form-group.form-group-select label.MuiInputLabel-shrink {
      margin-top: 0px; }

.btn:not(:disabled):not(.disabled) {
  cursor: pointer; }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.btn-activate {
  margin: 0 -3px;
  z-index: 200; }

.form-control {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.Modal {
  width: 476px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  outline: none;
  border-radius: 4px; }
  @media only screen and (max-width: 766px) {
    .Modal {
      width: 335px; } }
  .Modal.add_note {
    width: 400px; }
  .Modal.edit_copy_asset {
    width: 800px; }
  .Modal.w_666 {
    width: 666px; }
  .Modal.w_763 {
    width: 763px; }
  .Modal.w_600 {
    width: 600px; }

.ModalWidth475 {
  width: 475px; }

.ModalWidth400 {
  width: 400px; }

.WarningModal {
  width: 400px; }

.w_480 {
  width: 480px; }

.w_763 {
  width: 763px; }

.w_220_h284 {
  width: 220px;
  height: 284px; }

.ModalCreateFilterReport {
  width: 650px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  outline: none; }

.Overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); }

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none; }

.no-data-wrapper {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: gray; }

.delete-image-icon-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  color: #FFFFFF;
  cursor: pointer;
  z-index: 1000;
  padding: 0 2px;
  background: #FF6565;
  border-radius: 2px;
  width: 15px;
  height: 15px; }
  .delete-image-icon-wrapper i {
    margin: auto;
    display: inline-block;
    font-size: 9px; }

.category-attributes-list {
  white-space: pre;
  list-style-type: none;
  margin: 0;
  top: 100%;
  min-width: 284px;
  max-height: 180px;
  overflow: auto;
  font-weight: bold;
  position: absolute;
  z-index: 1;
  background-color: white;
  border: 1px solid #e7eaec;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0; }

.category-attributes-list div {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 13px;
  cursor: pointer; }

.category-attributes-list div:hover {
  background-color: #1090c0;
  color: white; }

.color-scheme-wrapper {
  margin-top: -2px;
  position: relative;
  border: 1px solid #dee2e6;
  background-color: white;
  height: calc(100% - 38px);
  border-top: none; }

.attributes-dropdown-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  box-sizing: border-box;
  position: relative; }

.placeholders-table-container {
  height: calc(100% - 140px);
  overflow-y: auto;
  padding: 20px; }

.reseller-package-wrapper {
  height: 100%; }

.textarea {
  width: 100%;
  margin-top: 2px;
  height: 200px;
  resize: none;
  box-sizing: border-box; }

.message-window {
  border-radius: 5px;
  background-color: white;
  border: 1px solid lightgrey;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px; }

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
  width: 100%;
  height: 100%;
  z-index: 9999; }

.deactivated-message {
  padding: 15px;
  font-size: 18px; }

.field {
  display: flex;
  flex-flow: column-reverse;
  position: relative; }

label, input {
  transition: all 0.3s;
  touch-action: manipulation; }

.standart-input {
  background: rgba(33, 33, 33, 0.08);
  border-radius: 4px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  padding: 23px 16px 9px 16px; }

.standart-input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  position: absolute;
  top: 0px;
  left: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 23px) scale(1.5); }

.standart-input:focus {
  padding-top: 23px;
  padding-bottom: 9px; }

.standart-input::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit; }

.standart-input:not(:placeholder-shown) + label,
.standart-input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  position: absolute;
  left: 16px;
  top: 9px; }

.header-wrapper {
  padding: 50px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.header-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center; }

.header-logo {
  cursor: pointer; }

.activate-company-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative; }
  .activate-company-modal-wrapper .second-text {
    text-align: center;
    margin: 20px 0 30px 0; }

.contact-us-wrapper {
  width: 400px;
  margin-top: 75px;
  padding-left: 46px; }
  .contact-us-wrapper .main-text {
    font-family: "Lato", serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    color: #191919;
    margin-bottom: 40px; }
  .contact-us-wrapper .textInfo {
    height: 14px;
    width: 100%;
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center; }
    .contact-us-wrapper .textInfo .error-contact {
      color: #FF0000; }
    .contact-us-wrapper .textInfo .success {
      color: #1AB394; }
  .contact-us-wrapper .flex-row-end {
    justify-content: flex-end;
    margin-top: 19px; }

.activate-company-main-text {
  font-size: 30px;
  line-height: 40px;
  font-family: Lato, serif;
  font-weight: 400;
  color: #0C1441;
  text-align: center;
  margin: 20px 0 30px 0; }

.logout-button {
  text-decoration: none;
  color: #878a8c;
  font-size: 15px;
  font-weight: 700;
  border: none;
  background-color: inherit;
  cursor: pointer;
  padding-right: 0; }

@media only screen and (max-width: 1024px) {
  .wrapper-main-container .logout-button {
    padding: 0 0 0 6px; } }

.btn-green {
  color: #fff;
  background-color: #1ab394;
  border-color: #1ab394; }

.ContactUsModal {
  width: 460px;
  border-radius: 8px; }

.ContactUsSendModal {
  width: 480px;
  background: #4391BC;
  border-radius: 8px; }

.close-icon-wrapper {
  position: absolute;
  top: 23px;
  right: 23px;
  cursor: pointer; }

.main-white-text {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #FFFFFF;
  margin-bottom: 8px; }

.second-text-white {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: 0.5px;
  color: #FFFFFF; }

.material-field {
  display: flex;
  flex-flow: column-reverse;
  position: relative;
  margin-bottom: 18px; }

.error-border {
  border: 1px solid red; }

.sidebar-dashboard {
  z-index: 3; }

.main-footer .inner-container {
  padding: 0; }

@media only screen and (min-width: 767px) {
  .main-footer {
    right: 0;
    padding-right: 40px; }
    .main-footer .inner-container {
      padding: 30px 0 20px;
      font-size: 12px;
      line-height: 14px; } }

.main-footer.dashboard {
  padding-right: 25px; }

.wrapper-main-container {
  display: flex;
  height: 100%; }
  .wrapper-main-container .main-container {
    scrollbar-width: thin;
    width: 1200px;
    height: 700px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    box-shadow: 0 0 30px 5px rgba(6, 51, 83, 0.05); }
    .wrapper-main-container .main-container .no_account_mobile {
      display: none; }
    .wrapper-main-container .main-container .fas.fa-arrow-left {
      display: none; }
    .wrapper-main-container .main-container .wrapper-content-main {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      position: relative; }
      .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .get-started-tablet .flex-row {
        margin-top: -6px;
        letter-spacing: 1px; }
      .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .get-started-tablet .m-g-t-8 {
        margin-top: 6px; }
      .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .MuiInputBase-root {
        padding: 0 0 1px 15px; }
        .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .MuiInputBase-root .MuiSelect-root {
          color: #09AE47; }
      .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .MuiFormLabel-root {
        z-index: 100; }
      .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .header-buttons-wrapper .button-mobile {
        display: none; }
      .wrapper-main-container .main-container .wrapper-content-main .mobile-img-wrapper {
        display: none; }
  @media only screen and (max-width: 1024px) {
    .wrapper-main-container button,
    .wrapper-main-container .ss-btn {
      display: flex;
      height: 35px;
      padding: 0 20px;
      justify-content: center;
      align-items: center; }
    .wrapper-main-container .get-started-tablet {
      margin-top: 176px; }
      .wrapper-main-container .get-started-tablet .flex-row.m-g-b-18 {
        margin-top: 0;
        margin-bottom: 23px; }
        .wrapper-main-container .get-started-tablet .flex-row.m-g-b-18 .main-text {
          margin-top: 4px;
          font-size: 56.5px;
          line-height: 61px;
          letter-spacing: 0; }
      .wrapper-main-container .get-started-tablet .m-g-t-8 {
        margin-top: 11px; }
      .wrapper-main-container .get-started-tablet .material-field {
        width: 100%; }
    .wrapper-main-container .tablet-login-container,
    .wrapper-main-container .forgot-password-tablet,
    .wrapper-main-container .reset-password-tablet {
      margin-top: 172px; }
      .wrapper-main-container .tablet-login-container .forgot-password-title,
      .wrapper-main-container .forgot-password-tablet .forgot-password-title,
      .wrapper-main-container .reset-password-tablet .forgot-password-title {
        margin-top: 0; }
    .wrapper-main-container .forgot-password-title {
      margin-bottom: 16px !important; }
    .wrapper-main-container .forgot-password-explanation-text {
      margin-bottom: 49px !important; }
    .wrapper-main-container .create-company-tablet {
      margin-top: 0; }
    .wrapper-main-container .create-company-tablet-button {
      margin-top: 30px; }
    .wrapper-main-container .tablet-waiting-review {
      margin-top: 0; }
    .wrapper-main-container .link-sent-tablet {
      margin-bottom: 15px; }
    .wrapper-main-container .main-container {
      background-image: none !important;
      background-color: #ffffff;
      height: 1200px;
      width: 850px;
      padding: 50px 50px 44px; }
      .wrapper-main-container .main-container .wrapper-content-main {
        z-index: 5; }
        .wrapper-main-container .main-container .wrapper-content-main .container h1 {
          margin-top: 0; }
        .wrapper-main-container .main-container .wrapper-content-main .container .inner-container {
          padding: 0; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside {
            margin-top: 172px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .tablet-signup-container {
              margin-top: -96px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .main-text {
              line-height: 56px;
              font-size: 57px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .forgot-password-outside {
              margin-top: -23px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .forgot-password-outside .form-title {
                margin-bottom: 12px;
                margin-top: 0; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .create-company-form-block {
              margin-top: -100px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .m-g-t-87.forgot-password-tablet .form-title {
            margin-bottom: 14px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .m-g-t-87.forgot-password-tablet .m-g-b-34 {
            margin-bottom: 39px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .form-title {
            margin-bottom: 48px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .form-title.smaller-tablet-fs {
              font-size: 43px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .form-title.less-verification-title-tablet {
              margin-bottom: 17px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .form-title.common-main-text-tablet {
              margin-bottom: 18px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-form-button-wrapper.m-t-38-fp {
            margin-top: 38px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-form-button-wrapper.m-g-t-54 .forgot-password-link {
            align-items: center;
            display: flex;
            margin: 0; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-form-button-wrapper.m-g-t-54 .ss-btn {
            margin-bottom: 0;
            font-size: 11px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-form-button.button-w-a {
            width: min-content; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .material-field {
            margin-bottom: 17px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .explanation-text {
            margin-bottom: 12px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .explanation-text.m-g-b-6.form_field {
              margin-bottom: 0; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .explanation-text.explanation-text-tablet {
              margin-bottom: 0; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .forgot-password-link {
            margin-top: 0; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .get-started-form {
            margin-top: 33px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .get-started-form .ss-btn {
              letter-spacing: 0px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 {
            margin-top: 0; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106.m-t-172 {
              margin-top: 172px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 .almost-done-mb {
              margin-bottom: 20px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 .explanation-text-tablet {
              margin-bottom: 25px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 button {
              height: 35px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .main-footer.onboarding {
            height: 0;
            right: 0; }
        .wrapper-main-container .main-container .wrapper-content-main .mobile-img-wrapper {
          position: absolute;
          bottom: 62px;
          display: block;
          width: 100%;
          left: 0;
          margin-top: auto;
          z-index: -1; }
          .wrapper-main-container .main-container .wrapper-content-main .mobile-img-wrapper img {
            max-width: 460px;
            display: block;
            margin: auto; }
          .wrapper-main-container .main-container .wrapper-content-main .mobile-img-wrapper .narrower-image {
            max-width: 400px; }
      .wrapper-main-container .main-container .header-wrapper {
        padding: 0; } }
  @media only screen and (min-width: 1025px) {
    .wrapper-main-container .main-container .footer-wrapper-mobile .main-footer {
      display: none; } }
  @media only screen and (max-width: 766px) {
    .wrapper-main-container {
      padding: 0; }
      .wrapper-main-container label + .MuiInput-formControl {
        margin-top: 21px !important; }
      .wrapper-main-container #root {
        min-height: 812px; }
      .wrapper-main-container button {
        height: 45px !important; }
      .wrapper-main-container .fa-spin {
        left: 47% !important; }
      .wrapper-main-container .tablet-login-container,
      .wrapper-main-container .forgot-password-tablet,
      .wrapper-main-container .reset-password-tablet {
        margin-top: 103px !important; }
        .wrapper-main-container .tablet-login-container .input-fields-wrapper.m-g-b-55,
        .wrapper-main-container .forgot-password-tablet .input-fields-wrapper.m-g-b-55,
        .wrapper-main-container .reset-password-tablet .input-fields-wrapper.m-g-b-55 {
          margin-bottom: 8px; }
      .wrapper-main-container .form_field input {
        font-size: 14px;
        line-height: 19px;
        padding: 10px 0 7px; }
      .wrapper-main-container .main-container {
        width: 100%;
        height: 100%;
        padding: 20px 20px 28px; }
        .wrapper-main-container .main-container .fas.fa-arrow-left {
          display: inline-block; }
        .wrapper-main-container .main-container .fas.fa-angle-double-left {
          display: none; }
        .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .form_field label {
          font-size: 16px;
          line-height: 19px; }
        .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .form_field,
        .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .MuiFormControl-root {
          height: 58px; }
        .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .MuiFormControl-root {
          height: 58px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .MuiFormControl-root button {
            height: 7px !important;
            align-items: flex-start; }
        .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .MuiSvgIcon-root {
          font-size: 1.2rem; }
        .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .header-logo {
          width: 130px; }
        .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .header-buttons-wrapper {
          justify-content: space-between;
          margin-top: 11px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .header-buttons-wrapper .button-desktop {
            display: none; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .header-buttons-wrapper .button-mobile {
            width: 45px;
            display: flex;
            padding: 0;
            height: 45px;
            margin-left: 10px;
            margin-right: 0; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .header-buttons-wrapper img {
            margin: auto; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .header-buttons-wrapper .no-account {
            margin-right: 0;
            font-size: 14px;
            line-height: 17px;
            color: #B5B5B5;
            width: 71%; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .header-buttons-wrapper .no-account.w-64 {
              width: 64%; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .header-buttons-wrapper .no-account.no_account_mobile {
              display: block; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .header-buttons-wrapper .no-account.no_account_desktop {
              display: none; }
        .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container {
          padding: 0;
          width: 100%; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .ss-btn {
            width: 100%;
            margin: 0;
            font-size: 16px;
            line-height: 19px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .login-form-button {
            height: 45px;
            font-size: 16px;
            line-height: 19px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container {
            width: 100%;
            margin: 0; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside {
              margin: 0; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .tablet-signup-container {
                margin-top: 77px; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .tablet-signup-container .form-title {
                  margin-bottom: 26px; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .tablet-signup-container .material-field {
                  margin-bottom: 10px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .create-company-tablet .create-company-form-block .form-title {
                margin-bottom: 27px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .create-company-tablet .create-company-form-block .upload-image-name-domain-wrapper.m-g-b-19 {
                justify-content: space-between;
                margin-bottom: 8px; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .create-company-tablet .create-company-form-block .upload-image-name-domain-wrapper.m-g-b-19 .name-domain-wrapper {
                  width: 56%; }
                  .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .create-company-tablet .create-company-form-block .upload-image-name-domain-wrapper.m-g-b-19 .name-domain-wrapper .material-field {
                    margin-bottom: 16px; }
                  .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .create-company-tablet .create-company-form-block .upload-image-name-domain-wrapper.m-g-b-19 .name-domain-wrapper .w-210 {
                    width: 100%; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .create-company-tablet .create-company-form-block .material-field {
                margin-bottom: 10px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container.wrapper-outside .create-company-tablet .create-company-form-block button {
                margin-top: 30px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .form-title {
              line-height: 120% !important; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .forgot-password-outside .material-field {
              margin-bottom: 40px !important; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .over {
              padding: 0; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .m-g-t-109 {
              margin-top: 78px !important; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .m-g-b-18 {
              margin-bottom: 27px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .wrapper-onboarding .registration-box {
              width: 100%;
              margin-top: 94px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .wrapper-onboarding .registration-box.get-started-tablet .second-text {
                margin-bottom: -5px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .wrapper-onboarding .registration-box.get-started-tablet .material-field {
                margin-bottom: 42px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .wrapper-onboarding .registration-box.get-started-tablet .m-g-b-18 {
                margin-bottom: 16px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .wrapper-onboarding .registration-box.get-started-tablet .explanation-text {
                margin-bottom: 0; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .wrapper-onboarding .m-g-t-87.forgot-password-tablet {
              margin-top: 111px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-form-block {
              margin: 0;
              width: 100%; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-form-block .form-title {
                font-size: 35px;
                line-height: 69.1%;
                margin-bottom: 41px; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-form-block .form-title.less-verification-title-tablet {
                  margin-bottom: 35px !important; }
                  .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-form-block .form-title.less-verification-title-tablet .code-input {
                    margin-right: 0; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .registration-box .form-title {
              margin-bottom: 17px !important; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .registration-box .forgot-password-explanation-text {
              margin-bottom: 57px !important; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .form-title.smaller-tablet-fs {
              font-size: 35px;
              line-height: 69%; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .registration-form-block .form-title {
              margin-bottom: 45px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .registration-form-block .registration-from {
              margin-bottom: 40px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .registration-form-block .material-field {
              margin-bottom: 18px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .registration-form-block .login-checkbox-wrapper.m-g-t-25 {
              margin-bottom: 0;
              margin-top: 40px !important;
              font-size: 14px;
              line-height: 17px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .registration-form-block .login-checkbox-wrapper.m-g-t-25 .terms-of-use {
                font-size: 14px;
                line-height: 17px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .registration-form-block .login-checkbox-wrapper.m-g-t-25 input {
                width: 20px;
                height: 20px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form {
              width: 100%; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form.form-verification {
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 13px; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form.form-verification .code-input {
                  width: 45px;
                  height: 45px;
                  margin-right: 0;
                  text-align: center; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form.form-verification button {
                  margin: 40px 0 0 !important; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form .wrapper-submit {
                width: 100%; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form.f-w-unset .get-started-form {
                margin-top: 20px; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form.f-w-unset .get-started-form .material-field {
                  width: 100%; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form.f-w-unset .get-started-form .ss-btn {
                  padding: 20px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form .material-field {
                width: 100%;
                margin-bottom: 30px; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form .material-field .form_field {
                  width: 100%; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form .input-fields-wrapper {
                width: 100%; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form .input-fields-wrapper .material-field {
                  margin-bottom: 10px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form .form_field {
                width: 100%; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form .form-button {
                width: 100%;
                height: 45px;
                font-size: 16px;
                line-height: 19px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form .login-form .login-form-button-wrapper {
                width: 100%;
                flex-direction: column; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form .login-form .login-form-button-wrapper.m-g-t-54 {
                  margin-top: -10px; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form .login-form .login-form-button-wrapper .forgot-password-link {
                  order: 1;
                  margin: auto;
                  text-align: center; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container form .login-form .login-form-button-wrapper .ss-btn {
                  margin: -4px 0 27px;
                  order: 0;
                  font-size: 16px;
                  line-height: 19px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .flex-row .small-hint-text.m-g-r-5.f-s-14,
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .flex-row .forgot-password-link {
              font-size: 13px;
              line-height: 16px; }
            .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container {
              margin-top: 111px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.create-company-tablet {
                margin-top: 75px; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.create-company-tablet .form-title {
                  margin-bottom: 37px; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.create-company-tablet .material-field {
                  margin-bottom: 20px; }
                  .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.create-company-tablet .material-field input {
                    padding: 10px 0 7px; }
                  .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.create-company-tablet .material-field label {
                    font-size: 16px;
                    line-height: 19px; }
                  .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.create-company-tablet .material-field .MuiInputBase-root .MuiSelect-root {
                    padding: 10px 0 7px; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.create-company-tablet .ss-btn {
                  margin-top: 20px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.reset-password-tablet.m-g-t-106.m-t-172 .form-title {
                margin-bottom: 16px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.reset-password-tablet.m-g-t-106.m-t-172 .material-field {
                margin-bottom: 25px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.reset-password-tablet.m-g-t-106.m-t-172 .ss-btn {
                margin-top: 7px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container .m-r-55 {
                margin-right: 0; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container .m-r-55 .explanation-text.m-g-b-10.form_field {
                  margin-bottom: 23px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.pad-0-49 {
                padding: 0; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container .registration-form-block {
                width: 100%;
                margin-right: 0; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container .create-company-form-block {
                margin: 0; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container .create-company-form-block .less-verification-title-tablet {
                  margin-bottom: 23px !important; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container .create-company-form-block .explanation-text.m-g-b-17.form_field {
                  margin-bottom: 0; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container .create-company-form-block .small-hint-text.m-g-b-32 {
                  margin-bottom: 36px; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container .over.m-r-55 {
                margin: 0; }
              .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 {
                margin-top: 102px !important; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 .form-title {
                  margin-bottom: 21px; }
                  .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 .form-title.common-main-text-tablet {
                    line-height: 120%; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 .forgot-password-link.m-g-l-0 {
                  margin-top: 29px !important; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 .almost-done-mb {
                  margin-bottom: 31px !important; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 .explanation-text-tablet {
                  margin-bottom: 8px !important; }
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 button:not(.MuiButtonBase-root),
                .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .overall-inner-container .login-container.m-g-t-106 .login-form-button {
                  margin-top: 40px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .second-text {
            font-size: 18px; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .main-text,
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .form-title {
            font-size: 35px;
            line-height: 120%; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .overall-main-container .explanation-text {
            font-size: 16px;
            line-height: 150%;
            width: 100%; }
        .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .main-footer {
          position: static; }
          .wrapper-main-container .main-container .wrapper-content-main .container .inner-container .main-footer .inner-container {
            justify-content: center; }
        .wrapper-main-container .main-container .wrapper-content-main .mobile-img-wrapper {
          bottom: 28px; }
          .wrapper-main-container .main-container .wrapper-content-main .mobile-img-wrapper img {
            width: 250px; }
          .wrapper-main-container .main-container .wrapper-content-main .mobile-img-wrapper .narrower-image {
            width: 218px; }
          .wrapper-main-container .main-container .wrapper-content-main .mobile-img-wrapper .image-d-n {
            display: none; } }

label + .MuiInput-formControl {
  margin-top: 13px !important; }

label + .MuiInput-formControl.MuiInput-multiline {
  margin-top: 18px !important; }

.MuiSelect-select:before {
  transition: unset !important;
  border-bottom: 1px solid #CCCCCC; }

.MuiInput-underline:before {
  transition: unset !important;
  border-bottom: 1px solid #CCCCCC !important; }

.MuiInput-underline:after {
  border-bottom: 1px solid #CCCCCC !important; }

.MuiInput-underline:hover:before {
  transition: unset !important;
  border-bottom: 1px solid #0F90C0 !important; }

.MuiSelect-select:focus {
  background-color: #E3F0FF !important; }

.MuiSelect-select.MuiSelect-select {
  font-size: 14px; }

.MuiFormControl-marginNormal {
  margin: 0 !important; }

button {
  transition: all 0.3s; }

.registration-from .MuiSelect-icon {
  color: #b9c0c5; }

.MuiSelect-icon {
  top: calc(50% - 10px); }
  @media only screen and (max-width: 766px) {
    .MuiSelect-icon {
      font-size: 1.7rem !important; } }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #09AE47;
  font-family: Lato, serif;
  font-size: 14px;
  transition: background-color 5000s ease-in-out 0s; }

.MuiSelect-select.MuiSelect-select {
  color: #09AE47;
  font-size: 14px;
  font-family: Lato, serif; }

.form-control select {
  position: absolute;
  top: 35px;
  right: 15px; }

.MuiSelect-select.Mui-disabled {
  color: #9D9D9D; }

.MuiInputBase-root {
  padding-left: 15px; }

.MuiInputBase-input {
  font-family: Lato, serif !important; }

[type="date"]:not(.hide_icon) {
  background-image: url("Assets/img/icons/calender.png");
  background-repeat: no-repeat;
  background-position: 97% 50%; }

[type="date"]::-webkit-inner-spin-button {
  display: none; }

[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0; }

.outlined {
  height: 40px; }

.MuiOutlinedInput-root.input_outlined {
  transition-duration: 0.3s; }
  .MuiOutlinedInput-root.input_outlined .MuiOutlinedInput-input {
    padding: 0; }
  .MuiOutlinedInput-root.input_outlined .MuiOutlinedInput-notchedOutline {
    transition-duration: 0.3s;
    border: 1px solid #DDE4EB;
    border-radius: 2px; }
  .MuiOutlinedInput-root.input_outlined:hover, .MuiOutlinedInput-root.input_outlined.Mui-focused {
    transition-duration: 0.3s; }
    .MuiOutlinedInput-root.input_outlined:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.input_outlined.Mui-focused .MuiOutlinedInput-notchedOutline {
      transition-duration: 0.3s;
      border: 1px solid #00BAE5; }
  .MuiOutlinedInput-root.input_outlined.input_outlined_error:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.input_outlined.input_outlined_error.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #FF4444; }
  .MuiOutlinedInput-root.input_outlined.Mui-disabled {
    color: #B9C0C5;
    background: #F4F6F7;
    border-radius: 2px; }
    .MuiOutlinedInput-root.input_outlined.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border: 1px solid #E5E5E5; }
  .MuiOutlinedInput-root.input_outlined input:disabled {
    background-color: #F4F6F7;
    color: #B9C0C5; }

.outlined_disabled .MuiFormLabel-root.Mui-disabled {
  color: #B9C0C5; }

.selection_outlined_wrapper .MuiOutlinedInput-root {
  border-radius: 2px; }

.selection_outlined_wrapper .MuiSelect-select.MuiSelect-select {
  font-size: 13px;
  line-height: 14px;
  color: #535353; }
  .selection_outlined_wrapper .MuiSelect-select.MuiSelect-select.Mui-disabled {
    color: #b5b5b5; }

.selection_outlined_wrapper .MuiSelect-select:focus {
  background-color: #FFFFFF !important; }

.MuiPaper-root {
  margin-top: -7px; }

#menu-selection_outlined .MuiPaper-root {
  padding: 3px;
  background: #F1F2F3;
  border-radius: 2px;
  margin-top: -9px; }

#menu-selection_outlined .MuiButtonBase-root {
  font-family: Lato, serif;
  font-size: 12px;
  line-height: 18px;
  color: #535353;
  padding: 0 10px;
  height: 26px;
  margin-left: 0; }

#menu-selection_outlined .MuiList-root {
  padding: 0; }

#menu-selection_outlined .MuiListItem-button:hover {
  background: #DDE4EB; }
