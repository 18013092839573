@import "./mixins";

.inner-container {
  padding: 0 50px;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #535353;
}

form {
  width: 100%;
}

.main-footer {
   height: 60px;
  display: flex;
  flex-direction: row;

  &.dashboard {
    height: 55px;

    .inner-container {
      padding: 0;
    }
  }
}

.wrapper-onboarding {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .img-wrapper  {
      width: 51%;
  }

  .registration-box {
      flex-direction: column;
      align-items: end;
  }

  .img-wrapper  {
      img {
          width: 130%;
      }
  }

  .get-started-form {
      display: flex;

      .field {
          width: 50%;
          margin-right: 8px;
      }
  }
}

.buttons-wrapper {
  display: flex;
  height: fit-content;
  width: 50%;
}

.info-modal-text-wrapper{
  padding: 30px;
}

.info-modal-main-text{
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  text-align: center;
  color: #535353;
}

.info-modal-second-text {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;

  text-align: center;

  color: #808080;
}

.info-modal-first-button {
  position: relative;
  padding: 0 20px;

  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  outline: none;
  cursor: pointer;
  line-height: 16px;
  /* identical to box height */

  background-color: white;
  display: flex;
  //align-items: center;
  border: 1px solid #DDE4EB;
  box-sizing: border-box;
  border-radius: 3px;

  color: #1090C0;
  height: 35px;
}

.info-modal-first-button:hover{
  border: 1px solid #33B5E5;
}

.info-modal-first-button-blue,
.info-modal-first-button {
  &:disabled {
    background: #E5E5E5;
    border: 1px solid #E5E5E5;
    color: #cccccc;

    &:hover {
      background: #E5E5E5;
      border: 1px solid #E5E5E5;
    }
  }
}

.info-modal-first-button-blue{
  color: white;
  background-color: #0F90C0;
  border: 1px solid #0F90C0;
  transition-duration: 0.3s;
}

.info-modal-first-button-blue:hover{
  border: 1px solid #33B5E5;
  background-color: #33B5E5;
  transition-duration: 0.3s;
}

.info-modal-first-button-yellow{
  color: white;
  background-color: #FF9800;
  border: 1px solid #FF9800;
}

.info-modal-first-button-yellow:hover{
  border: 1px solid #FFBB33;
  background-color: #FFBB33;
}

.info-modal-first-button-red {
  color: white;
  background-color: #FF0000;
  border: 1px solid #FF0000;
}

.info-modal-first-button-red:hover {
  color: white;
  background-color: #FF6565;
  border: 1px solid #FF6565;
}

.explanation-textarea {
  background: #EFEFF0;
  border-radius: 3px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 65%;
  padding: 15px;
  height: 130px;
  width: 100%;
  resize: none;
  border: none
}

.form-textarea {
  background: #EFEFF0;
  border-radius: 3px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 65%;
  padding: 15px;
  height: 106px;
  width: 100%;
  resize: none;
  border: none
}

.far {
  &.fa-eye,
  &.fa-eye-slash  {
    position: absolute;
    right: 10px;
    top: 21px;
    font-size: 15px;
  }
}

.modal {
  position: absolute;
  top: 135px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $color-blue;
  color: $color-white;
  border-radius: 4px;
  font-family: $Lato;
  width: 400px;
  height: 150px;
}

.login-form-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 44px;
}

.warning-modal-header {
  width: 100%;
  height: 40px;
  background: #0F90C0;
  border-radius: 4px 4px 0 0;

  img {
    width: 20px;
    height: 20px;
  }
}

.explanation-modal-header {
    width: 100%;
    height: 40px;
    flex-shrink: 0;
    border-radius: 4px 4px 0 0;
    padding: 0 30px;

  img {
    width: 20px;
    height: 20px;
  }
}

.explanation-modal-from-wrapper{
    padding: 25px 30px 30px;
    width: 100%;
}

.explanation-modal-from-title{
  font-family: Lato, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #535353;
}

.reason-modal-title {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: 400; // Ali styles
  letter-spacing: 0.03em; // Ali styles
  margin-top: 4px; // Ali styles
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  color: #FFFFFF;
  /*margin-top: 5px;*/
  white-space: pre-wrap;
}

.warning-text-wrapper{
  padding: 24px 30px 31px;
  min-height: 195px;

  .warning-modal-text {
    margin: auto;
    padding: 0;
  }

  .flex-row {
    margin-top: 30px;
    padding: 0;
  }
}

.warning-modal-text{
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 27px 30px 30px;

  color: #535353;

  & .text-bold {
    margin: 0 4px;
  }

  .large_img {
    display: flex;
    flex-direction: column;
    align-items: center;

    .message {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #535353;
      border: none;
    }
    .size {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 160%;
      color: #808080;
      margin: 20px 0 10px;
    }
    .suggestion {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 160%;
      letter-spacing: -0.01em;
      color: #808080;
    }
  }
}

.custom-dropdown-selection-overall {
  position: relative;
  font-family: "Lato", sans-serif;
  background: #FFFFFF;
  border: 1px solid #A9B9C7;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  height: 35px;
  transition-duration: 0.3s;

  &:hover {
    transition-duration: 0.3s;
    border: 1px solid #00BAE5;
  }

  &.disabled {
    border: 1px solid #E5E5E5;

    .custom-dropdown-button,
    .dropdown-caret {
      cursor: not-allowed;
      color: #CCCCCC;
    }

  .dropdown-line {
    background-color: #E5E5E5;
  }

  .dropdown-caret {
    border-color: #E5E5E5 transparent transparent transparent;
  }

    &:hover {
      transition-duration: 0.3s;
      border: 1px solid #E5E5E5;
      cursor: not-allowed;
    }
  }
}

.dropdown-line {
  position: absolute;
  height: 23px;
  top: 6px;
  right: 30px;
  width: 1px;
  background: #A9B9C7;
  cursor: pointer;
}

.dropdown-caret {
  position:absolute;
  width:0;
  height:0;
  border: 6px solid;
  border-color: #A9B9C7 transparent transparent transparent;
  right: 9px;
  top: 15px;

  &:hover {
    cursor: pointer;
  }
}

.custom-dropdown-button{
  padding: 13px 40px 13px 11px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 74%;
  color: #535353;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.custom-dropdown-list-selection-with-values {
  background: #FFFFFF;
  border: 1px solid #00BAE5;
  box-sizing: border-box;
  border-radius: 0 0 3px 3px;
  width: 98%;
  max-height: 156px;
  overflow-y: auto;
  margin-left: 2px;
  margin-top: -6px;
  position: absolute;
  z-index: 1000;
  top: 34px;
}

.dropdown-search {
  width: 100%;
  height: 30px;
  background: #E5E5E5;
  padding: 11px 11px 10px 11px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 74%;
  color: #535353;
  border: none;
}


.custom-dropdown-list-selection-with-values li {
  font-family: Lato, serif;
  font-style: normal;
  font-weight: normal;
  backdrop-filter: blur(1px);
  font-size: 12px;
  line-height: 74%;
  /* identical to box height, or 9px */

  color: #535353;
  width: 97%;
  padding: 8px 11px;
  margin: 5px 0 5px 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  //max-width: 165px;


  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #0F90C0;
    color: white;
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    font-style: italic;

    &:hover {
      cursor: not-allowed;
    }
  }
}

$loadingSpinnerDiameter: 28px;
$loadingSpinnerStrokeWidth: 4px;
$loadingSpinnerStrokeColor: #FFF;
$loadingSpinnerOpacity: .8;
$loadingSpinnerStrokeStyle: solid; // solid, dashed, dotted
$loadingSpinnerSpeed: .7s;

.loadingSpinner {
  border: $loadingSpinnerStrokeWidth $loadingSpinnerStrokeStyle $loadingSpinnerStrokeColor;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  width: $loadingSpinnerDiameter - ($loadingSpinnerStrokeWidth * 2);
  height: $loadingSpinnerDiameter - ($loadingSpinnerStrokeWidth * 2);
  opacity: $loadingSpinnerOpacity;
  border-radius: 50%;
  animation: loadingSpinner $loadingSpinnerSpeed infinite linear;
  -webkit-animation: loadingSpinner $loadingSpinnerSpeed infinite linear;
}

button .loadingSpinner {
  margin-top: -$loadingSpinnerDiameter / 2;
  margin-left: -$loadingSpinnerDiameter / 2;
  position: absolute;
  top: 50%;
  left: 50%;
}

.nav{
  li {
    div {
      color: #a7b1c2;
      font-weight: 600;
      padding: 14px 20px 14px 25px;
      display: block;
      cursor: pointer;
    }
  }
}



@keyframes loadingSpinner {
  from { transform: rotate(0deg)   }
  to   { transform: rotate(360deg) }
}

@-webkit-keyframes loadingSpinner {
  from { -webkit-transform: rotate(0deg)   }
  to   { -webkit-transform: rotate(360deg) }
}

.switch_wrapper {
  .switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 18px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #1AB394;

    &.red {
      background-color: #FB5757;
    }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.jobs-dropdown-wrapper {
  width: 278px;
}

.export-items-dropdown-wrapper {
  width: 300px;
  margin-right: auto;
}

.super-admin-companies-table-container {
  margin-top: 26px;
  margin-bottom: 10px;

  &.head {
    border-bottom: 1px solid #E0E0E0;

    .common_table thead {
      border: 0;
    }
  }

  &.body {
    height: 300px;
    flex-grow: 1;
    overflow-y: scroll;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    scrollbar-width: thin;

    .companies_table,
    .jobs_table {
      tr {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .common_table {
    width: 100%;

    thead {
      border-bottom: 1px solid #E0E0E0;

      tr th {
        height: 32px;
        font-weight: 600;
        font-size: 13px;
        line-height: 100%;
        color: #535353;
        text-align: start;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .title_value_wrapper {
          line-height: 32px;
        }

        .th_inner {
          display: flex;
          align-items: center;

          span {
            width: max-content;
          }

          .icons_wrapper {
            margin-left: 5px;
            width: 8px;

            .sort_icon {
              fill: #DDE4ED;

              &.active_icon_sort {
                fill: #535353;
              }

              &:hover {
                fill: #00BAE5;
                cursor: pointer;
              }
            }
            .sort_icon_active{
              fill: #00BAE5 !important;
            }
          }
        }
      }
    }

    tr {
      width: calc(100% - 8px);
      display: flex;
      justify-content: flex-start;
      margin: 0;
      align-items: center;

      th,td {
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
        color: #535353;
        text-align: start;
      }

      td {
        font-size: 13px;
      }
    }

    tbody {
      tr {
        transition-duration: 0.3s;
        min-height: 40px;
        height: auto;
        padding: 5px 0;

        &.activated {
          td {
            color: #535353
          }
        }

        &.deactivated {
          td {
            color: #a9b9c7
          }
        }

        &:first-child {
          margin-top: 0;
        }

        &:nth-child(odd) {
          background: #F5F5F5;
        }

        &:hover {
          cursor: auto;
          td {
            color: #26AEE1;
          }
          background: #E6F8FC;
        }
      }

      td {
        font-weight: normal;
        line-height: 120%;
        &:nth-child(4) {
          font-size: 12px;
        }
      }
    }
  }

}

.panel_wrapper {
  display: flex;

  &.selector {
    .search_box_wrapper {
      margin-right: 15px;
    }

    .custom-dropdown-selection-overall {
      margin-right: auto;
    }

    @include mq("tablet-max") {
      .search_box_wrapper {
        width: 240px;
      }

      .custom-dropdown-selection-overall {
        width: 235px;
        margin-right: 0;
      }
    }

    @include mq("l") {
      .search_box_wrapper {
        width: 100px;
      }

      .custom-dropdown-selection-overall {
        width: 130px;
      }
    }
  }

  &.jobs_tab {
    @include mq("xl") {
      .flex-row {
        width: 50%;
        justify-content: start;

        &.right_side {
          align-items: end;
          justify-content: flex-end;
        }

        .jobs-dropdown-wrapper {
          width: 274px;
        }
      }

      .search_box_wrapper {
        margin-right: auto;
        margin-bottom: 17px;
        width: 345px;
      }
    }
  }

  @include mq("l") {
    &.wrap {
      flex-direction: column;

      .buttons-wrapper {
        margin-top: 20px;
      }
    }
  }
}

.spinner_container {
  width: 100%;
  height: 100%;
  display: flex;

  .spinner {
    margin: auto;
  }
}

.job-details-dropdown-wrapper{
  width: 120px;
}

.add-item-to-package-dropdown-wrapper{
  width: 160px;
}

.mark-up-dropdown-wrapper {
  width: 225px;
}

.filter-report-dropdown-wrapper {
  width: 120px;
}

.filter-report-dropdown-wrapper-operator{
  width: 180px;
}

.package-page-dropdown-wrapper{
  width: 160px;
}

.sidebar {
  height: 100%;
  width: 200px;
  color: white;
  background-color: #02354B;

  .current_version {
    font-family: Lato, serif;
    font-size: 9px;
    line-height: 11px;
    color: #E5E5E5;
    margin: 0 0 22px 22px;
    display: block;
  }
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  height: 100%;
  display: flex;
  flex-direction: column;

  .menu_item {
    .menu_item_container {
      padding: 0;

      .sub_items_container {
        padding: 0 0 5px;

        a {
          padding: 0 0 0 55px;

          .nav-label {
            color: #B4C6D8;
          }

          &.active {
            .nav-label {
              color: #00BAE5;
            }
          }

          &:hover {
            .nav-label {
              color: #FFFFFF;
            }

            .icon_container {
              .fas {
                color: #FFFFFF;
              }

              svg {
                fill: #FFFFFF;
              }
            }
          }
        }
      }
    }

    a,
    .assets-management-wrapper {
      display: flex;
      padding: 10px 22px;
      font-family: Lato, serif;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #B4C6D8;
      align-items: center;
      height: 35px;

      .inner {
        display: flex;
        padding: 0;
        align-items: center;
        transition-duration: 0.3s;
      }

      .icon_container {
        padding: 0;
        margin-right: 10px;
        transition-duration: 0.3s;
        min-width: 16px;
        text-align: center;
      }

      .fa-qrcode {
        font-size: 18px;
        color: #B4C6D8;
        transition-duration: 0.3s;
      }

      svg {
        fill: #B4C6D8;
        transition-duration: 0.3s;
      }

      .fa-angle-up {
        fill: #B4C6D8;
        transform: rotate(180deg);
        transition-duration: 0.3s;

        &.active {
          transform: rotate(360deg);
          color: #00BAE5;
        }
      }
    }

    a {
      &:hover {
        .nav-label {
          color: #FFFFFF;
          transition-duration: 0.3s;
        }

        .icon_container {
          .fas {
            color: #FFFFFF;
            transition-duration: 0.3s;
          }
          svg {
            fill: #FFFFFF;
            transition-duration: 0.3s;
          }
        }
      }
    }

    .assets-management-wrapper {
      justify-content: space-between;

      &:hover {
        color: #FFFFFF;
        .nav-label {
          color: #FFFFFF;
        }

        .icon_container {
          svg {
            fill: #FFFFFF;
          }
        }

        .fa-angle-up.active {
          color: #FFFFFF;
        }
      }
    }

    .sub_items_container {
      a {
        height: 0;
        transition-duration: 0.3s;

        .nav-label {
          font-size: 0;
          transition-duration: 0.3s;
        }
      }
    }

    &.active_ca {
      background: #002636;

      .nav-label {
        color: #00BAE5;
      }

      .fas {
        color: #00BAE5;
      }

      svg {
        fill: #00BAE5;
      }

      .fa-angle-up {
        color: #00BAE5;
      }

      .assets-management-wrapper {
        padding: 0 22px;
      }

      a .nav-label {
        font-family: Lato, serif;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #00BAE5;
        transition-duration: 0.3s;

        &:hover {
          color: #FFFFFF;
        }
      }

      a {
        &:hover {
          .nav-label {
            color: #FFFFFF;
          }

          .icon_container {
            svg {
              fill: #FFFFFF;
            }
          }
        }
      }

      .sub_items_container {
        a {
          height: 35px;
          .nav-label {
            color: #B4C6D8;
            transition-duration: 0.3s;
          }
        }
      }
    }

    &.settings {
      margin: auto 22px 22px;
      background: #215E78;
      border-radius: 3px;
      height: 30px;
      transition-duration: 0.3s;

      a {
        padding: 0 20px;
        height: 30px;
        align-items: center;
      }

      &.active_ca {
        background: #002636;
      }

      &:hover {
        background: #346A81;
        .nav-label {
          color: #FFFFFF;
        }
      }
    }
  }
}

.header {
  padding: 10pt
}

.avatar-image {
  width: 50px;
  height: 50px;
  border-radius: 50%!important;
}

.sidebar-label {
  padding-left: 29px;
  width: 100%;
  height: 100%;
  text-align: start;
}

.ca_nav_header {
  margin: 50px 22px 14px;
  text-align: center;
  padding: 0;
  border-bottom: 1px solid #346A81;

  .account_settings {
    cursor: auto;
    padding: 5px;
    margin: 0 auto;
    width: 60px;
    height: 60px;
    background-color: #FFFFFF;
    border-radius: 15%;
    display: flex;
    align-items: center;

    .avatar_image {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .company_title {
    display: block;
    font-family: Lato, serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin-top: 13px;
    color: #FFFFFF;

    &.subtitle {
      font-size: 10px;
      line-height: 12px;
      color: #B4C6D8;
      margin-top: 10px;

      &.parent {
        font-weight: normal;
        margin-top: 3px;
        margin-bottom: 10px;
      }

      &.position {
        margin-top: 3px;
      }
    }
    &.main_menu {
      font-size: 12px;
      line-height: 14px;
      text-align: start;
      margin: 35px 0 14px;
    }
  }
}

.disabledBlockElement {
  pointer-events: none;
  opacity: 0.4;
}

.job_container {
  height: 100%;

  .companies_wrapper.job {
    margin-top: 0;
    padding: 25px;
    background-color: #FFFFFF;

    .panel_wrapper {
      margin: 0 0 32px;

      .table_tools_block {
        display: flex;
        margin-right: auto;

        .white {
          margin-right: 15px;
        }
      }

      .custom-dropdown {
        height: 35px;
        max-height: 35px;
        min-width: 150px;
      }
    }

    .nav_switcher {
      height: 75%;

      .nav_switcher_ul {
        display: flex;
        border-bottom: 1px solid #CCCCCC;
        margin-bottom: 10px;
        margin-right: 8px;
      }

      .switcher_link {
        font-family: Lato, serif;
        font-size: 13px;
        line-height: 16px;
        color: #999999;
        padding: 0 15px 7px;
        border-bottom: 1px solid #CCCCCC;
        margin-bottom: -1px;
        cursor: pointer;

        &.active {
          font-weight: bold;
          color: #535353;
          border-bottom: 1px solid #00BAE5;
        }
      }
    }

    .nav_tab_wrapper {
      background-color: rgb(240, 240, 241);
    }

    .items_note_container {
      height: 94%;
      margin-top: -1px;
      overflow-y: auto;
      scrollbar-width: thin;
      display: flex;
      flex-direction: column;

      .categories_list {
        border: 1px solid #DDE7ED;
        border-radius: 3px;
        margin-bottom: 10px;
        margin-right: 8px;
        transition-property: color;

        .list_item {
          display: flex;
          //padding: 12px 15px;
          padding: 2px 15px 0; //ali css
          border-radius: 3px;
          cursor: pointer;
          background-color: #F0F0F0;
          transition-duration: 0.3s;
          transition-property: color;

          .note_title {
            font-family: Lato, serif;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #535353;
            margin-top: 3px; //ali css

            &.num {
              margin-left: 5px;
            }
          }

          .plus-category {
            padding-top: 2px;

            //&.os_win {
            //  background-color: red;
            //}
          }

          &.expand_notes {
            border-bottom: 1px solid #DDDDDD;
            margin-bottom: 15px;
            border-radius: 3px 3px 0 0;

            .plus-category,
            .note_title {
              color: #00BAE5;

            }
          }

          &.outside {
            background-color: #FFFFFF;
          }
        }

        &.categories_list_expanded {
          border: 1px solid #00BAE5;
          border-radius: 3px 3px 0 0;

          .categories_list {
            background-color: #F8F8F8;
            transition-property: color;

            .expand_notes {
              margin-bottom: 0;
            }
          }

          &:hover {
            border: 1px solid #00BAE5;
          }
        }

        &.notes_list_item_outer {
          background-color: #F8F8F8;

          .categories_list {
            margin: 0 15px 0 40px;
            border: none;

            &:not(:last-child) {
              border-bottom: 1px solid #E0E0E0;
            }

            .notes_list_item {
              .notes_wrapper {
                .company_item_name {
                  padding: 10px 0;

                  .buttons_note_wrapper {
                    //margin-left: 45px;
                    margin-left: 15px;
                  }

                  .item_name_property {
                    font-size: 13px;
                    line-height: 140%;
                  }
                }
              }
            }
          }
        }

        .subcategory_list {
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 5px;

          &.categories_list_expanded {
            border: 1px solid #F8F8F8;

            .categories_list {
              background-color: #F8F8F8;
              margin-left: 40px;
              margin-right: 15px;
              margin-bottom: 0;
            }

            &:hover {
              border: 1px solid #F8F8F8;
            }

            .placeholders-list {

              .placeholder_list_item {

                &:hover {
                  background-color: #F8F8F8;
                }
              }
            }
          }

          .list_item {
            &:hover {
                background-color: #F8F8F8;
            }
          }

          &:last-child {
            margin-bottom: 10px;
          }
        }

        &.placeholder_list {
          border: none;

          .accessorie_list_item {
            background-color: #F8F8F8;

            .accessorie_list_item_inner {
              .accessorie_image_wrapper {
                width: 90px;
                height: 90px;
                background: #FFFFFF;
                border: 1px solid #dde4eb;
                box-sizing: border-box;
                border-radius: 3px;
                margin-left: 15px;
                display: flex;
                align-items: center;
                position: relative;

                .accessorie_image {
                  max-width: 100%;
                  max-height: 100%;
                  opacity: 1;
                }

                .zoom_icon {
                  border-radius: 3px;
                  transition: .3s ease;
                  opacity: 0;
                  position: absolute;
                  height: inherit;
                  width: inherit;
                  display: flex;
                  top: 0;
                  left: 0;

                  .fa-search-plus {
                    margin: auto;
                    color: #FFFFFF;
                    font-size: 23px;
                  }
                }

                &:hover {
                  cursor: pointer;
                  img {
                    opacity: 0.7;
                    background: rgba(0, 0, 0, 0.3);
                  }

                  .zoom_icon {
                    opacity: 1;
                    background: rgba(0, 0, 0, 0.3);
                  }
                }
              }
              .accessorie_wrapper_container {
                display: flex;
                padding: 10px 0;
                align-items: center;

                .accessorie_wrapper {
                  .accessorie_title {
                    font-family: Lato, serif;
                    font-size: 13px;
                    line-height: 16px;
                    color: #535353;
                    margin-top: 5px;
                    transition-duration: 0.3s;

                    &:hover {
                      color: #00BAE5;
                      transition-duration: 0.3s;
                    }
                  }

                  .company_item_name {
                    display: flex;
                    margin: 25px 0 15px;

                    .item_name_container {
                      &:not(:last-child) {
                        padding-right: 20px;
                        margin-right: 20px;
                        //border-right: 1px solid #CCCCCC;
                      }

                      .item_name_title,
                      .item_name_property {
                        font-family: Lato, serif;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 14px;
                        color: #535353;
                      }

                      .item_name_title {
                        margin-bottom: 5px;
                      }

                      .item_name_property {
                        font-weight: normal;
                      }
                    }
                  }
                }

                .with_img_container {
                  display: flex;
                  margin-left: auto;
                  align-items: center;

                  .buttons_note_wrapper {
                    display: flex;
                    margin-left: 15px;
                    min-width: 55px;

                    .ss-btn.d_btn.refresh:not(:last-child) {
                      margin-right: 5px;
                    }
                  }

                  &.with_img {
                    align-items: flex-start;
                  }
                }
              }

              .note_with_selection {
                display: flex;
                padding-bottom: 10px;

                .job-details-dropdown-wrapper {
                  margin-left: 15px;
                }

                .accessorie_image_wrapper {
                  .danger_badge {
                    position: relative;
                    top: auto;
                    right: auto;
                  }
                }
              }

              .img_dropdown_container {
                margin-right: 70px;
                display: flex;
                margin-left: auto;

                .danger_badge {
                  position: relative;
                  top: auto;
                  right: auto;
                  height: 19px;
                  margin-left: 15px;
                }
              }
            }
          }

          .placeholder_list_item {
            display: flex;
            align-items: center;
            padding: 5px 0;

            .accessorie_title {
              margin-right: auto;
              transition-duration: 0.3s;

              &:hover {
                color: #00BAE5;
                transition-duration: 0.3s;
              }
            }

            .placeholder_note_without_selection {
              margin-right: 45px;

              .danger_badge_container {
                display: flex;
                flex-direction: column;

                .danger_badge {
                  position: relative;
                  margin-top: 10px;
                  margin-left: auto;
                  height: 19px;
                }
              }
            }

            .ss-btn.d_btn.refresh.edit_b {
              margin: 0;

              &.plus {
                margin-left: 5px;
              }
            }
          }

          &:hover {
            border: none;
          }

          &:not(:last-child) {
            border-bottom: 1px solid #E0E0E0;
          }
        }

        &.subcategory_list {
          .notes_list_item {
            padding: 15px;
            background-color: #F8F8F8;

            .item_name_title,
            .item_name_property {
              font-family: Lato, serif;
              font-weight: bold;
              font-size: 12px;
              line-height: 14px;
              color: #535353;
            }

            .item_name_property {
              font-weight: normal;
            }

            .note_items_wrapper {
              display: flex;

              .item_name_property {
                margin-right: auto;
              }

              .accessorie_image_wrapper {
                width: 90px;
                height: 90px;
                background: #FFFFFF;
                border: 1px solid #A9B9C7;
                box-sizing: border-box;
                border-radius: 3px;
                margin-left: 15px;
                display: flex;
                align-items: center;
                position: relative;

                .accessorie_image {
                  max-width: 100%;
                  max-height: 100%;
                  opacity: 1;
                }

                .zoom_icon {
                  border-radius: 3px;
                  transition: .3s ease;
                  opacity: 0;
                  position: absolute;
                  height: inherit;
                  width: inherit;
                  display: flex;
                  top: 0;
                  left: 0;

                  .fa-search-plus {
                    margin: auto;
                    color: #FFFFFF;
                    font-size: 23px;
                  }
                }

                &:hover {
                  cursor: pointer;
                  img {
                    opacity: 0.7;
                    background: rgba(0, 0, 0, 0.3);
                  }

                  .zoom_icon {
                    opacity: 1;
                    background: rgba(0, 0, 0, 0.3);
                  }
                }
              }

              .with_img_container {
                display: flex;
                margin-left: auto;
                align-items: center;

                .buttons_note_wrapper {
                  margin-left: 15px;
                  min-width: 55px;

                  .ss-btn.d_btn.refresh:not(:last-child) {
                    margin-right: 5px;
                  }
                }

                &.with_img {
                  align-items: flex-start;
                }
              }
            }
          }
        }

        &:hover {
          border: 1px solid #00BAE5;
        }

        &.notes_inner {
          border: none;

          .note_items_wrapper {
            border-bottom: 1px solid #E0E0E0;
            align-items: center;
            padding: 5px 0;
          }
        }
      }
    }
  }
}

.job_properties_container {
  display: flex;
  align-items: flex-start;
  background: #F9F9F9;
  border-radius: 3px;
  padding: 12px 15px 10px;
  margin-bottom: 25px;

  .button_back_container {
    display: flex;
    align-items: center;
    padding-right: 30px;
    min-height: 41px;

    @include mq('1365', min) {
      padding-right: 20px;
    }

    .ss-btn.d_btn.refresh {
      margin-right: 0;
      width: 25px;
      height: 25px;
      padding: 5px;
    }

    .title,
    .property_title,
    .property {
      font-family: Lato, serif;
      font-size: 12px;
      line-height: 14px;
      color: #535353;
      transition-duration: 0.3s;

    }

    .info_container {
      width: 66px;
      .title {
        margin: 0 0 8px;
        transition-duration: 0.3s;
        width: 66px;
      }

      .number {
        font-family: Lato, serif;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #1090C0;
        max-width: 66px;
        width: min-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .properties_wrapper {
    //padding-left: 30px;
    //border-left: 1px solid #CCCCCC;
    display: flex;
    max-width: calc(100% - 195px);

    @media only screen and (max-width: 1366px) {
      flex-grow: initial;
    }

    .properties_two_wrapper {
      display: flex;

      .properties_container {
        @media only screen and (max-width: 1366px) {
          .property_wrapper {
            width: 65px;

            &.titles {
              width: 90px;
            }
          }
        }
      }
    }
  }

  .properties_container {
    margin-right: 80px;
    display: flex;

    .property_wrapper {
      margin-right: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: auto;

      &.titles {
        width: max-content;
      }

      .property_title {
        font-weight: bold;
        margin: 0;
        font-size: 12px;
        cursor: default;
        line-height: 14px;

        &:first-child {
          margin-bottom: 10px;
        }
      }

      .property {
        margin-top: 0;
        font-size: 12px;
        line-height: 14px;
        padding-left: 3px;
        color: #999999;
        font-weight: 400;
        cursor: default;

        &:first-child {
          margin-bottom: 11px;
        }
      }
    }
  }

  .buttons_container {
    min-width: 137px;
    min-height: 40px;
    align-items: center;
    display: flex;
    margin-left: auto;

    .new {
      border-radius: 2px;
      font-weight: bold;
      font-size: 9px;
      line-height: 74%;
      text-align: center;
      letter-spacing: 0.03em;
      padding: 0 26px;
      height: 16.35px;
      margin-right: 15px;
    }

    .ss-btn.d_btn.refresh.edit_b {
      margin-right: 5px;
    }
  }
}

.change_headers_color_container {
  .choose_header_label {
    font-family: Lato, serif;
    font-size: 12px;
    line-height: 14px;
    color: #535353;

    &.not_allowed {
      color: #B5B5B5;
    }
  }

  .choose_headers_wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .choose_header_container {
    position: relative;
    margin-left: auto;

    .color_box {
      width: 20px;
      height: 20px;
      border: 1px solid #9D9D9D;
      box-sizing: border-box;
      border-radius: 3px;
      cursor: pointer;

      &.forbid {
        cursor: not-allowed;
      }
    }

    .color_picker {
      background-color: #F8F6F6;
      position: absolute;
      top: 25px;
      right: 0;
      border-radius: 3px;
      z-index: 9;

      .buttons_picker_wrapper {
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        background-color: #FFFFFF;

        .button_ok,
        .button_cancel {
          width: auto;
          font-size: 10px;
          line-height: 12px;
          height: 24px;
          padding-left: 10px;
          padding-right: 10px;
        }

        .button_cancel {
          margin-right: 10px;
        }
      }
    }
  }
}

.attribute-filter-wrapper-regular {
  padding-left: 10px;
  overflow-y: auto;
  height: calc(100% - 45px);
  scrollbar-width: thin;
}

.attribute-filter-wrapper-regular {
  margin-top: 7px;
  padding: 0;

  &::-webkit-scrollbar {
    width: 1px;
  }

  .filter_attribute_container {
    width: 100%;
    max-width: 253px;
  }

  .filter-attribute-wrapper,
  .square-checkbox-wrapper{
    background: #EFEFF0;
    border-radius: 3px;
    height: 30px;
    margin-bottom: 5px;
    margin-right: 25px;
    padding: 0 10px 0 9px;
    font-family: Lato, serif;
    font-size: 12px;
    line-height: 74%;
    color: #535353;
    border: 1px solid #EFEFF0;

    .fa-check-square.active-filter-icon:before {
      color: #00BAE5;
    }

    .fas.fa-square {
      border: 1px solid #00BAE5;
      border-radius: 3px;
      padding: 1px 1px 0 1.5px;
      color: #00BAE5;
    }

    .filter-attribute-name,
    .filter_checkbox_name {
      white-space: nowrap;
    }

    .filter_checkbox_name {
      line-height: 100%;
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .fas {
      &.fa-chevron-right,
      &.fa-chevron-down {
        font-size: 9px;
      }
    }

    &:hover {
      border: 1px solid #00BAE5;
      font-weight: normal;
    }

    &.show_all {
      border: 1px solid #00BAE5;
      color: #00BAE5;
    }
  }

  .square-checkbox-wrapper {
    height: 25px;
    margin: 0 25px 5px 23px;
    padding: 0 10px;
    font-size: 12px;
    position: relative;
    background-color: #F5F5F5;

    .check-box-wrapper {
      top: 4px;
      right: 10px;
    }
  }
}

.select_add_item_attribute_form.attribute_modal {
  padding-right: 20px;

  .filter_above_line {
    width: 340px;
    height: 1px;
    background-color: #E0E0E0;
    display: block;
    margin-bottom: 9px;
  }

  .button_wrapper_above {
    display: flex;
    justify-content: space-between;
    height: 32px;
    padding-bottom: 7px;

    .refresh {
      height: 25px;
      width: 25px;
      padding: 5px;

      &:last-child {
        margin-right: 10px;
      }

      svg {
        fill: #1090C0;
        transition-duration: 0.3s;
      }

      &:hover {
        svg {
          fill: #FFFFFF;
        }
      }
    }
  }

  .attribute-filter-wrapper-regular {
    height: auto;
    max-height: 400px;
    scrollbar-width: thin;

    .filter-attribute-wrapper,
    .square-checkbox-wrapper {
      margin-right: 10px;
      font-size: 12px;

      .filter_checkbox_name {
        width: 270px;
      }
    }
  }
}

.nav_switcher_ul_container {
  margin-bottom: 5px;
  overflow-x: auto;
  scrollbar-width: thin;
  height: 40px;
  flex: 0 0 auto;

  .nav_switcher_ul {
    display: flex;
    border-bottom: 2px solid #CCCCCC;
    scrollbar-width: thin;

    .switcher_link {
      font-family: Lato, serif;
      //font-size: 12px;
      font-weight: 400;
      //line-height: 14px;
      color: #ababab;
      padding: 0 15px 10px;
      border-bottom: 2px solid #CCCCCC;
      margin-bottom: -2px;
      cursor: pointer;
      white-space: nowrap;
      //width: max-content;
      font-size: 13px;
      line-height: 13px;

      .fas {
        font-size: 13px;
        line-height: 13px;
      }

      &.active {
        font-weight: 400;
        color: #535353;
        //padding-bottom: 13px;
        border-bottom: 2px solid #1090c0;

        .fas {
          color: #1090c0;
        }
      }

      &:not(.active) .fas{
        line-height: 17px;
      }

      &:hover {
        color: #00bae5;
        border-bottom: 2px solid #00BAE5;
      }
    }
  }
}

.custom_dropdown {
  position: relative;
  font-family: Lato,sans-serif;
  background: #fff;
  border: 1px solid #a9b9c7;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 270px;
  height: 35px;
  width: auto;

  .custom_dropdown-button-dropdown-selection {
    font-size: 12px;
    width: 240px;
  }

  .custom_dropdown_button {
    padding: 13px 40px 13px 11px;
    font-family: Lato,serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 74%;
    color: #535353;

    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-line {
    position: absolute;
    height: 23px;
    top: 6px;
    right: 30px;
    width: 1px;
    background: #a9b9c7;
  }

  .dropdown-caret {
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: #a9b9c7;
    right: 9px;
    top: 15px;

    &:hover {
      cursor: pointer;
    }
  }

  .custom_dropdown_list {
    background: #fff;
    border: 1px solid #00bae5;
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
    width: 98%;
    max-height: 156px;
    overflow-y: auto;
    margin-left: 2px;
    margin-top: -1px;
    position: absolute;
    z-index: 1000;
    padding-right: 8px;

    .custom_dropdown-button-dropdown-selection {
      backdrop-filter: blur(1px);
      width: 97%;
      padding: 8px 11px;
      margin: 5px 0 5px 4px;

      &:hover {
        background-color: #0f90c0;
        color: #fff;
      }
    }
  }

  &.open {
    border: 1px solid #00bae5;

    .dropdown-caret {
      border-top-color: #00bae5;
    }
  }

  &:hover {
    border: 1px solid #00bae5;
  }
}

.attribute_value_wrapper {
  background: #FFFFFF;
  border: 1px solid #A9B9C7;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 7px 7px 0;
  display: flex;
  flex-wrap: wrap;

  .ss-btn {
    width: 22px;
    height: 21px;
    padding: 0;
    margin-bottom: 7px;
    display: flex;

    .fa-plus {
      margin: auto;
    }
  }

  .cheeps {
    background: #DDE7ED;
    border-radius: 2px;
    font-family: Lato, serif;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: #535353;
    margin: 0 5px 7px 0;
    height: 21px;
    padding: 4px 26px 5px 10px;
    transition-duration: 0.3s;

    .cheeps-delete-icon-wrapper {
      margin: 0 9px 0 0;
    }

    svg {
      fill: #535353;
      transition-duration: 0.3s;
    }

    .cheeps_title {
      transition-duration: 0.3s;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }


    &:hover {
      transition-duration: 0.3s;

      svg {
        fill: #00bae5;
      }

      .cheeps_item,
      .cheeps_title {
        transition-duration: 0.3s;
        color: #00bae5;
      }
    }
  }

  .category-attributes-list {
    background: #FFFFFF;
    border: 1px solid #00BAE5;
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
    width: 100%;
    max-height: 156px;
    overflow-y: auto;
    margin-left: 0;
    margin-top: -1px;
    position: absolute;
    z-index: 1000;
    left: 0;
    scrollbar-width: thin;

    div {
      font-family: Lato, serif;
      font-style: normal;
      font-weight: normal;
      -webkit-backdrop-filter: blur(1px);
      backdrop-filter: blur(1px);
      font-size: 12px;
      line-height: 74%;
      color: #535353;
      width: 97%;
      padding: 8px 11px;
      margin: 5px 0 5px 4px;

      &:hover {
        color: #FFFFFF;
      }
    }
  }
}

.create-edit-form.add_note {
  .upload-image-label {
    width: 100px;
    height: 100px;
    color: #535353;
    .uploaded-image {
      max-width: 98px;
      max-height: 98px;
    }
  }

  .replace-logo-frame {
    width: 101%;
  }

  .delete-image-icon-wrapper {
    color: #fff;
    background: #ff6565;
    border-radius: 2px;
    width: 17px;
    height: 17px;
    display: flex;

    .fa-times {
      margin: auto;
      margin-top: 3px;
    }
  }

  .note_inner_wrapper {
    display: flex;
    flex-wrap: wrap;

    .item_label {
      font-family: Lato, serif;
      font-size: 12px;
      line-height: 12px;
      color: #535353;
      margin-bottom: 5px;
      display: block;
      margin-top: 30px;
      font-weight: 500;

      &.note {
        margin-top: 0;
      }
    }

    .items_container {
      margin-left: 15px;
      width: 225px;

      .note_text_area {
        resize: none;
        background: #fbfcfc;
        border: 1px solid #DDE4EB;
        box-sizing: border-box;
        border-radius: 2px;
        width: 225px;
        height: 140px;
        font-family: Lato, serif;
        font-size: 12px;
        line-height: 18px;
        color: #535353;
        padding: 6px 7px;
        scrollbar-width: thin;

        &:hover,
        &:focus {
          border: 1px solid #00BAE5;
        }
      }

      .custom_dropdown {
        min-width: 225px;
        width: 225px;

        background: #FBFCFC;
        border: 1px solid #DDE4EB;
        border-radius: 2px;
        height: 40px;
        margin-top: 15px;

        .dropdown-caret {
          top: 17px;
        }

        .custom_dropdown_button {
          font-size: 13px;
          line-height: 13px;
          color: #b9c0c5;
        }

        .dropdown-line {
          display: none;
        }

        .custom_dropdown_list {
          top: 35px;
          border-top: none;
          scrollbar-width: thin;
        }

        &.open {
          border-color: #00bae5;
        }

        &.haveValue {

          .custom_dropdown_button {
            color: #535353;
          }
        }
      }

      .add-attributes-button-wrapper {
        margin-top: 15px;
        .custom-dropdown-selection-overall {
          height: 40px;
          background: #FBFCFC;
          border: 1px solid #DDE4EB;
          border-radius: 2px;

          .dropdown-caret {
            top: 17px;
          }

          .dropdown-line {
            display: none;
          }

          .custom-dropdown-button {
            font-size: 13px;
            line-height: 13px;
            color: #b9c0c5;
          }
        }

        .btn {
          height: 100%;
          font-size: 10px;
          line-height: 26px;
          flex: 0 0 40px;

          &[disabled]{
            background-color: #E5E5E5;
            border-color: #E5E5E5;
            color: #cccccc;
          }
        }
      }

      .dynamic_input {
        .add_attributes_button_wrapper {
          justify-content: space-between;

          .job-info-label {
            font-size: 10px;
            line-height: 12px;
          }

          .table-button .fas {
            font-size: 12px;
            line-height: 12px;
          }
        }
        .attribute_value_wrapper {
          border: 0;
          padding: 2px 2px;
          min-height: 24px;
          .cheeps {
            height: 20px;
            padding: 3px 25px 4px 10px;
            margin: 0 3px 0 0;
            border: 0;

            .cheeps-delete-icon-wrapper {
              top: 2px;
            }
          }
        }
      }
    }

    .button_container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 30px;

      .pink {
        background-color: #ffefef;
        color: red;
        padding: 0 8.5px;
        font-size: 20px;
      }
    }

    .dynamicInputContainer {
      //max-height: 105px;
      //overflow: auto;
      margin-right: -8px;
      padding-right: 8px;
      scrollbar-width: thin;
    }

    .dynamic_input {
      margin-top: 10px;

      .add_attributes_button_wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;

        .job-info-label {
          font-family: Lato, serif;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #535353;
        }

        .fa-trash {
          color: #1090C0;
          transition-duration: 0.3s;

          &.without_button:hover {
            transition-duration: 0.3s;
            color: #33B5E5;
          }
        }
      }

      .attribute_value_wrapper {
        background: #F5F5F7;
        border-radius: 3px;
        padding: 3px 0 0 3px;
        min-height: 27px;

        .cheeps {
          margin: 0 3px 3px 0;
          height: 19px;
          padding: 3px 25px 4px 10px;
          max-width: 100%;
          width: fit-content;

          div {
            max-width: 215px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 600;
          }

          .cheeps-delete-icon-wrapper {
            top: 1px;
          }
        }
      }

      .attribute_input {
        background: #F5F5F7;
        border: none;
        font-family: Lato, serif;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.03em;
        color: #535353;
        width: 100%;
        flex-grow: 1;
        min-width: 40px;
        min-height: 21px;
      }
    }

    .asset_name {
      font-family: Lato, serif;
      font-size: 14px;
      line-height: 17px;
      color: #535353;
      width: 100%;

      span {
        font-weight: bold;
        margin-right: 3px;
      }
    }

    &.edit_copy_asset_container {
      .items_container {
        width: 550px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .input_name_wrapper {
          width: 260px;

          &.description {
            width: 100%;
            margin-top: 20px;
          }
        }

        &:focus {
          background-color: #E3F0FF;
        }

        .dropdown_container {
          width: 260px;
        }

        .add-attributes-button-wrapper.attributes {
          width: 100%;

          .custom-dropdown-selection-overall {
            margin-right: 12px;
            width: 474px;
          }
        }

        .dynamicInputContainer {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          //max-height: 200px;
          //overflow: auto;

          .dynamic_input {
            width: 47%;
            margin-top: 15px;

            .cheeps {
              div {
                max-width: 170px;
              }

              svg {
                fill: #535353;
              }

              .cheeps_title {
                transition-duration: 0.3s;
              }


              &:hover {
                svg {
                  fill: #00bae5;
                }

                .cheeps_item {
                  color: #00bae5;
                }
              }
            }

            .attribute_input {
              width: fit-content;

              &.color_red {
                color: #ff6565;
              }
            }

            .attribute_value_container {
              display: flex;
              justify-content: space-between;

              .attribute_value_wrapper {
                width: 220px;
              }
            }
          }
        }
      }

      &.cp {
        .items_container {
          width: 451px;
          margin-top: 30px;

          .item_label {
            margin-top: 0;
          }

          .input_name_wrapper {
            width: 260px;

            &.description {
              width: 100%;
              margin-top: 20px;
            }
          }

          .dropdown_container {
            width: 260px;
          }

          .add-attributes-button-wrapper.attributes {
            width: 100%;

            .custom-dropdown-selection-overall {
              margin-right: 12px;
              width: 474px;
            }
          }

          .dynamicInputContainer {
            width: 100%;
            display: block;
            //max-height: 200px;
            //overflow: auto;
            border: 1px solid #D8E7F4;
            border-radius: 3px;
            margin-top: 15px;
            overflow: auto;
            padding: 0 15px 14px 13px;
            transition-duration: 0.3s;
            height: 200px;

            .dynamic_input {
              width: 100%;
              margin-top: 10px;
              transition-duration: 0.3s;

              .cheeps {
                transition-duration: 0.3s;
                height: 21px;

                div {
                  max-width: 370px;
                }

                svg {
                  fill: #535353;
                  transition-duration: 0.3s;
                }

                .cheeps_item {
                  transition-duration: 0.3s;
                  font-weight: 500;
                }

                &:hover {
                  svg {
                    fill: #00bae5;
                    transition-duration: 0.3s;
                  }

                  .cheeps_item {
                    color: #00bae5;
                  }
                }
              }

              .attribute_input {
                width: fit-content;
              }

              .attribute_value_container {
                display: flex;
                justify-content: space-between;

                .attribute_value_wrapper {
                  border: none;
                  transition-duration: 0.3s;
                  width: 100%;
                }
              }
            }

            .fa-trash.dynamic {
              transition-duration: 0.3s;

              &:hover {
                color: #00BAE5;
                transition-duration: 0.3s;
              }
            }
          }
        }

        .switch_wrapper_container {
          margin-left: 10px;
        }

        .img_category_container {
          width: 260px;
          margin-top: 30px;

          .dropdown_container {
            .item_label {
              margin-top: 27px;
            }
          }

          .upload-image-label {
            width: 109px;
            height: 109px;

            .uploaded-image {
              max-height: 109px;
              max-width: 100px;
            }
          }
        }
      }

      &.sa {
        padding: 30px;
        .items_container {
          .input_name_wrapper_outside {
            width: 400px;
            flex-grow: 1;
          }

          .name {
            margin-top: -3px;
          }

          .website,
          .sku,
          .vendor {
            margin-top: 25px;
          }

          .button_container {
            margin-top: 30px;
          }

          .dynamicInputContainer {
            height: 210px;
            border: 1px solid #BAC4CC;
            box-sizing: border-box;
            border-radius: 3px;
            scrollbar-width: thin;
          }

          .item_label {
            margin-top: 15px;
          }

          .input_name_wrapper {
            &.sku,
            &.website {
              margin-top: 35px;
            }
          }

          .add-attributes-button-wrapper {
            margin-bottom: 10px;
          }

          .dynamicInputContainer {
            height: 210px;
            overflow-y: auto;
            padding: 8px 10px 10px 10px;

            .dynamic_input {
              margin-top: 0;
              margin-bottom: 15px;
              width: 48%;
            }

            .attribute_value_wrapper {
              border: none;
            }
          }
        }
      }
    }

    .upload-image-label {
      border: 1px solid #DDE4EB;
    }
  }
}

.main_item_wrapper {
  background: #F9F9F9;
  border-radius: 3px;
  width: 20%;
  height: 284px;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #F9F9F9;
  transition-duration: 0.3s;

  .item_attributes_category {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid #E5E5E5;

    .fa-times {
      &:hover {
        font-size: 10px;
        cursor: pointer;
        color: #00BAE5;
      }
    }

    .title {
      font-family: Lato, serif;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      padding-right: 10px;
    }
  }

  .imgs_wrapper {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 10px;

    .img_container {
      background: #FFFFFF;
      border: 1px solid #F1F2F3;
      box-sizing: border-box;
      border-radius: 3px;
      min-width: 78.3px;
      max-width: 78.3px;
      height: 80px;
      align-items: center;
      display: flex;
      position: relative;
      cursor: pointer;
      margin: 0;

      img {
        max-height: 100%;
        max-width: 100%;
        opacity: 1;
        margin: auto;
      }

      .zoom_icon {
        transition: .3s ease;
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;

        .fa-search-plus {
          margin: auto;
          color: #FFFFFF;
          font-size: 23px;
        }
      }

      &.no_img_ext {
        cursor: not-allowed;
        background: #F6F6F6;
        border: 1px solid #B3C5D0;
      }

      &:hover {
        img {
          opacity: 0.7;
          background: rgba(0, 0, 0, 0.3);
        }

        &.no_img_ext {
          img {
            background: inherit;
          }
        }

        .zoom_icon {
          opacity: 0.3;
          background: rgba(0, 0, 0, 0.3);
        }
      }
    }

    .item_name_wrapper {
      width: 20px;
      padding-left: 15px;
      flex-grow: 1;

      .note_level_label {
        width: 80px;
        height: 19px;
        margin-bottom: 3px;
        margin-left: auto;
        padding: 4px 0;
        font-size: 9px;
        line-height: 11px;
      }
    }

    .item_name {
      font-family: Lato, serif;
      font-size: 10px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #1090C0;
      font-weight: 600;
    }

    .item_name_vendor {
      font-family: Lato, serif;
      font-size: 9px;
      line-height: 140%;
      color: #999999;
      margin-bottom: 5px;
      margin-top: -2px;
    }
  }

  .item_info_wrapper {
    height: 117px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-height: thin;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  }

  .company_item_name,
  .company_item_name_color_Scheme {
    .item_name_container {
      margin-bottom: 3px;

      .item_name_title,
      .item_name_property {
        font-family: Lato, serif;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: 0.02em;
        color: #535353;
        display: inline-block;
        margin-right: 2px;
        font-weight: 400;
      }

      .item_name_title {
        font-size: 10px;
        line-height: 13px;
        font-weight: 600;
      }

      .item_name_property {
        color: #696969;
        font-weight: 300;
      }
    }
  }

  .check_box_wrapper {
    cursor: pointer;
  }

  &.deactivate {
    pointer-events: none;
    opacity: 0.7;
    color: #CCCCCC;

    &.checkbox_events_on {
      pointer-events: auto;
    }

    .item_name,
    .item_name_title {
      color: #CCCCCC;
    }

    .item_name_container {
      .item_name_title,
      .item_name_property {
        color: #CCCCCC;
      }
    }

    &.selected {
      border: 1px solid #E5E5E5;

      .active-filter-icon {
        color: #CCCCCC;
      }
    }
  }

  &.selected {
    border: 1px solid #00BAE5;

    &.not_allowed_item {
      opacity: 0.35;
      .check_box_wrapper {
        &:hover {
          cursor: not-allowed;
        }
      }

      &:hover {
        opacity: 0.25;
      }
    }
  }

  &:hover {
    border: 1px solid #00BAE5;
  }

  .main_item_wrapper {
    margin-right: 10px;
  }
}

.simple_input,
.search_input {
  width: 100%;
  height: 35px;
  background: #EFEFF0;
  border: 1px solid #EFEFF0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 35px 0 15px;
  transition-duration: 0.3s;
  font-family: "Lato";

  &:focus,
  &:hover {
    border: 1px solid #00BAE5;
    transition-duration: 0.3s;
  }

  &:disabled {
    border: 1px solid #EFEFF0;
    background: #EFEFF0;
    color: #7F7F7F;
  }

  &::placeholder {
    font-size: 12px;
    color: #C6C6C6;
  }

  //&:focus {
  //  + .fa-search {
  //    color: #00BAE5;
  //  }
  //}
}

.simple_input_container {
  width: 100%;

  .simple_input {
    color: #1AB394;
    padding-left: 10px;
    font-family: Lato, serif;
    font-size: 12px;
    line-height: 17px;
    transition-duration: 0.3s;

    &:focus {
      color: #535353;
    }

    &:disabled {
      border: 1px solid #EFEFF0;
      background: #EFEFF0;
      color: #7F7F7F;
    }
  }

  &.inside_placeholder {
    width: 400px;
    flex-grow: 1;
    position: relative;

    .simple_input { // Ali
      //padding-left: 57px;
      font-size: 13px;
      padding: 5px 35px 0 57px;
    }

    .inside_placeholder_title { // Ali
      position: absolute;
      left: 15px;
      top: 33%;
      //transform: translateY(-50%);
      font-weight: 500;
      font-size: 13px;
    }
  }

  .invalid {
    border: 1px solid #FF0000;
    color: #535353;
  }



  //&.disabled {
  //  .simple_input {
  //    &:focus,
  //    &:valid,
  //    &:hover {
  //      border: 1px solid red !important;
  //    }
  //  }
  //}
}

.button_wrapper {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.form_label {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
  display: inline-block;
}

.settings_input {
  width: 100%;
  height: 35px;
  background: #EFEFF0;
  border-radius: 3px;
  border: 1px solid #EFEFF0;
  font-family: Lato, serif;
  font-size: 12px;
  line-height: 14px;
  transition-duration: 0.3s;
  padding-left: 11px;

  &:disabled {
    color: #B5B5B5 !important;
  }

  &:not(:disabled):hover {
    border: 1px solid #00BAE5;
  }
}

.package_info_container {
  display: flex;
  background: #F9F9F9;
  border-radius: 3px;
  padding: 0 10px;
  align-items: center;
  height: 35px;

  .info_title_wrapper {
    display: flex;
    align-items: baseline;

    &:first-child {
      margin-right: 20px;
    }

    .property_title {
      font-weight: bold;
      font-size: 12px;
      line-height: 13px;
      margin: 0 3px 0 0;
      cursor: default;
      white-space: nowrap;
    }

    .property {
      font-size: 12px;
      font-weight: 400;
      cursor: default;
    }
  }
}

.licence_container {
  display: flex;

  .basic,
  .premium {
    border-radius: 2px;
    padding: 5px 13px 4px;
    display: flex;
    align-items: center;
    font-family: Lato, serif;
    font-weight: 400;
    font-size: 9px;
    line-height: 74%;
    letter-spacing: 0.03em;
    min-height: 18px;
    color: #FFFFFF;
    justify-content: center;

    .days_wrapper {
      display: contents;
      font-size: 10px;
    }
  }

    .basic {
      background-color: #1090C0;
    }

    .premium {
      background-color: #E3B848;
    }
}

.min_animation {
  cursor: pointer;
  transition-duration: 0.3s;

  &:hover {
    transition-duration: 0.3s;
    color: #05BAE5;
    cursor: pointer;
  }
}

.resource_label {
  min-width: 80px;
  height: 19px;
  border-radius: 2px;
  font-family: Lato, serif;
  font-style: normal;
  font-size: 9px;
  line-height: 74%;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 13px 4px;
  font-weight: 600;
  letter-spacing: 0.05em;

  &.blue {
    background-color: #0F90C0;
  }

  &.pink {
    background-color: #C16CC2;
  }

  &.gray {
    background-color: #C4C4C4;
  }
}

.with_value {
  .MuiFormLabel-root {
    top: 4px;
    color: #B5B5B5;
  }
}

.material-field.custom {
  .MuiInputLabel-root.Mui-focused {
    top: 4px;
  }

  .with_value {
    .MuiFormLabel-root {
      top: 4px;
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    height: 46px;

    .MuiInput-formControl {
      padding-left: 0;
      margin-top: 0 !important;
    }
  }
}

#create_copy_asset_description,
#create_copy_asset_name_sa,
#create_copy_asset_sku_sa,
#create_copy_asset_website_sa,
#create_copy_asset_vendor_sa,
#create_asset_request_name,
#create_asset_request_sku,
#create_asset_request_website,
#create_asset_request_note {
  height: 24px;
  padding: 17px 15px 5px;
  font-size: 14px;
  text-overflow: ellipsis;

  &:focus {
    background-color: #E3F0FF;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #E0E0E0;
  display: block;
}

.radio_item {
  @-moz-document url-prefix() {
    .first,
    .second {
      margin-top: -1px;
      label::before {
        top: 0 !important;
      }
      label::after {
        top: 3px !important;
      }
    }

    .second {
      margin-bottom: -2px;
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
    position: relative;
    padding-left: 17px;
    cursor: pointer;
    font-family: Lato, serif;
    font-size: 12px;
    line-height: 16px;
    color: #535353;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 10px;
    height: 10px;
    border: 1px solid #1090C0;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 6px;
    height: 6px;
    background: #1090C0;
    position: absolute;
    top: 5px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.accessorie_image_wrapper {
  width: 90px;
  height: 83px;
  background: #FFFFFF;
  border: 1px solid #dde4eb;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  .accessorie_image {
    max-width: 100%;
    max-height: 100%;
    opacity: 1;
  }

  .zoom_icon {
    border-radius: 3px;
    transition: .3s ease;
    opacity: 0;
    position: absolute;
    height: inherit;
    width: inherit;
    display: flex;
    top: 0;
    left: 0;

    .fa-search-plus {
      margin: auto;
      color: #FFFFFF;
      font-size: 23px;
    }
  }

  &:hover {
    cursor: pointer;

    img {
      opacity: 0.7;
      background: rgba(0, 0, 0, 0.3);
    }

    .zoom_icon {
      opacity: 1;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.global_loader {
  position: fixed;
  background-color: white;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.green_item {
  background: #1AB394;
  border-radius: 2px;
  font-family: Lato, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #FFFFFF !important;
  padding: 2px 10px;
  display: flex;
  width: fit-content;
}

.super-admin-companies-table-container.head {
  padding-right: 5px;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ff4444;
}
.MuiInputBase-input.MuiInput-input {
  color: #535353
}
/* todo make it work properly, it can help a lot with 'transition-duration: 0.3s'
body #root * {
  i {
    transition-duration: 0s;

    &::before {
      transition-duration: 0.3s;
    }

    &::after {
      transition-duration: 0.3s;
    }
  }

  svg * {
    transition-duration: unset;
  }
}*/

body #root {
  td, .list_item {
    transition-property: all;
    transition-duration: 0.3s;
  }
}
