$breakpoints: (
        "xs": 480px,
        "s": 576px,
        "m": 767px,
        "l": 992px,
        "tablet-max": 1025px,
        "xl": 1200px,
        "1280": 1280px,
        "1365": 1365px,
        "1350": 1350px,
        "xxl": 1367px,
        "1400": 1400px,
        "1500": 1500px,
        "1580": 1580px,
        "1600": 1600px,
        "xxxl": 1601px,
        "xxxxl": 1900px,
);

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// $exts: woff2 woff ttf
@mixin font-face($name, $path, $weight: null, $style: null, $exts: woff2) {
  $src: null;

  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

